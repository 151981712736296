import React, { useState, useEffect } from 'react';
import {
	Route,
	Switch,
	useRouteMatch,
	useLocation,
	useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/isNumber';
import CaseOverviewPage from '../CaseOverviewPage';
import CasePreparingPage from '../CasePreparingPage';
import ImageBar from './components/ImageBar';
import PreparingBar from './components/ImageBar/PreparingBar';
import usePrevious from '../../hooks/usePrevious';
import Loading from '../../components/Loading';
import { CaseStatus } from '../../utils/constants';
import CaseSummaryPage from '../CaseSummaryPage';
import './CaseDetailPage.scss';

function CaseDetailPage() {
	const { path } = useRouteMatch();
	const location = useLocation();
	const htr = useHistory();
	const [startFrame, setStartFrame] = useState(0);
	const [images, setImages] = useState([]);
	const {
		isFetchingData,
		allowDragdrop,
		caseDetail: { casestatus },
	} = useSelector((state) => state.caseDetail);
	const prevCaseStatus = usePrevious(casestatus);
	const isCaseSummaryPage = location.pathname.includes('summary');
	const isCasePreparingPage = location.pathname.includes('preparing');

	const getImages = (imagesUrl) => {
		setImages(imagesUrl);
	};
	const getStartFrame = (frameID) => {
		setStartFrame(frameID);
	};

	useEffect(() => {
		if (!prevCaseStatus && isNumber(casestatus)) {
			const { pathname } = location;
			if (
				(casestatus !== CaseStatus.NOT_STARTED &&
					pathname.includes('preparing')) ||
				(casestatus === CaseStatus.NOT_STARTED &&
					(pathname.includes('overview') ||
						pathname.includes('summary')))
			) {
				htr.push('/');
			}
		}
	}, [casestatus]);

	return (
		<div className="case-detail mt-3">
			{!isCasePreparingPage && (
				<div style={{ display: isCaseSummaryPage ? 'none' : 'block' }}>
					<ImageBar
						allowDragdrop={allowDragdrop}
						getStartFrame={getStartFrame}
						getImages={getImages}
					/>
				</div>
			)}

			{isCasePreparingPage && (
				<PreparingBar
					allowDragdrop={allowDragdrop}
					getStartFrame={getStartFrame}
					getImages={getImages}
				/>
			)}

			{isFetchingData && (
				<Loading show>
					<p className="m-auto loading-message">
						Loading the images, please wait ...
					</p>
				</Loading>
			)}
			<Switch>
				<Route exact path={`${path}/summary`}>
					<CaseSummaryPage />
				</Route>
				<Route exact path={`${path}/overview`}>
					<CaseOverviewPage />
				</Route>
				<Route exact path={`${path}/preparing`}>
					<CasePreparingPage
						startFrame={startFrame}
						images={images}
					/>
				</Route>
			</Switch>
		</div>
	);
}

export default CaseDetailPage;
