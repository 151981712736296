/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { Link, Route, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CaseStatus } from '../../../../utils/constants';
import {
	updateFrameStartOfCaseDetail,
	updateCaseDetail,
	setElapsedTime,
	setFrameStart,
} from '../../../../store/slices/CaseDetailSlice';
import './FooterButton.scss';

function FooterButton(props) {
	const { frameID } = props;
	const params = useParams();
	const dispatch = useDispatch();
	const { caseDetail } = useSelector((state) => state.caseDetail);
	const { accounts } = useMsal();
	const fullName = accounts[0] && accounts[0].name;
	const htr = useHistory();

	const onHandleMark = (e) => {
		e.preventDefault();
		const timer = localStorage.getItem('elapsedTime');

		const obj = {
			caseId: caseDetail.caseid,
			casestatus: CaseStatus.IN_PROGRESS,
			modifiedby: fullName,
			startframeid: frameID,
			elapsedtime: timer,
			iscaseoverview: false,
		};
		(async () => {
			await dispatch(updateFrameStartOfCaseDetail(obj)).unwrap();
			// dispatch(setElapsedTime(timer));
			dispatch(setFrameStart(frameID));
			// dispatch(
			// 	updateCaseDetail({
			// 		caseStatus: CaseStatus.IN_PROGRESS,
			// 		modifiedBy: fullName,
			// 		startFrameID: frameID,
			// 		elapsedTime: timer,
			// 	})
			// );
			htr.push(`/cases/${+params.id}/overview`);
		})();
	};
	return (
		<Route>
			<div className="footer-button py-4">
				<Link className="btn btn-warning text-center me-3" to="/">
					To Case List
				</Link>
				<Link
					className="btn btn-primary text-center ms-2"
					to={`/cases/${+params.id}/overview`}
					onClick={(e) => onHandleMark(e)}
				>
					Mark
				</Link>
			</div>
		</Route>
	);
}

FooterButton.propTypes = {
	frameID: PropTypes.number,
};

FooterButton.defaultProps = {
	frameID: 0,
};

export default FooterButton;
