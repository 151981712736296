import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {
	CaseStatus,
	CASE_STATUS_VALUES,
	STATUS_CLASS,
} from '../../../../utils/constants';
import './CaseListItem.scss';

function CaseListItem(props) {
	const { row, noNumber, history, onRowClicked, onIconClicked } = props;
	const bgClass = STATUS_CLASS[row.casestatus];
	const iconClass = `status-icon d-block bi cursor-pointer ${
		CASE_STATUS_VALUES[row.casestatus]?.value === CaseStatus.COMPLETED
			? 'bi-eye-fill'
			: 'bi-play-fill'
	}`;
	const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

	const [loading, setLoading] = React.useState(false);

	const htr = useHistory();

	const onHandleRowClick = (currentRow) => {
		if (loading) {
			return;
		}
		setIsPopoverOpen(!isPopoverOpen);
		if (onRowClicked) {
			setLoading(true);
			onRowClicked(currentRow)
				.then(() => {
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	};

	const handleRedirectPage = async (id, status, event) => {
		event.stopPropagation();
		if (
			CASE_STATUS_VALUES[status]?.value === CaseStatus.IN_PROGRESS ||
			CASE_STATUS_VALUES[status]?.value === CaseStatus.COMPLETED
		) {
			if (onIconClicked) {
				await onIconClicked(id);
			}
			htr.push(`/cases/${id}/overview`);
		}
		if (status === 0) {
			htr.push(`/cases/${id}/preparing`);
		}
	};

	return (
		<>
			<Popover
				isOpen={isPopoverOpen}
				positions={['bottom', 'top', 'left', 'right']} // if you'd like, you can limit the positions
				padding={10} // adjust padding here!
				reposition // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
				containerStyle={{ top: '-20px' }}
				onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
				containerParent={document.querySelector('.table-fixed')}
				boundaryInset={75}
				content={(
					{ position, childRect, popoverRect } // you can also provide a render function that injects some useful stuff!
				) => (
					<ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
						position={position}
						childRect={childRect}
						popoverRect={popoverRect}
						arrowColor="#C4C4C4"
						arrowSize={10}
						arrowStyle={{ opacity: 0.7 }}
						className="popover-arrow-container"
						arrowClassName="popover-arrow"
					>
						<div className="my-popover">
							<div className="my-popover__title">
								Case Details
							</div>

							{loading ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '100px',
										width: '200px',
									}}
								>
									<ReactLoading
										type="spin"
										color="#5f5f5f"
										width="48px"
										height="48px"
									/>
								</div>
							) : (
								<ul className="case-detail__list">
									<li className="case-detail__item">
										History and Medications
										<div>{history[0]?.medicalhistory}</div>
									</li>
									<li className="case-detail__item">
										Order Details
										<div>{history[0]?.orderdetails}</div>
									</li>
								</ul>
							)}
						</div>
					</ArrowContainer>
				)}
			>
				<tr
					onClick={() => onHandleRowClick(row)}
					className="CaseListPage-item"
				>
					<th scope="row">
						<div className="d-inline-block">{noNumber}</div>
					</th>
					<td>
						<div className="d-block" title={row.casename}>
							{row.casename}
						</div>
					</td>
					<td>
						<div
							className="d-block text-truncate max-width-200px"
							title={row.customer}
						>
							{row.customer}
						</div>
					</td>
					<td>
						<div
							className="d-block text-truncate max-width-100px"
							title={row.modifiedby}
						>
							{row.modifiedby}
						</div>
					</td>
					<td>
						<div
							className="d-block text-truncate max-width-100px"
							title={row.capsulecamera}
						>
							{row.capsulecamera}
						</div>
					</td>
					<td>
						<div className="d-block max-width-120px">
							{row.uploadeddate}
						</div>
					</td>
					<td>
						<div className="d-flex justify-content-between align-items-center max-width-120px">
							<span className={`badge ${bgClass}`}>
								{CASE_STATUS_VALUES[row.casestatus]?.label}
							</span>
							<i
								className={iconClass}
								onClick={(e) =>
									handleRedirectPage(
										row.caseid,
										row.casestatus,
										e
									)
								}
							></i>
						</div>
					</td>
					<td>
						<div className="d-block max-width-120px">
							{row.startdate}
						</div>
					</td>
					<td>
						<div className="d-block max-width-120px">
							{row.completeddate}
						</div>
					</td>
				</tr>
			</Popover>
		</>
	);
}

CaseListItem.propTypes = {
	row: PropTypes.object.isRequired,
	noNumber: PropTypes.number.isRequired,
	history: PropTypes.array,
	onRowClicked: PropTypes.func,
	onIconClicked: PropTypes.func,
};

CaseListItem.defaultProps = {
	onRowClicked: null,
	onIconClicked: null,
	history: [],
};

export default CaseListItem;
