import React from 'react';
import PropTypes from 'prop-types';
import ElapsedTime from '../../ElapsedTime';
import { SLIDE_LENGTH } from '../../../utils/constants';

const Header = (props) => {
	const {
		isLoading,
		isMarking,
		imageIndex,
		marks,
		onResetModal,
		onNext,
		onPrev,
	} = props;

	return (
		<>
			<i
				className={`bi bi-caret-left-fill mark-modal-content__prev-button ${
					isLoading || isMarking || marks.length > 0 || imageIndex < 1
						? 'disabled'
						: 'cursor-pointer'
				}`}
				onClick={onPrev}
			></i>
			<i
				className={`bi bi-caret-right-fill mark-modal-content__next-button ${
					isMarking ||
					marks.length > 0 ||
					isLoading ||
					imageIndex >= SLIDE_LENGTH * 2
						? 'disabled'
						: 'cursor-pointer'
				}`}
				onClick={onNext}
			></i>
			<div className="row align-items-center">
				<ElapsedTime style={{ width: 'auto', margin: 0 }} />
				<div className="col text-end">
					<button
						type="button"
						className="close btn-lg mark-modal-content__close-modal"
						style={{ marginRight: -20 }}
						data-dismiss="alert"
						aria-label="Close"
						onClick={onResetModal}
					>
						<span style={{ fontSize: 28 }} aria-hidden="true">
							&times;
						</span>
					</button>
				</div>
			</div>
		</>
	);
};

Header.propTypes = {
	isMarking: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	imageIndex: PropTypes.number.isRequired,
	marks: PropTypes.array.isRequired,
	onResetModal: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	onPrev: PropTypes.func.isRequired,
};

export default Header;
