/* eslint-disable camelcase */
import React, {
	useState,
	useRef,
	useCallback,
	forwardRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import get from 'lodash/get';
import last from 'lodash/last';
import first from 'lodash/first';
import findLastIndex from 'lodash/findLastIndex';
import { toPng } from 'html-to-image';
import { v4 as uuidv4 } from 'uuid';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import Header from './Header';
import ImageCreation from './LeftPanel/ImageCreation';
import CaseDetailService from '../../services/CaseDetailService';
import { getImageByFrameId } from '../../services/CaseOverviewService';
import { SLIDE_LENGTH, LIMIT_SLIDE, CAMERA } from '../../utils/constants';
import './MarkModal.scss';

const customStyles = {
	content: {
		top: 10,
		width: 1082,
		height: 582,
		left: '50%',
		padding: '0px 24px 20px',
		transform: 'translateX(-50%)',
		paddingTop: 0,
	},
	overlay: {
		zIndex: 100,
	},
};

const DEFAULT_SIZE = 72;

const MarkModal = (props, ref) => {
	const panelRef = useRef(null);
	const {
		caseId,
		centralFrame,
		frameIdRange,
		frameIndex,
		isShowHeatmap,
		images: imagesProps,
		imageBarService,
		onComplete,
		onUpdateElapsedTime,
		showSimilar,
	} = props;
	const [images, setImages] = useState([]);
	const [marks, setMarks] = useState([]);
	const [isShowModal, setIsShowModal] = useState(false);
	const [isMarking, setIsMarking] = useState(false);
	const [isAddView, setIsAddView] = useState(false);
	const [imageIndex, setImageIndex] = useState(SLIDE_LENGTH);
	const [isLoading, setIsLoading] = useState(false);

	const updateImages = async (callback) => {
		const frameIds = [];
		setIsLoading(true);
		const camlength = imageBarService.getMaxFrameCameraOne();
		for (
			let frameId = frameIdRange[0];
			frameId <= frameIdRange[1];
			frameId++
		) {
			frameIds.push({
				cameraid: get(imagesProps, '0.cam'),
				frameid: frameId,
				camlength,
				isexplain: isShowHeatmap,
			});
		}
		const imagesResponse = await CaseDetailService.getImagesByFrameIds(
			caseId,
			frameIds
		);
		const indexCentral = imagesResponse.findIndex(
			(img) => img.frameid === centralFrame.frameID
		);
		setImages([
			...new Array(SLIDE_LENGTH - indexCentral),
			...imagesResponse.map(({ cameraid, frameid, img_base64 }) => ({
				cam: cameraid,
				frameID: frameid,
				src: img_base64,
				isSeen: true,
			})),
			...new Array(
				SLIDE_LENGTH - (imagesResponse.length - indexCentral - 1)
			),
		]);
		setImageIndex(SLIDE_LENGTH + frameIndex);
		setIsLoading(false);
		if (callback) {
			callback();
		}
	};

	const resetModal = () => {
		setMarks([]);
		setIsMarking(false);
		setIsAddView(false);
		setIsShowModal(false);
		setIsLoading(false);
	};

	const handleCreateImage = useCallback(
		async (trackedMark, isHeatmap) => {
			if (panelRef.current === null) {
				return null;
			}
			let image = null;
			const camlength = imageBarService.getMaxFrameCameraOne();
			if (isHeatmap && trackedMark) {
				image = await getImageByFrameId(
					caseId,
					trackedMark.frame,
					trackedMark.cam,
					camlength
				);
			}
			const imageDom = panelRef.current?.children[0];
			if (image) imageDom.style['background-image'] = `url(${image.src})`;
			return toPng(imageDom, {
				cacheBust: true,
			});
		},
		[panelRef]
	);

	const handleComplete = async () => {
		const savedMarks = marks.map(({ id, ...item }) => item);
		const camOneLength = imageBarService.getCamOne().length;
		const trackedImages = [];
		let trackedMark = null;
		setIsLoading(true);
		images.forEach((item, index) => {
			if (item && item.isSeen) {
				const { frameID, cam } = item;
				const isMarkTrack = imageIndex === index;
				const track = {
					frame: frameID,
					text: isMarkTrack ? 'Mark' : 'Clear',
					mark: isMarkTrack ? 'Mark' : 'Clear',
					score: imageBarService.getScore(frameID, cam),
					timestamp: imageBarService.getTimestamp(frameID, cam),
					thumbnail: imageBarService.getThumbnail(frameID, cam),
					y_pred: imageBarService.getRealY(frameID, cam),
					cam,
					positions: isMarkTrack ? savedMarks : 'No circles',
					seq_id: `Seq_${frameID}`,
					num_detection: savedMarks.length,
					range_visualized: isMarkTrack ? 'Mark' : 'Clear',
					frame_line:
						cam === CAMERA.TWO ? frameID + camOneLength : frameID,
					context: [],
				};
				if (isMarkTrack) {
					trackedMark = { ...track, image: item };
				}
				trackedImages.push(track);
			}
		});
		const base64 = await handleCreateImage(trackedMark, isShowHeatmap);
		resetModal();
		onComplete(trackedImages, [
			{
				...trackedMark,
				base64,
			},
		]);
	};

	const addMark = (event) => {
		const bounds = event.target.getBoundingClientRect();
		const { clientX, clientY } = event;
		setMarks([
			...marks,
			{
				id: uuidv4(),
				x: clientX - bounds.left - DEFAULT_SIZE / 2,
				y: clientY - bounds.top - DEFAULT_SIZE / 2,
				width: DEFAULT_SIZE,
				height: DEFAULT_SIZE,
			},
		]);
		setIsMarking(false);
		setIsAddView(true);
	};

	const saveMark = (infor) => {
		const length = marks.length - 1;
		const newMarks = marks.map((item, index) =>
			index === length ? { ...item, ...infor } : item
		);
		setIsAddView(false);
		setMarks(newMarks);
	};

	const removeMark = (id) => {
		setMarks(marks.filter((item) => item.id !== id));
	};

	const cancelMark = () => {
		setMarks(marks.slice(0, marks.length - 1));
		setIsAddView(false);
	};

	const handleToggleIsMarking = () => {
		setIsMarking(!isMarking);
	};

	const handleDragStop = (item, e, d) => {
		item.x = d.x;
		item.y = d.y;
	};

	const handleResizeStop = (item, dom, position) => {
		item.width = +dom.style.width.replace('px', '');
		item.height = +dom.style.height.replace('px', '');
		item.x = position.x;
		item.y = position.y;
	};

	const handlePrev = async () => {
		if (!images[0]) {
			setIsLoading(true);
			const firstImage = first(images.filter((item) => item));
			const preFrameId = get(firstImage, 'frameID') - 1;
			const preCam = get(firstImage, 'cam');
			const camlength = imageBarService.getMaxFrameCameraOne();
			const image = await getImageByFrameId(
				caseId,
				preFrameId,
				preCam,
				camlength,
				isShowHeatmap
			);
			if (image) {
				const indexPrevAdd = images.findIndex((item) => item);
				images[indexPrevAdd - 1] = image;
				setImages([...images]);
			}
			setIsLoading(false);
		}
		images[imageIndex - 1].isSeen = true;
		setImageIndex(imageIndex - 1);
	};

	const handleOpenMarkModal = () => {
		setImages([
			...new Array(LIMIT_SLIDE + frameIndex),
			...imagesProps.map((image) => ({ ...image, isSeen: true })),
			...new Array(LIMIT_SLIDE - frameIndex),
		]);
		setImageIndex(SLIDE_LENGTH + frameIndex);
		updateImages();
		setIsShowModal(true);
		onUpdateElapsedTime();
	};

	const handleNext = async () => {
		if (!images[SLIDE_LENGTH * 2]) {
			setIsLoading(true);
			const lastImage = last(images.filter((item) => item));
			const nextFrameId = get(lastImage, 'frameID') + 1;
			const nextCam = get(lastImage, 'cam');
			const camlength = imageBarService.getMaxFrameCameraOne();
			const image = await getImageByFrameId(
				caseId,
				nextFrameId,
				nextCam,
				camlength,
				isShowHeatmap
			);
			if (image) {
				const indexNextAdd = findLastIndex(images, (item) => item);
				images[indexNextAdd + 1] = image;
				setImages([...images]);
			}
			setIsLoading(false);
		}
		images[imageIndex + 1].isSeen = true;
		setImageIndex(imageIndex + 1);
	};

	useImperativeHandle(ref, () => ({
		openMarkModal() {
			handleOpenMarkModal();
		},
	}));

	return (
		<div className="mark-modal">
			{!!imagesProps.length && (
				<button
					type="button"
					className="btn btn-primary text-white button__text px-4 mx-2"
					onClick={handleOpenMarkModal}
					disabled={showSimilar}
				>
					<i className="bi bi-vector-pen me-2"></i>
					Mark
				</button>
			)}
			<Modal
				isOpen={isShowModal}
				ariaHideApp={false}
				style={customStyles}
			>
				<div className="container mark-modal-content">
					<Header
						isMarking={isMarking}
						imageIndex={imageIndex}
						isLoading={isLoading}
						marks={marks}
						onResetModal={resetModal}
						onNext={handleNext}
						onPrev={handlePrev}
					/>
					<div className="row h-100">
						<div ref={panelRef} className="col h-100 left-panel">
							<LeftPanel
								isMarking={isMarking}
								image={images[imageIndex]}
								marks={marks}
								imageBarService={imageBarService}
								onAddMark={addMark}
								onDragStop={handleDragStop}
								onResizeStop={handleResizeStop}
							/>
						</div>
						<RightPanel
							image={images[imageIndex]}
							imageIndex={imageIndex}
							marks={marks}
							isLoading={isLoading}
							isAddView={isAddView}
							imageBarService={imageBarService}
							onSaveMark={saveMark}
							onRemoveMark={removeMark}
							onCancelMark={cancelMark}
							onToggleIsMarking={handleToggleIsMarking}
							onComplete={handleComplete}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

MarkModal.propTypes = {
	frameIndex: PropTypes.number,
	centralFrame: PropTypes.object,
	frameIdRange: PropTypes.array,
	images: PropTypes.array,
	imageBarService: PropTypes.any,
	isShowHeatmap: PropTypes.bool,
	caseId: PropTypes.any.isRequired,
	onComplete: PropTypes.func.isRequired,
	onUpdateElapsedTime: PropTypes.func.isRequired,
	showSimilar: PropTypes.bool,
};

MarkModal.defaultProps = {
	frameIndex: 0,
	images: [],
	frameIdRange: [0, 0],
	centralFrame: {},
	imageBarService: {},
	isShowHeatmap: false,
	showSimilar: false,
};

export default forwardRef(MarkModal);
export { ImageCreation };
