import { PropTypes } from 'prop-types';
import React from 'react';
import { CASE_STATUS_VALUES, STATUS_CLASS } from '../../../../utils/constants';
import './VideoAdministrationItem.scss';

function VideoAdministrationItem(props) {
	const { row, handleHardRemove, handleSoftRemove, handleRestore } = props;
	const bgClass = STATUS_CLASS[row.casestatus];
	return (
		<>
			<tr className="VideoAdministrationPage-item">
				<td>
					<div className="d-block">{row.casename}</div>
				</td>
				<td>
					<div className="d-block text-truncate max-width-200px">
						{row.customer}
					</div>
				</td>
				<td>
					<div className="d-block max-width-120px">
						{row.uploadedDate}
					</div>
				</td>
				<td>
					<div className="d-block max-width-120px">
						{row.startedDate}
					</div>
				</td>
				<td>
					<div className="d-block max-width-120px">
						{row.completedDate}
					</div>
				</td>
				<td>
					<div className="d-block max-width-120px">
						{row.nfinding}
					</div>
				</td>
				<td>
					<div className="d-block text-truncate max-width-200px">
						<span className={`badge ${bgClass}`}>
							{CASE_STATUS_VALUES[row.casestatus]?.label}
						</span>
					</div>
				</td>
				<td>
					<div className="d-flex justify-content-around align-items-center max-width-120px">
						{row.isdeleted ? (
							<i
								className="status-icon d-block bi bi-arrow-counterclockwise btn btn-outline-light btn-sm"
								onClick={() => handleRestore(row.caseid)}
							></i>
						) : (
							<i
								className="status-icon d-block bi bi-archive-fill btn btn-outline-light btn-sm"
								onClick={() => handleSoftRemove(row.caseid)}
							></i>
						)}
						<i
							className="status-icon d-block bi bi-x-lg btn btn-outline-light btn-sm"
							onClick={() => handleHardRemove(row.caseid)}
						></i>
					</div>
				</td>
			</tr>
		</>
	);
}

VideoAdministrationItem.propTypes = {
	row: PropTypes.object.isRequired,
	handleSoftRemove: PropTypes.func,
	handleHardRemove: PropTypes.func,
	handleRestore: PropTypes.func,
};

VideoAdministrationItem.defaultProps = {
	handleSoftRemove: null,
	handleHardRemove: null,
	handleRestore: null,
};

export default VideoAdministrationItem;
