import getConfig from './configProvider';

export const msalConfig = {
	auth: {
		clientId: getConfig('REACT_APP_CLIENTID'),
		authority: getConfig('REACT_APP_AUTHORITY'),
		redirectUri: getConfig('REACT_APP_REDIRECTURI'),
		postLogoutRedirectUri: getConfig('REACT_APP_POSTLOGOUTREDIRECTURI'),
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: false,
	},
};

export const loginRequest = {
	scopes: ['User.ReadBasic.All'],
};

export const ROLE = {
	ADMIN: 'Admin',
	USER: 'User',
	GLOBAL_ADMIN: 'GlobalAdmin',
};
