import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MarkForm = (props) => {
	const { onSave, onCancel } = props;
	const [type, setType] = useState('Polyp');
	const [description, setDescription] = useState('');

	const handleValueChange = (event) => {
		if (event && event.target && event.target.name) {
			const { name } = event.target;
			if (name === 'type') {
				setType(event.target.value);
				return;
			}
			if (name === 'description') {
				setDescription(event.target.value);
			}
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	const handleSave = () => {
		onSave({ type, description });
	};

	return (
		<div className="">
			<select
				name="type"
				className="form-select"
				onChange={handleValueChange}
				value={type}
				aria-label="type-mark"
			>
				<option value="Polyp">Polyp</option>
				<option value="Other">Other</option>
			</select>
			<textarea
				name="description"
				className="form-control mt-1"
				placeholder="Description ..."
				maxLength={250}
				rows="6"
				value={description}
				onChange={handleValueChange}
			/>
			<br />
			<div className="d-flex justify-content-evenly">
				<button
					type="button"
					className="btn btn-primary width-172"
					onClick={handleSave}
				>
					Add
				</button>
				<button
					type="button"
					className="btn btn-warning width-172"
					onClick={handleCancel}
				>
					Cancel
				</button>
			</div>
		</div>
	);
};

MarkForm.propTypes = {
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default MarkForm;
