import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isArray } from 'lodash';
import Creation from './Creation';
import CaseDetailService from '../../../../services/CaseDetailService';
import { getMarkFrames } from '../../../../utils/commons';

const ImageCreation = (props) => {
	const { caseId, trackNegative, imageBarService, onSetMarkDetections } =
		props;
	const [trackedMarks, setTrackedMarks] = useState([]);

	const renderMark = async (markFrames) => {
		const camlength = imageBarService.getMaxFrameCameraOne();
		const frameIds = markFrames.map(({ frame, cam }) => ({
			frameid: frame,
			cameraid: cam,
			camlength,
			isexplain: false,
		}));
		const newImages = await CaseDetailService.getImagesByFrameIds(
			caseId,
			frameIds
		);
		if (isArray(newImages)) {
			setTrackedMarks(
				newImages.map((item, index) => ({
					...markFrames[index],
					image: {
						src: item.img_base64,
						frameID: item.frameid,
					},
				}))
			);
		}
	};

	useEffect(() => {
		renderMark(getMarkFrames(trackNegative));
	}, [trackNegative]);

	return (
		!isEmpty(trackedMarks) && (
			<Creation
				trackedMarks={trackedMarks}
				trackNegative={trackNegative}
				imageBarService={imageBarService}
				onSetMarkDetections={onSetMarkDetections}
			/>
		)
	);
};

ImageCreation.propTypes = {
	caseId: PropTypes.any.isRequired,
	imageBarService: PropTypes.any,
	trackNegative: PropTypes.array.isRequired,
	onSetMarkDetections: PropTypes.func.isRequired,
};

ImageCreation.defaultProps = {
	imageBarService: {},
};

export default ImageCreation;
