import React from 'react';
import { Helmet } from 'react-helmet';
import { useIsAuthenticated } from '@azure/msal-react';
import SignIn from './components/SignIn';
import SignOut from '../../components/SignOut';
import imageLogo from '../../assets/images/corporatehealth.png';
import imageAispeed from '../../assets/images/aispeed.png';
import './LoginPage.scss';

function LoginPage() {
	const isAuthenticated = useIsAuthenticated();

	return (
		<div className="page-layout">
			<Helmet>
				<title>Login | CHI AiSPEED</title>
			</Helmet>
			<div className="background" />
			<div className="content">
				<div className="content-logo">
					<img src={imageLogo} alt="logo" className="logo" />
				</div>
				<div className="content-info">
					<img
						src={imageAispeed}
						alt="aispeedlogo"
						className="aispeedlogo"
					/>
					<p>
						Sign in with your CHI network account using the sign-in
						link
					</p>
					{isAuthenticated ? <SignOut /> : <SignIn />}
				</div>
				<div className="content-copy-right">
					<p>© {new Date().getFullYear()} AiSPEED</p>
				</div>
			</div>
		</div>
	);
}

export default LoginPage;
