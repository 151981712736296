import React from 'react';
import PropTypes from 'prop-types';
import './CaseOverviewFinding.scss';

function CaseOverviewFinding(props) {
	const { height, markDetections, imageBarService, onCloseFinding } = props;

	return (
		<div className="card h-100 mb-2 CaseOverviewPage-finding">
			<div className="card-body shadow">
				<div className="d-flex justify-content-between align-items-center">
					<div className="card-title CaseOverviewPage-finding__title">
						Finding <br />
						{markDetections?.length || 0} Detection(s)
					</div>
					<div
						className="CaseOverviewPage-finding__close-icon flex-shrink-0 cursor-pointer"
						onClick={onCloseFinding}
					>
						<i className="bi bi-chevron-right"></i>
					</div>
				</div>
				<div
					className="CaseOverviewPage-finding__image-wrapper"
					style={
						height
							? { maxHeight: height - 52, overflow: 'auto' }
							: {}
					}
				>
					{markDetections.map((mark) => {
						const frameID = mark.frame;
						const score = imageBarService.getScore(
							frameID,
							mark.cam
						);
						const timestamp = imageBarService.getTimestamp(
							frameID,
							mark.cam
						);
						const borderColor = imageBarService.getColorFromProb(
							imageBarService.getScoreFromProb(frameID, mark.cam)
						);
						return (
							<div
								key={frameID}
								className="CaseOverviewPage-finding__image"
							>
								<div>{`Cam ${mark.cam} - Similar: ${
									mark.similars ? mark.similars.length : 0
								}`}</div>
								<img
									src={mark.base64}
									style={{
										width: '100%',
										border: `solid 2px ${borderColor}`,
									}}
									alt={mark.text}
								/>
								<div className="d-flex justify-content-center align-items-center mt-2">
									<span
										className="CaseOverviewPage-finding__image-score px-3"
										style={{
											border: `solid 2px ${borderColor}`,
										}}
									>
										{score} {timestamp}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

CaseOverviewFinding.propTypes = {
	height: PropTypes.number,
	markDetections: PropTypes.array.isRequired,
	imageBarService: PropTypes.any,
	onCloseFinding: PropTypes.func.isRequired,
};

CaseOverviewFinding.defaultProps = {
	height: 0,
	imageBarService: {},
};

export default CaseOverviewFinding;
