import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../../components/ConfirmModal';
import {
	fetchVideoAdminList,
	hardRemoveCase,
	restoreCase,
	setFilterCriteria,
	setFullName,
	setPage,
	softRemoveCase,
} from '../../../../store/slices/VideoAdminListSlice';
import { CaseStatus } from '../../../../utils/constants';
import VideoAdministrationItem from '../VideoAdministrationItem';
import './VideoAdministrationList.scss';

function VideoAdministrationList() {
	const hardRemoveConfirmMessage = 'Are you sure to hard remove this case?';
	const [isShowHardRemoveModal, setIsShowHardRemoveModal] = useState(false);
	const [currentHardRemoveCaseId, setCurrentHardRemoveCaseId] =
		useState(null);
	const cases = useSelector((state) => state.videoAdminList.cases);
	const { isFetchingData } = useSelector((state) => state.videoAdminList);
	const { fullName } = useSelector(
		(state) => state.videoAdminList.filterCriteria
	);
	const dispatch = useDispatch();
	const { accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (isAuthenticated && !fullName) {
			dispatch(setFullName(accounts[0].name));
		}
	}, []);

	useEffect(() => {
		dispatch(setPage(1));
		dispatch(
			setFilterCriteria({
				customer: '',
				caseName: '',
				caseStatus: [CaseStatus.NOT_STARTED, CaseStatus.IN_PROGRESS],
				uploadedDate: '',
				startedDate: '',
				completedDate: '',
				isRemove: false,
			})
		);
		dispatch(fetchVideoAdminList());
	}, []);

	const handleSoftRemove = async (caseId) => {
		await dispatch(softRemoveCase(caseId));
		await dispatch(fetchVideoAdminList());
	};

	const handleRestore = async (caseId) => {
		await dispatch(restoreCase(caseId));
		await dispatch(fetchVideoAdminList());
	};

	const handleHardRemove = (caseId) => {
		setIsShowHardRemoveModal(true);
		setCurrentHardRemoveCaseId(caseId);
	};

	const handleHardRemoveConfirm = async () => {
		setIsShowHardRemoveModal(false);
		await dispatch(hardRemoveCase(currentHardRemoveCaseId));
		await dispatch(fetchVideoAdminList());
	};

	return isFetchingData ? (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100px',
				width: '100%',
			}}
		>
			<ReactLoading
				type="spin"
				color="#5f5f5f"
				width="48px"
				height="48px"
			/>
		</div>
	) : (
		<div className="table-responsive mt-4 table-fixed VideoAdministrationPage-list">
			<ConfirmModal
				isShowModal={isShowHardRemoveModal}
				handleHideModal={() => setIsShowHardRemoveModal(false)}
				handleConfirm={handleHardRemoveConfirm}
				message={hardRemoveConfirmMessage}
				buttonColor="danger"
				buttonName="Hard Remove"
			/>
			<table className="table table-hover table-sm align-middle">
				<thead className="bg-white">
					<tr>
						<th scope="col">Case</th>
						<th scope="col">Customer</th>
						<th scope="col">Uploaded D/T</th>
						<th scope="col">Started D/T</th>
						<th scope="col">Completed D/T</th>
						<th scope="col">N.Findings</th>
						<th scope="col">Status</th>
						<th scope="col"> </th>
					</tr>
				</thead>
				<tbody>
					{cases && cases.length !== 0 ? (
						cases.map((videoAdmin) => (
							<VideoAdministrationItem
								key={videoAdmin.caseid}
								row={videoAdmin}
								handleSoftRemove={handleSoftRemove}
								handleHardRemove={handleHardRemove}
								handleRestore={handleRestore}
							/>
						))
					) : (
						<tr>
							<td colSpan="9" className="text-center">
								No data found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
export default VideoAdministrationList;
