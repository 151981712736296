import http from '../utils/http';

export default class CaseListService {
	static async getCaseList(queryParams) {
		try {
			const { data } = await http.get(`/api/cases`, {
				params: {
					fullname: queryParams?.fullName || '',
					casename: queryParams?.caseName || '',
					customer: queryParams?.customers || [],
					modifiedby: queryParams?.modifiedBy || [],
					casestatus: queryParams?.caseStatus || [],
					page: queryParams?.page || 1,
				},
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getFilterOptions() {
		try {
			const { data } = await http.get(`/api/filters/cases`);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
