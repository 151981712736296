import React from 'react';
import PropTypes from 'prop-types';

function DatePicker(props) {
	const { name, id, handleDateSelect } = props;
	return (
		<div className="d-flex flex-nowrap align-items-center">
			<label htmlFor={id}>{name}:</label>
			<input
				className="mx-1 form-control form-control-sm input-control"
				type="date"
				onChange={(e) => handleDateSelect(e.target.value)}
				name={name}
				id={id}
				placeholder={name}
			/>
		</div>
	);
}

DatePicker.propTypes = {
	name: PropTypes.string,
	id: PropTypes.string,
	handleDateSelect: PropTypes.func,
};

DatePicker.defaultProps = {
	name: '',
	id: '',
	handleDateSelect: () => {},
};

export default DatePicker;
