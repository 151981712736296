import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import { toPng } from 'html-to-image';
import LeftPanel from '../index';

const ImageCreation = (props) => {
	const ref = useRef(null);
	const { trackedMarks, imageBarService, onSetMarkDetections } = props;

	const handleCreateImages = useCallback(async () => {
		if (ref.current === null) {
			return;
		}

		const base64s = await Promise.all(
			values(ref.current?.children).map((dom) => toPng(dom))
		);
		onSetMarkDetections(
			trackedMarks.map((item, index) => ({
				...item,
				base64: base64s[index],
			}))
		);
	}, [ref]);

	useEffect(() => {
		handleCreateImages();
	}, []);

	const domImages = (
		<div
			ref={ref}
			style={{ position: 'absolute', top: '-9999em', right: '-9999em' }}
		>
			{trackedMarks.map((item) => (
				<LeftPanel
					key={item.frame}
					style={{ border: 'none' }}
					image={item.image}
					marks={item.positions}
					imageBarService={imageBarService}
				/>
			))}
		</div>
	);

	return ReactDOM.createPortal(domImages, document.body);
};

ImageCreation.propTypes = {
	trackedMarks: PropTypes.array.isRequired,
	imageBarService: PropTypes.any,
	trackNegative: PropTypes.array.isRequired,
	onSetMarkDetections: PropTypes.func.isRequired,
};

ImageCreation.defaultProps = {
	imageBarService: {},
};

export default ImageCreation;
