import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCaseList,
	fetchFilterOptions,
	setFilterCriteria,
	setPage,
} from '../../../../store/slices/CaseListSlice';
import { CASE_STATUS_VALUES } from '../../../../utils/constants';
import './CaseListFilter.scss';

function CaseListFilter() {
	const dispatch = useDispatch();
	const {
		customerOptions,
		modifiedByOptions,
		caseStatusOptions,
		isFetchingData,
		filterCriteria,
	} = useSelector((state) => state.caseList);
	const [caseName, setCaseName] = useState('');
	const [customers, setCustomers] = useState([]);
	const [modifiedBy, setModifiedBy] = useState([]);
	const [caseStatus, setCaseStatus] = useState(
		filterCriteria.caseStatus.map((x) => ({
			value: CASE_STATUS_VALUES[x].value,
			label: CASE_STATUS_VALUES[x].label,
		}))
	);

	const onHandleApplyFilter = async () => {
		const criteria = {
			caseName,
			customers: customers.map((x) => x.value),
			modifiedBy: modifiedBy.map((x) => x.value),
			caseStatus: caseStatus.map((x) => x.value),
		};
		dispatch(setPage(1));
		dispatch(setFilterCriteria(criteria));
		await dispatch(fetchCaseList());
	};

	const handleOnKeyDown = async (e) => {
		if (e.key === 'Enter' && e.code === 'Enter') {
			await onHandleApplyFilter();
		}
	};

	useEffect(() => {
		dispatch(fetchFilterOptions());
	}, []);

	const overrideStrings = {
		selectSomeItems: 'Select the options',
		selectAll: 'All',
	};

	const filterOptions = (options, filter) => {
		if (!filter) {
			return options;
		}
		return options.filter((x) =>
			x.label.toLowerCase().includes(filter.toLowerCase())
		);
	};

	const resetCaseName = () => {
		setCaseName('');
	};

	return (
		<div className="row CaseListPage-filter">
			<div className="col-lg-12">
				<div className="row d-flex align-items-center">
					<div className="offset-lg-2 col-lg-2">
						<div className="position-relative">
							<input
								className="form-control form-control-sm input-case-name"
								type="text"
								placeholder="Search by case"
								aria-label="Search by case"
								value={caseName}
								onChange={(e) => {
									setCaseName(e.target.value);
								}}
								onKeyDown={handleOnKeyDown}
							/>
							<i
								className={`close-icon bi bi-x ${
									caseName ? '' : 'd-none'
								}`}
								onClick={resetCaseName}
							></i>
						</div>
					</div>
					<div className="col-lg-2">
						{customerOptions.length !== 0 && (
							<MultiSelect
								options={customerOptions}
								value={customers}
								onChange={setCustomers}
								isLoading={customerOptions.length === 0}
								labelledBy="Select"
								filterOptions={filterOptions}
								overrideStrings={{
									...overrideStrings,
									selectSomeItems: 'Customer',
								}}
							/>
						)}
					</div>
					<div className="col-lg-2">
						{modifiedByOptions.length !== 0 && (
							<MultiSelect
								options={modifiedByOptions}
								value={modifiedBy}
								onChange={setModifiedBy}
								labelledBy="Select"
								filterOptions={filterOptions}
								overrideStrings={{
									...overrideStrings,
									selectSomeItems: 'Modified By',
								}}
							/>
						)}
					</div>
					<div className="col-lg-2">
						{caseStatusOptions.length !== 0 && (
							<MultiSelect
								options={caseStatusOptions}
								value={caseStatus}
								onChange={setCaseStatus}
								labelledBy="Select"
								filterOptions={filterOptions}
								className="status-filter"
								overrideStrings={{
									...overrideStrings,
									selectSomeItems: 'Status',
								}}
								disableSearch
							/>
						)}
					</div>
					<div className="col-lg-2">
						<button
							type="button"
							className="btn btn-sm btn-primary w-100"
							onClick={() => onHandleApplyFilter()}
						>
							{isFetchingData ? (
								<div
									className="spinner-border spinner-border-sm text-light"
									role="status"
								>
									<span className="visually-hidden">
										Loading...
									</span>
								</div>
							) : (
								'Apply'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CaseListFilter;
