import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './SimilarImagesModal.scss';
import Modal from 'react-modal';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'visible',
		width: '95%',
	},
	overlay: {
		zIndex: 10000,
	},
};

function SimilarImagesModal(props) {
	const [loading, setLoading] = useState(true);
	const { imageBarService, similars } = useSelector(
		(state) => state.caseDetail
	);
	const {
		images,
		enabled,
		isSimilar,
		isShowSimilarModal,
		onHandleConfirmButton,
		originalImages,
	} = props;
	const { frameIds } = similars;
	const [agreedImages, setAgreedImages] = useState([]);
	const [centralImage, setCentralImage] = useState(null);
	const [centralImageInfo, setCentralImageInfo] = useState(null);

	useEffect(() => {
		setAgreedImages(images);
		setLoading(false);
		return () => {
			setLoading(false);
		};
	}, [images]);

	useEffect(() => {
		if (originalImages && originalImages.length > 0) {
			const centralIndex = Math.floor(originalImages.length / 2);
			const centerImage = originalImages[centralIndex];
			setCentralImage(centerImage);
			const borderColorStyle = imageBarService.getColorFromProb(
				imageBarService.getScoreFromProb(
					centerImage?.frameID,
					centerImage?.cam
				)
			);
			const score = imageBarService.getScore(
				centerImage?.frameID,
				centerImage?.cam
			);
			const timestamp = imageBarService.getTimestamp(
				centerImage?.frameID,
				centerImage?.cam
			);
			setCentralImageInfo({
				borderColorStyle,
				score,
				timestamp,
			});
		}
	}, [originalImages]);

	const handleToggleImage = (image) => {
		const isAgree = agreedImages.some(
			(agreeImage) => agreeImage.frameID === image.frameID
		);
		let newAgeedImages = [...agreedImages];
		if (isAgree) {
			newAgeedImages = agreedImages.filter(
				(agreeImage) => agreeImage.frameID !== image.frameID
			);
		} else {
			newAgeedImages = [...newAgeedImages, image];
		}
		setAgreedImages(newAgeedImages);
	};

	const onHandleConfirm = () => {
		const callback = () => {
			setLoading(false);
		};
		setLoading(true);
		onHandleConfirmButton(agreedImages, callback);
	};

	return (
		<Modal
			isOpen={isShowSimilarModal}
			ariaHideApp={false}
			style={customStyles}
		>
			<div className="mb-1 image-slider SimilarImageModal">
				<div className="row">
					<div className="col-md-2 original-image__wrapper">
						<div
							className="row justify-content-center"
							style={{ margin: 0 }}
						>
							<h3 className="col-auto">Original Image</h3>
						</div>
						<div className="row" style={{ margin: 0 }}>
							{imageBarService && centralImage && (
								<div className="col-auto original-image">
									<div className="img-wrapper position-relative">
										<img
											className="img-fluid image-slider__image"
											style={{
												borderColor:
													centralImageInfo.borderColorStyle,
											}}
											src={centralImage?.src}
											alt={centralImage?.frameID}
										/>
									</div>
									<div className="d-flex justify-content-center align-items-center mt-3 image-slider__percent">
										<span
											className="image-slider__info  px-3"
											style={{
												borderColor:
													centralImageInfo.borderColorStyle,
											}}
										>
											{`${centralImageInfo.score} ${centralImageInfo.timestamp}`}
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-md-10">
						{isSimilar && (
							<>
								<div className="row justify-content-center">
									<h3 className="col-auto">
										These are the images that will also be
										marked as reviewed
									</h3>
								</div>
								<div className="row justify-content-center">
									<h6 className="col-auto">
										You can click on the images you do not
										want to mark as reviewed.
									</h6>
								</div>
								<div className="row m-0 justify-content-end">
									<h6 className="col-auto fw-bold">
										Total: {`${frameIds.length}`}
									</h6>
								</div>
							</>
						)}
						<div
							className="row image-wrapper"
							style={{ margin: 0 }}
						>
							{imageBarService &&
								images.map((image) => {
									const borderColorStyle =
										imageBarService.getColorFromProb(
											imageBarService.getScoreFromProb(
												image.frameID,
												image.cam
											)
										);
									const score = imageBarService.getScore(
										image.frameID,
										image.cam
									);
									const timestamp =
										imageBarService.getTimestamp(
											image.frameID,
											image.cam
										);
									return (
										<div
											className="image__col"
											key={image.frameID + Math.random()}
										>
											<div
												className="img-wrapper position-relative"
												onClick={() =>
													handleToggleImage(image)
												}
											>
												<img
													className="img-fluid image-slider__image"
													style={{
														borderColor:
															borderColorStyle,
													}}
													src={image.src}
													alt={image.frameID}
												/>
												{agreedImages.some(
													(agreeImage) =>
														agreeImage.frameID ===
														image.frameID
												) ? (
													<i className="img-checker bi bi bi-check position-absolute"></i>
												) : (
													<i className="img-checker bi bi-x position-absolute"></i>
												)}
											</div>
											<div className="d-flex justify-content-center align-items-center mt-3 image-slider__percent">
												<span
													className="image-slider__info  px-3"
													style={{
														borderColor:
															borderColorStyle,
													}}
												>
													{`${score} ${timestamp}`}
												</span>
											</div>
										</div>
									);
								})}
						</div>
						<div className="row justify-content-center mt-3">
							<div className="col-auto">
								<button
									type="button"
									className="btn btn-primary btn-small"
									onClick={onHandleConfirm}
								>
									{loading ? (
										<div
											className="spinner-border spinner-border-sm text-light"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									) : (
										'OK'
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
				{!enabled && (
					<div className="position-absolute card-disabled"></div>
				)}
			</div>
		</Modal>
	);
}

SimilarImagesModal.propTypes = {
	images: PropTypes.array.isRequired,
	enabled: PropTypes.bool,
	isSimilar: PropTypes.bool,
	isShowSimilarModal: PropTypes.bool,
	onHandleConfirmButton: PropTypes.func,
	originalImages: PropTypes.array.isRequired,
};

SimilarImagesModal.defaultProps = {
	enabled: true,
	isSimilar: false,
	isShowSimilarModal: false,
	onHandleConfirmButton: null,
};

export default SimilarImagesModal;
