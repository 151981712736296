import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImageVideo.scss';
import imageTransparent from '../../../../assets/images/transparent.png';

function ImageVideo(props) {
	const IMAGE_BUTTON = {
		UP: 1,
		DOWN: 2,
	};
	const { images } = props;
	const refs = useRef([React.createRef(), React.createRef()]);
	const setImageForAllCam = () => {
		const imageCamOne = refs.current[0].current;
		imageCamOne.src =
			typeof images[0]?.img_base64 === 'undefined'
				? imageTransparent
				: images[0]?.img_base64;
		const imageCamTwo = refs.current[1].current;
		imageCamTwo.src =
			typeof images[1]?.img_base64 === 'undefined'
				? imageTransparent
				: images[1]?.img_base64;
	};
	useEffect(() => {
		setImageForAllCam();
	}, [images]);

	const handleChangeImageSize = (buttonName) => {
		const currentHeight = refs.current[0].current.height;
		const changeImageHeight = (newHeight) => {
			refs.current[0].current.style.height = `${newHeight}px`;
			refs.current[1].current.style.height = `${newHeight}px`;
		};
		switch (buttonName) {
			case IMAGE_BUTTON.UP: {
				let changedHeight = currentHeight + 50;
				if (changedHeight > 900) {
					changedHeight = 900;
				}
				changeImageHeight(changedHeight);

				break;
			}
			case IMAGE_BUTTON.DOWN: {
				let changedHeight = currentHeight - 50;
				if (changedHeight < 256) {
					changedHeight = 256;
				}
				changeImageHeight(changedHeight);
				break;
			}
			default:
				break;
		}
	};

	return (
		<>
			<div className="container-fluid">
				<div className="card row mb-3">
					<div className=" card-body col-12 d-flex justify-content-end align-items-center">
						<span>Images size</span>
						<i
							className="image-size-icon image-size-icon--left d-block bi bi-chevron-up btn btn-outline-light btn-sm"
							onClick={() =>
								handleChangeImageSize(IMAGE_BUTTON.UP)
							}
						></i>
						<i
							className="image-size-icon d-block bi bi-chevron-down btn btn-outline-light btn-sm"
							onClick={() =>
								handleChangeImageSize(IMAGE_BUTTON.DOWN)
							}
						></i>
					</div>
				</div>
			</div>
			<div className="image-video">
				<div className="image-video__content shadow">
					<div className="row w-100 m-0">
						<p className="title text-center mt-3">
							Select Landmark: Colon Start
						</p>
					</div>
					<div className="row justify-content-center w-100 m-0">
						<div className="col-auto">
							<p className="text-cam-1">Cam 1</p>
							<img
								ref={refs.current[0]}
								id="imageCam1"
								src={imageTransparent}
								alt="Cam1"
								style={{ width: '100%' }}
							></img>
						</div>
						<div className="col-auto">
							<p className="text-cam-2">Cam 2</p>
							<img
								ref={refs.current[1]}
								id="imageCam2"
								src={imageTransparent}
								alt="Cam2"
								style={{ width: '100%' }}
							></img>
						</div>
					</div>
					<div className="row pb-5 w-100 m-0"></div>
				</div>
			</div>
		</>
	);
}

ImageVideo.propTypes = {
	images: PropTypes.array,
};

ImageVideo.defaultProps = {
	images: [],
};

export default ImageVideo;
