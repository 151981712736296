import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function ElapsedTime(props) {
	const { caseDetail } = useSelector((state) => state.caseDetail);
	const { allowDragdrop, style, freeze, overrideTimerString } = props;
	const [timer, SetTimer] = useState(0);
	const [timerString, SetTimerString] = useState('');
	const [counter, setCounter] = useState(0);

	useEffect(() => {
		if (freeze) {
			return false;
		}

		const timeout = setTimeout(() => {
			const seconds = counter % 60;
			const minutes = Math.floor(counter / 60) % 60;
			const hours = Math.floor(counter / 3600);
			const secondsSTR =
				seconds.toString().length > 1
					? seconds.toString()
					: `0${seconds.toString()}`;
			const minutesSTR =
				minutes.toString().length > 1
					? minutes.toString()
					: `0${minutes.toString()}`;
			const hoursSTR =
				hours.toString().length > 1
					? hours.toString()
					: `0${hours.toString()}`;
			setCounter(counter + 1);
			SetTimerString(`${hoursSTR}:${minutesSTR}:${secondsSTR}`);
			localStorage.setItem(
				'elapsedTime',
				`${hoursSTR}:${minutesSTR}:${secondsSTR}`
			);
		}, 1000);

		return () => {
			if (allowDragdrop) {
				if (timeout) {
					clearTimeout(timeout);
				}
			} else {
				clearTimeout(timeout);
			}
		};
	}, [counter]);
	useEffect(() => {
		if (caseDetail.elapsedtime) {
			const numberElapseTime = caseDetail.elapsedtime
				.split(':')
				.map((x) => parseInt(x, 10));
			let timeToNumber = 0;
			for (let index = 0; index < numberElapseTime.length; index++) {
				if (index === 0) {
					timeToNumber += numberElapseTime[index] * 60 * 60;
				} else if (index === 1) {
					timeToNumber += numberElapseTime[index] * 60;
				} else {
					timeToNumber += numberElapseTime[index];
				}
			}
			SetTimer(timeToNumber);
			setCounter(0);
		}
	}, [caseDetail.elapsedtime]);
	useEffect(() => {
		if (freeze) return;
		setCounter(counter + timer);
	}, [timer]);
	return (
		<div
			style={{
				marginLeft: allowDragdrop ? '60px' : '40px',
				fontSize: '0.825rem',
				...style,
			}}
		>
			Elapsed Time :{' '}
			{!overrideTimerString ? timerString : overrideTimerString}
		</div>
	);
}
ElapsedTime.propTypes = {
	allowDragdrop: PropTypes.bool,
	style: PropTypes.object,
	freeze: PropTypes.bool,
	overrideTimerString: PropTypes.string,
};

ElapsedTime.defaultProps = {
	allowDragdrop: true,
	style: {},
	freeze: false,
	overrideTimerString: '',
};
export default ElapsedTime;
