import { USERS_ITEM_PER_PAGE } from '../utils/constants';
import http from '../utils/http';

export default class UserControlListService {
	static async getUserControlList() {
		try {
			const { data } = await http.get(`/api/admin/users`);
			let totalpages = 0;
			if (data && data.length > 0) {
				totalpages = Math.ceil(data.length / USERS_ITEM_PER_PAGE);
			}
			return { users: data, pagination: { totalpages } };
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getFilterOptions() {
		try {
			const { data } = await http.get(`/api/admin/roles`);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async checkUserExist(userName) {
		try {
			const { data } = await http.get(`/api/admin/users/${userName}`);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async createNewUser({ email, fullName, password, userRoles }) {
		const body = {
			username: email,
			displayname: fullName,
			nickname: fullName.split(' ').join(''),
			password,
			forcechangepasswordnextsignin: true,
			roleid: userRoles,
		};
		try {
			const { data } = await http.post(`/api/admin/users`, body);
			return data;
		} catch (error) {
			return error;
		}
	}

	static async assignRole({ userId, roleId }) {
		const body = {
			userid: userId,
			roleid: roleId,
		};
		try {
			const { data } = await http.put(`/api/admin/users`, body);
			return data;
		} catch (error) {
			return error;
		}
	}

	static async unAssignRole(unAssignId) {
		try {
			const { data } = await http.delete(
				`/api/admin/users/${unAssignId}`
			);
			return data;
		} catch (error) {
			return error;
		}
	}
}
