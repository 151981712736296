import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../components/Pagination';
import {
	fetchVideoAdminList,
	setPage,
} from '../../store/slices/VideoAdminListSlice';
import VideoAdministrationFilter from './components/VideoAdministrationFilter';
import VideoAdministrationList from './components/VideoAdministrationList';

function VideoAdministrationPage() {
	const { totalPages, page } = useSelector(
		(state) => state.videoAdminList.pagination
	);
	const dispatch = useDispatch();

	const onPageChange = async (newPage) => {
		dispatch(setPage(newPage));
		await dispatch(fetchVideoAdminList());
	};
	return (
		<>
			<h4>Recent cases</h4>
			<VideoAdministrationFilter />
			<VideoAdministrationList />
			{totalPages > 1 && (
				<Pagination
					totalPages={totalPages}
					page={page}
					onPageChange={onPageChange}
				/>
			)}
		</>
	);
}

export default VideoAdministrationPage;
