import http from '../utils/http';

export default class FooterService {
	static async getVersion() {
		try {
			const { data } = await http.get(`api/system/info`);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
