import React, { useRef } from 'react';
import './Styles.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DeleteMark from '../../../../components/DeleteMark/index';
import {
	clearDetection,
	clearSimilar,
} from '../../../../store/slices/CaseSummarySlice';
import ImageMarker from '../../../../components/ImageMarker';

export default function Detection(props) {
	const dispatch = useDispatch();
	const imageRef = useRef();

	const removeDetection = (index) => {
		dispatch(clearDetection(index));
	};
	const removeDetectionSimilarItem = (rowIndex, similarIndex) => {
		dispatch(
			clearSimilar({
				rowIndex,
				similarIndex,
			})
		);
	};

	const { detection, rowIndex } = props;
	const hasDetections = detection && Array.isArray(detection.markers);

	return (
		<div className="mt-4 row detection-row g-2">
			<div className="col d-flex detection-row__first-col">
				<div className="row">
					<div className="col" style={{ maxWidth: 300 }}>
						<div className="d-flex justify-content-between">
							<h2 className="detection-row__title">
								Detection {rowIndex + 1}
							</h2>
							<DeleteMark
								className="cursor-pointer hide-on-export"
								onClick={() => removeDetection(rowIndex)}
							/>
						</div>

						<div ref={imageRef}>
							<ImageMarker
								showIndex
								image={detection.imageUrl}
								markers={detection.markers}
							/>
						</div>
					</div>

					<div className="col-lg px-2 mt-4">
						<ul className="detection-row__info">
							<li>
								<strong>Time:</strong> {detection.time}
							</li>
							<li>
								<strong>Camera:</strong> {detection.camera}
							</li>

							{hasDetections &&
								detection.markers.map((finding, idx) => (
									<li key={finding.id}>
										<strong>Finding {idx + 1}</strong>:{' '}
										{finding.description}
									</li>
								))}
						</ul>
					</div>
				</div>
			</div>

			<div className="col d-flex align-content-start flex-wrap">
				{detection.similar &&
					detection.similar.map((similarItem, similarItemIndex) => (
						<div key={similarItem.id} className="px-2 w-20 mb-3">
							<div className="d-flex justify-content-between">
								<h3 className="detection-row__subtitle">
									Similar {rowIndex + 1}.
									{similarItemIndex + 1}
								</h3>

								<DeleteMark
									className="cursor-pointer hide-on-export"
									onClick={() =>
										removeDetectionSimilarItem(
											rowIndex,
											similarItemIndex
										)
									}
								/>
							</div>
							<img
								src={similarItem.imageUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					))}
			</div>
		</div>
	);
}

Detection.propTypes = {
	detection: PropTypes.object.isRequired,
	rowIndex: PropTypes.number.isRequired,
};
