import { configureStore } from '@reduxjs/toolkit';
import caseListReducer from './slices/CaseListSlice';
import caseDetailReducer from './slices/CaseDetailSlice';
import caseSummaryReducer from './slices/CaseSummarySlice';
import userControlReducer from './slices/UserControlSlice';
import videoAdminListReducer from './slices/VideoAdminListSlice';
import footerReducer from './slices/FooterSlice';

const store = configureStore({
	reducer: {
		caseList: caseListReducer,
		caseDetail: caseDetailReducer,
		caseSummary: caseSummaryReducer,
		userControlList: userControlReducer,
		videoAdminList: videoAdminListReducer,
		footer: footerReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export default store;
