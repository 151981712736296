import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../../utils/authConfig';
import './SignIn.scss';

function handleLogin(instance) {
	instance.loginRedirect(loginRequest).catch((e) => {
		console.error(e);
	});
}

function SignInButton() {
	const { instance } = useMsal();

	return (
		<button
			variant=" secondary "
			className=" btn-login "
			onClick={() => handleLogin(instance)}
			type="button"
		>
			Sign in
		</button>
	);
}

export default SignInButton;
