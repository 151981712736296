import { useMsal } from '@azure/msal-react';
import { debounce, findLastIndex, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import MarkModal, { ImageCreation } from '../../components/MarkModal';
import CaseDetailService from '../../services/CaseDetailService';
import CaseOverviewService from '../../services/CaseOverviewService';
import {
	addSeen,
	addTrackNegative,
	fetchCaseDetailHistory,
	removeSeen,
	removeTrackNegative,
	setAllowDragdrop,
	setCentralFrame,
	setCurrentImages,
	setIndexTimestamp,
	setInspected,
	setMarkDetections,
	setSimilarFrameIds,
	setSimilarImages,
	setSimilarIndex,
	setUndoTime,
	toggleFinding,
	toggleHeatmap,
	toggleSimilar,
	updateCaseDetail,
	updateCaseDetailHistory,
	updateCurrentImages,
	updateFrameStartOfCaseDetail,
	updateSimilarForMarkDetections,
	updateValidatedSimilarImages,
	setIgnoredIndexTimestamp,
} from '../../store/slices/CaseDetailSlice';
import { CAMERA, FPL, CaseStatus } from '../../utils/constants';
import './CaseOverviewPage.scss';
import CaseOverviewDetail from './components/CaseOverviewDetail';
import CaseOverviewFinding from './components/CaseOverviewFinding';
import ImageSlider from './components/ImageSlider';
import SimilarImagesModal from './components/SimilarImagesModal';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '600px',
	},
	overlay: {
		zIndex: 9999,
	},
};

function CaseOverviewPage() {
	const markModalRef = useRef(null);
	const imageRef = useRef(null);
	const inforRef = useRef(null);
	const {
		currentImages,
		showHeatmap,
		showFinding,
		showSimilar,
		imageBarService,
		indexTimestamp,
		inspected,
		detection,
		markDetections,
		history,
		undoTime,
		trackNegative,
		centralFrame,
		allowDragdrop,
		seen,
		frameStart,
		caseDetail,
		similars,
		ignoredIndexTimestamp,
	} = useSelector((state) => state.caseDetail);
	const dispatch = useDispatch();
	const similarContainerRef = useRef(null);
	const [height, setHeight] = useState(0);
	const params = useParams();
	const htr = useHistory();
	const [step, setStep] = useState(0);
	const [stepArr, setStepArr] = useState([0]);
	const [isCleaning, setIsCleaning] = useState(false);
	const [isUndoing, setIsUndoing] = useState(false);
	const [isHeatMapping, setIsHeatMapping] = useState(false);
	const [isNexting, setIsNexting] = useState(false);
	const [showModalConfirmComplete, setShowModalConfirmComplete] =
		useState(false);
	const [showInstructionSimilar, setShowInstructionSimilar] = useState(false);
	const [isShowSimilarModal, setIsShowSimilarModal] = useState(false);
	const [tmpTrackItems, setTmpTrackItems] = useState([]);
	const [markedFrame, setMarkedFrame] = useState(null);
	const { accounts } = useMsal();
	const fullName = accounts[0] && accounts[0].name;

	const loadImagesByProb = async (idxTms) => {
		const centralFrameID = imageBarService.currentTms(idxTms);
		const body = [];
		const padding = Math.floor(FPL / 2);
		const cam = imageBarService.currentCam(idxTms);
		const camlength = imageBarService.getMaxFrameCameraOne();
		for (
			let index = centralFrameID - padding;
			index <= centralFrameID + padding;
			index++
		) {
			body.push({
				frameid: index,
				cameraid: cam,
				camlength,
				isexplain: showHeatmap,
			});
		}
		const response = await CaseDetailService.getImagesByFrameIds(
			+params.id,
			body
		);
		return response.map((x) => ({
			frameID: x.frameid,
			src: x.img_base64,
			cam,
		}));
	};

	const loadSimilarImagesByFrameIds = async (similarImages) => {
		const body = [];
		const camlength = imageBarService.getMaxFrameCameraOne();
		similarImages.forEach(({ frame, cam }) => {
			body.push({
				frameid: frame,
				cameraid: cam,
				camlength,
				isexplain: showHeatmap,
			});
		});
		const response = await CaseDetailService.getImagesByFrameIds(
			+params.id,
			body
		);
		return response.map((x) => ({
			frameID: x.frameid,
			src: x.img_base64,
			cam: x.cameraid,
		}));
	};

	const onUpdateElapsedTime = () => {
		const timer = localStorage.getItem('elapsedTime');
		const obj = {
			caseId: caseDetail.caseid,
			casestatus: CaseStatus.IN_PROGRESS,
			modifiedby: fullName,
			startframeid: frameStart,
			elapsedtime:
				caseDetail.casestatus === CaseStatus.COMPLETED
					? caseDetail.elapsedtime
					: timer,
			iscaseoverview: true,
		};
		dispatch(updateFrameStartOfCaseDetail(obj));
		dispatch(
			updateCaseDetail({
				caseStatus: CaseStatus.IN_PROGRESS,
				modifiedBy: fullName,
				startFrameID: frameStart,
				elapsedTime:
					caseDetail.casestatus === 3
						? caseDetail.elapsedtime
						: timer,
			})
		);
	};

	const onCaseSummaryPrepared = (modifiedBy) => {
		const isCaseCompleted = caseDetail.casestatus === CaseStatus.COMPLETED;
		const timer = localStorage.getItem('elapsedTime');
		const elapsedTime = isCaseCompleted ? caseDetail.elapsedtime : timer;

		if (!isCaseCompleted) {
			const request = {
				caseId: caseDetail.caseid,
				casestatus: caseDetail.casestatus,
				modifiedby: modifiedBy,
				startframeid: frameStart,
				elapsedtime: elapsedTime,
				iscaseoverview: true,
			};
			dispatch(updateFrameStartOfCaseDetail(request));
		}

		dispatch(
			updateCaseDetail({
				caseStatus: caseDetail.casestatus,
				modifiedBy,
				startFrameID: frameStart,
				elapsedTime,
			})
		);
	};

	const handleSetMarkDetections = (newMarkDetections) => {
		dispatch(setMarkDetections([...markDetections, ...newMarkDetections]));
	};

	const saveHistoryAndRefeshFrames = async (
		trackedItems = [],
		callback,
		beforeUpdateCallback,
		confirmSimilarList = []
	) => {
		dispatch(
			addSeen(
				trackedItems.map((x) => ({
					frame: x.frame,
					cam: x.cam,
				}))
			)
		);
		dispatch(addTrackNegative(trackedItems));
		let nextIndexTimestamp = indexTimestamp + 1;
		const totalTimestamp = imageBarService.getTotalInspected();

		const ignoredIndex = [];

		for (let index = indexTimestamp + 1; index < totalTimestamp; index++) {
			const cam = imageBarService.currentCam(index);
			const frame = imageBarService.currentTms(index);
			if (
				seen.findIndex((x) => x.frame === frame && x.cam === cam) === -1
			) {
				nextIndexTimestamp = index;
				break;
			}
			ignoredIndex.push(index);
		}

		if (ignoredIndex.length > 0) {
			dispatch(
				setIgnoredIndexTimestamp([
					...ignoredIndexTimestamp,
					...ignoredIndex,
				])
			);
		}

		const base64Images = await loadImagesByProb(nextIndexTimestamp);
		setIsCleaning(false);
		dispatch(setIndexTimestamp(nextIndexTimestamp));
		dispatch(setCurrentImages(base64Images));
		dispatch(setCentralFrame(base64Images[Math.floor(FPL / 2)]));
		dispatch(setInspected(seen.length + trackedItems.length));
		setStep(0);
		setStepArr([0]);
		if (beforeUpdateCallback) {
			beforeUpdateCallback();
		}
		if (confirmSimilarList && confirmSimilarList.length > 0) {
			dispatch(updateValidatedSimilarImages(confirmSimilarList));
			dispatch(updateSimilarForMarkDetections(confirmSimilarList));
		}
		await dispatch(updateCaseDetailHistory(+params.id));
		if (callback) {
			callback();
		}
		onUpdateElapsedTime();
	};

	const onHandleNextButton = async () => {
		setStepArr([...stepArr, step + 1]);
		setStep(step + 1);
		setIsNexting(true);
		const cam = imageBarService.currentCam(indexTimestamp);
		const nextFrameId = currentImages[6].frameID + 1;
		const camlength = imageBarService.getMaxFrameCameraOne();
		const image = await CaseDetailService.getImagesByFrameIds(+params.id, [
			{
				frameid: nextFrameId,
				cameraid: cam,
				camlength,
				isexplain: showHeatmap,
			},
		]);
		dispatch(
			updateCurrentImages({
				src: image[0].img_base64,
				frameID: nextFrameId,
				cam,
			})
		);
		setIsNexting(false);
	};

	const onHandlePrevButton = async () => {
		setStepArr([...stepArr, step - 1]);
		setStep(step - 1);
		setIsNexting(true);
		const prevFrameId = currentImages[0].frameID - 1;
		const cam = imageBarService.currentCam(indexTimestamp);
		const camlength = imageBarService.getMaxFrameCameraOne();
		const image = await CaseDetailService.getImagesByFrameIds(+params.id, [
			{
				frameid: prevFrameId,
				cameraid: cam,
				camlength,
				isexplain: showHeatmap,
			},
		]);
		setIsNexting(false);
		dispatch(
			updateCurrentImages({
				src: image[0].img_base64,
				frameID: prevFrameId,
				cam,
			})
		);
	};

	const onBackToCentralFrame = async () => {
		const body = [];
		const padding = Math.floor(FPL / 2);
		if (!showSimilar) {
			setStep(0);
			const cam = imageBarService.currentCam(indexTimestamp);
			const camlength = imageBarService.getMaxFrameCameraOne();
			for (
				let index = centralFrame.frameID - padding;
				index <= centralFrame.frameID + padding;
				index++
			) {
				body.push({
					cameraid: cam,
					frameid: index,
					camlength,
					isexplain: showHeatmap,
				});
			}

			const response = await CaseDetailService.getImagesByFrameIds(
				+params.id,
				body
			);
			const images = response.map((x) => ({
				src: x.img_base64,
				frameID: x.frameid,
				cam,
			}));
			dispatch(setCurrentImages(images));
		}
	};

	const getMinFrameId = () =>
		Math.min(...stepArr) - Math.floor(FPL / 2) + centralFrame.frameID;

	const getMaxFrameId = () =>
		Math.max(...stepArr) + Math.floor(FPL / 2) + centralFrame.frameID;

	const onHandleClean = async () => {
		setIsCleaning(true);
		if (undoTime < 3) {
			dispatch(setUndoTime(1));
		}
		const trackNegativeItem = [];
		let min = 0;
		let max = 0;
		if (stepArr.length === 1) {
			const currentFrameIds = currentImages.map((x) => x.frameID);
			min = Math.min(...currentFrameIds);
			max = Math.max(...currentFrameIds);
		} else {
			min = getMinFrameId();
			max = getMaxFrameId();
		}
		const cam = imageBarService.currentCam(indexTimestamp);
		const camlength = imageBarService.getMaxFrameCameraOne();
		for (let index = min; index <= max; index++) {
			const actualFrameInData = index;
			if (seen.findIndex((x) => x.frame === actualFrameInData) === -1) {
				trackNegativeItem.push({
					frame: actualFrameInData,
					text: 'Clear',
					mark: 'Clear',
					score: imageBarService.getScore(actualFrameInData),
					timestamp: imageBarService.getTimestamp(actualFrameInData),
					thumbnail: imageBarService.getThumbnail(actualFrameInData),
					y_pred: imageBarService.getRealY(actualFrameInData),
					cam,
					positions: 'No circles',
					seq_id: `Seq_${actualFrameInData}`,
					num_detection: 0,
					range_visualized: 'Clear',
					frame_line:
						cam === CAMERA.TWO
							? actualFrameInData + camlength
							: actualFrameInData,

					context: [],
				});
			}
		}

		saveHistoryAndRefeshFrames(trackNegativeItem);
	};

	const onMarkComplete = async (trackedItems, newTrackedMark) => {
		const similar = imageBarService.getSimilar(
			newTrackedMark[0].frame,
			newTrackedMark[0].cam,
			seen
		);
		if (similar.length !== 0) {
			setShowInstructionSimilar(true);
			if (showFinding) {
				dispatch(toggleFinding());
			}
			dispatch(toggleSimilar());
			dispatch(setSimilarFrameIds(similar));
			const images = await loadSimilarImagesByFrameIds(similar);
			dispatch(setSimilarIndex(0));
			dispatch(setSimilarImages(images));
			setTmpTrackItems(trackedItems);
			const camlength = imageBarService.getMaxFrameCameraOne();
			const markedHeatmapImage =
				await CaseDetailService.getImagesByFrameIds(+params.id, [
					{
						cameraid: newTrackedMark[0].cam,
						frameid: newTrackedMark[0].frame,
						camlength,
						isexplain: false,
					},
					{
						cameraid: newTrackedMark[0].cam,
						frameid: newTrackedMark[0].frame,
						camlength,
						isexplain: true,
					},
				]);
			setMarkedFrame({
				...newTrackedMark[0],
				normalBase64: markedHeatmapImage[0].img_base64,
				heatmapBase64: markedHeatmapImage[1].img_base64,
			});

			onUpdateElapsedTime();
		} else {
			saveHistoryAndRefeshFrames(trackedItems);
		}
		dispatch(setUndoTime(-undoTime));
		handleSetMarkDetections(newTrackedMark);
	};

	const onHandleUndo = async () => {
		setIsUndoing(true);

		const lastCleanIndex = findLastIndex(trackNegative, (track) =>
			track.every((x) => x.mark === 'Clear')
		);

		const trackNegativeItem = trackNegative[lastCleanIndex];
		if (trackNegativeItem) {
			if (undoTime > 0) {
				dispatch(setUndoTime(-1));
			}

			dispatch(
				removeSeen(
					trackNegativeItem.map((x) => ({
						frame: x.frame,
						cam: x.cam,
					}))
				)
			);

			let previousLastCleanIndex = indexTimestamp - 1;
			const removeIgnoredIndex = [...ignoredIndexTimestamp];
			do {
				if (!ignoredIndexTimestamp.includes(previousLastCleanIndex)) {
					break;
				}
				const previousIndex = previousLastCleanIndex;
				const index = removeIgnoredIndex.findIndex(
					(x) => x === previousIndex
				);
				removeIgnoredIndex.splice(index, 1);
				previousLastCleanIndex--;
			} while (previousLastCleanIndex >= 0);
			if (removeIgnoredIndex.length !== ignoredIndexTimestamp.length) {
				dispatch(setIgnoredIndexTimestamp(removeIgnoredIndex));
			}

			dispatch(removeTrackNegative(lastCleanIndex));
			const base64Images = await loadImagesByProb(previousLastCleanIndex);
			dispatch(setIndexTimestamp(previousLastCleanIndex));
			dispatch(setCurrentImages(base64Images));
			dispatch(setCentralFrame(base64Images[Math.floor(FPL / 2)]));
			dispatch(setInspected(seen.length - trackNegativeItem.length));
			setStep(0);
			setStepArr([0]);
			await dispatch(updateCaseDetailHistory(+params.id));
			onUpdateElapsedTime();
		}
		setIsUndoing(false);
	};

	const setupForCaseSummary = async () => {
		const isCaseCompleted = caseDetail.casestatus === CaseStatus.COMPLETED;

		if (isCaseCompleted) return true;

		const [data, error] = await CaseOverviewService.completeCase({
			caseId: caseDetail.caseid,
			modifiedby: fullName,
			markDetections,
		});

		if (error || !data || data.error) {
			return false;
		}

		return true;
	};

	const informUpdateCaseFailed = () => {
		console.log('An error occurred. Please try again later');
	};

	const onHandleCompletButton = async () => {
		if (inspected > 0) {
			if (detection > 0) {
				const isSetupOkay = await setupForCaseSummary();

				if (isSetupOkay) {
					onCaseSummaryPrepared(fullName);
					htr.push(`/cases/${caseDetail.caseid}/summary`);
					return;
				}

				informUpdateCaseFailed();
				return;
			}

			setShowModalConfirmComplete(true);
		}
	};

	const onHandleConfirm = async () => {
		setShowModalConfirmComplete(false);

		const isSetupOkay = await setupForCaseSummary();
		if (isSetupOkay) {
			onCaseSummaryPrepared();
			htr.push(`/cases/${caseDetail.caseid}/summary`);
			return;
		}
		informUpdateCaseFailed();
	};

	const handleUpdateDimensions = debounce(() => {
		setHeight(
			(imageRef.current?.clientHeight || 0) +
				(inforRef.current?.clientHeight || 0) +
				75
		);
	}, 1000);

	const onHandleConfirmButton = async (
		confirmSimilarImagesList,
		callback
	) => {
		const confirmSimilarList = confirmSimilarImagesList.map(
			({ frameID, cam }) => {
				return {
					frame: frameID,
					cam,
				};
			}
		);
		await saveHistoryAndRefeshFrames(
			tmpTrackItems,
			null,
			null,
			confirmSimilarList
		);
		dispatch(setSimilarIndex(0));
		dispatch(setSimilarImages([]));
		dispatch(setSimilarFrameIds([]));
		dispatch(toggleSimilar());
		callback();
		setIsShowSimilarModal(false);
	};

	useEffect(() => {
		if (allowDragdrop) {
			dispatch(setAllowDragdrop(false));
		}

		dispatch(fetchCaseDetailHistory(+params.id));

		handleUpdateDimensions();
		window.addEventListener('resize', handleUpdateDimensions);
		return () => {
			window.removeEventListener('resize', handleUpdateDimensions);
			dispatch(setMarkDetections([]));
		};
	}, []);

	useEffect(() => {
		if (imageBarService && frameStart === imageBarService.getFrameStart()) {
			loadImagesByProb(indexTimestamp).then((base64Images) => {
				dispatch(setCurrentImages(base64Images));
				dispatch(setCentralFrame(base64Images[Math.floor(FPL / 2)]));
			});
		}
	}, [imageBarService]);

	const onToggleHeatmap = async () => {
		setIsHeatMapping(true);
		const { cam } = currentImages[0];
		const camlength = imageBarService.getMaxFrameCameraOne();
		const body = currentImages.map((x) => ({
			frameid: x.frameID,
			cameraid: x.cam,
			camlength,
			isexplain: !showHeatmap,
		}));
		let response = await CaseDetailService.getImagesByFrameIds(
			+params.id,
			body
		);
		dispatch(
			setCurrentImages(
				response.map((x) => ({
					frameID: x.frameid,
					src: x.img_base64,
					cam,
				}))
			)
		);

		if (showSimilar && similars.images.length > 0) {
			const { images } = similars;
			const { cam: camSimilar } = images[0];
			const bodySimilar = images.map((x) => ({
				frameid: x.frameID,
				cameraid: x.cam,
				camlength,
				isexplain: !showHeatmap,
			}));

			response = await CaseDetailService.getImagesByFrameIds(
				+params.id,
				bodySimilar
			);
			dispatch(
				setSimilarImages(
					response.map((x) => ({
						frameID: x.frameid,
						src: x.img_base64,
						cam: camSimilar,
					}))
				)
			);
		}
		dispatch(toggleHeatmap());
		setIsHeatMapping(false);
	};

	const handleToggleFinding = () => {
		dispatch(toggleFinding());
		handleUpdateDimensions();
	};

	const onRedirectToCaseList = () => {
		htr.push('/');
	};

	const onAcceptInstruction = () => {
		setShowInstructionSimilar(false);
		setIsShowSimilarModal(true);
	};

	const handleOpenMarkModal = () => {
		if (!centralFrame || showSimilar || !currentImages?.length) {
			return;
		}
		markModalRef.current.openMarkModal();
	};

	return (
		<div className="CaseOverviewPage">
			<Helmet>
				<title>Case Overview | CHI AiSPEED</title>
			</Helmet>
			<div className="container-fluid mt-2">
				<div className={showFinding ? 'row' : ''}>
					<div
						className={`CaseOverviewPage__content ${
							showFinding ? `col-lg-10` : 'col-lg-12'
						}`}
					>
						<div ref={imageRef} className="position-relative">
							<ImageSlider
								images={currentImages}
								isLoading={isNexting}
								onHandleNextButton={onHandleNextButton}
								onHandlePrevButton={onHandlePrevButton}
								step={step}
								enabled={!showSimilar}
								markedFrame={markedFrame}
								showHeatmap={showHeatmap}
								onHandleOpenMarkModal={handleOpenMarkModal}
							/>
							{!showFinding && (
								<div
									className={`position-absolute CaseOverviewPage__setting-icon d-flex cursor-pointer ${
										showSimilar ? 'icon-disabled' : ''
									}`}
									onClick={handleToggleFinding}
								>
									<i className="bi bi-flag m-auto"></i>
								</div>
							)}
						</div>

						{!isEmpty(currentImages) && (
							<div className="d-flex justify-content-center align-items-center my-4 position-relative">
								<button
									type="button"
									className="btn btn-warning text-white button__text px-4 mx-2"
									onClick={() => onHandleUndo()}
									disabled={
										undoTime === 0 ||
										showSimilar ||
										isUndoing ||
										isCleaning
									}
								>
									<i className="bi bi-arrow-left-circle-fill me-2"></i>
									Undo
								</button>
								<button
									type="button"
									className="btn btn-success text-white button__text px-4 mx-2"
									onClick={() => onHandleClean()}
									disabled={
										isCleaning || showSimilar || isUndoing
									}
								>
									<i className="bi bi-stars me-2"></i>
									Clean
								</button>
								<i
									className="bi bi-bullseye mx-2 button__central cursor-pointer"
									onClick={() => onBackToCentralFrame()}
								></i>
								<button
									type="button"
									className="btn btn-info text-white button__text px-4 mx-2"
									onClick={() => onToggleHeatmap()}
									disabled={isHeatMapping}
								>
									<i className="bi bi-image me-2"></i>
									Heatmap
								</button>
								{centralFrame && (
									<MarkModal
										frameIndex={step}
										centralFrame={centralFrame}
										frameIdRange={[
											getMinFrameId(),
											getMaxFrameId(),
										]}
										images={currentImages}
										caseId={+params.id}
										isShowHeatmap={showHeatmap}
										imageBarService={imageBarService}
										onComplete={onMarkComplete}
										onUpdateElapsedTime={
											onUpdateElapsedTime
										}
										showSimilar={showSimilar}
										ref={markModalRef}
									/>
								)}
								<button
									type="button"
									className="btn btn-danger text-white button__text px-4 mx-2 position-absolute button__complete"
									disabled={inspected === 0 || showSimilar}
									onClick={() => onHandleCompletButton()}
								>
									<i className="bi bi-check-all me-2"></i>
									Complete
								</button>
							</div>
						)}

						{showSimilar ? (
							<div ref={similarContainerRef}>
								<SimilarImagesModal
									images={similars.images}
									enabled={showSimilar}
									isSimilar
									onHandleConfirmButton={
										onHandleConfirmButton
									}
									isShowSimilarModal={isShowSimilarModal}
									originalImages={currentImages}
								/>
							</div>
						) : (
							<div ref={inforRef}>
								<CaseOverviewDetail
									caseDetail={caseDetail}
									history={history}
								/>
							</div>
						)}
					</div>

					{showFinding && (
						<div className="col-lg-2 CaseOverviewPage__detection">
							<CaseOverviewFinding
								height={height}
								markDetections={markDetections}
								onCloseFinding={() => dispatch(toggleFinding())}
								imageBarService={imageBarService}
							/>
						</div>
					)}
				</div>

				<div className="d-flex justify-content-center py-4">
					<button
						type="button"
						className="btn btn-warning text-dark button__text px-4 float-end"
						onClick={() => onRedirectToCaseList()}
					>
						To Case List
					</button>
				</div>
			</div>
			<Modal
				isOpen={showModalConfirmComplete}
				style={customStyles}
				contentLabel="Confirm Complete Modal"
			>
				<p>
					No detections found. Are you sure you want to complete the
					case?
				</p>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-warning btn-sm me-1"
						onClick={() => setShowModalConfirmComplete(false)}
					>
						No
					</button>
					<button
						type="button"
						className="btn btn-primary btn-sm"
						onClick={() => onHandleConfirm()}
					>
						Yes
					</button>
				</div>
			</Modal>
			<Modal
				isOpen={showInstructionSimilar}
				style={customStyles}
				contentLabel=""
			>
				<p>
					<i className="bi bi-info-square-fill"></i>&nbsp;
					<span className="fw-bold">Notice:</span>
					&nbsp;The image you have just marked has (a) similar
					image(s). In next step, please confirm if you{' '}
					<span className="fw-bold">agree or disagree</span> the below
					central frame is similar to the above central frame (the
					marked image)
				</p>
				<div className="d-flex justify-content-center">
					<button
						type="button"
						className="btn btn-warning btn-sm me-1 px-5 mx-2"
						style={{ boxShadow: '0px 6px 6px #979797' }}
						onClick={onAcceptInstruction}
					>
						OK
					</button>
				</div>
			</Modal>
			{!markDetections.length && imageBarService && (
				<ImageCreation
					caseId={+params.id}
					trackNegative={trackNegative}
					imageBarService={imageBarService}
					onSetMarkDetections={handleSetMarkDetections}
				/>
			)}
		</div>
	);
}

export default CaseOverviewPage;
