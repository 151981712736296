import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

function Loading(props) {
	const { show, children } = props;
	return (
		<>
			{show && (
				<div className="position-fixed loading-component">
					{children}
				</div>
			)}
		</>
	);
}

Loading.propTypes = {
	show: PropTypes.bool.isRequired,
	children: PropTypes.any.isRequired,
};

export default Loading;
