import React, { useState, useEffect } from 'react';
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
	useIsAuthenticated,
} from '@azure/msal-react';
import { Route, Switch } from 'react-router-dom';
import { registerInterceptor, removeInterceptor } from './utils/http';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import Navbar from './components/Navbar';
import CaseDetailPage from './pages/CaseDetailPage';
import NetworkModal from './components/NetworkModal';
import './App.scss';
import AdminPanelPage from './pages/AdminPanelPage';
import RouteGuard from './components/RouteGuard';
import { ROLE } from './utils/authConfig';
import Footer from './components/Footer';

function App() {
	const { instance, accounts } = useMsal();
	const [isHttpError, setIsHttpError] = useState(false);
	const isAuthenticated = useIsAuthenticated();
	const nameAccount = accounts[0] && accounts[0].name;
	useEffect(() => {
		const interceptors = registerInterceptor(instance, accounts, () => {
			setIsHttpError(true);
		});
		return () => {
			removeInterceptor(interceptors);
		};
	}, [accounts, instance]);
	return (
		<>
			<NetworkModal
				isShowModal={isHttpError}
				onHideModal={() => setIsHttpError(false)}
			/>
			<AuthenticatedTemplate>
				<div
					className="app-content"
					style={{ backgroundColor: '#f2f1f1', height: '100vh' }}
				>
					<Navbar
						isAuthenticated={isAuthenticated}
						nameAccount={nameAccount}
					/>
					<Switch>
						<Route exact path="/" component={HomePage} />
						<Route
							path="/admin-panel"
							component={() => (
								<RouteGuard
									roles={[ROLE.ADMIN, ROLE.GLOBAL_ADMIN]}
								>
									<AdminPanelPage />
								</RouteGuard>
							)}
						/>
						<Route path="/cases/:id">
							<CaseDetailPage />
						</Route>
					</Switch>
					<Footer />
				</div>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<LoginPage />
			</UnauthenticatedTemplate>
		</>
	);
}

export default App;
