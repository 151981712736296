import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CaseListService from '../../services/CaseListService';
import { CaseStatus } from '../../utils/constants';
import { convertTZ } from '../../utils/time';

const initialState = {
	cases: [],
	customerOptions: [],
	modifiedByOptions: [],
	caseStatusOptions: [],
	pagination: {
		totalPages: 1,
		page: 1,
	},
	filterCriteria: {
		caseName: '',
		customers: [],
		modifiedBy: [],
		caseStatus: [CaseStatus.NOT_STARTED, CaseStatus.IN_PROGRESS],
		fullName: '',
	},
	isFetchingData: false,
};

export const fetchCaseList = createAsyncThunk(
	'caseList/fetchCaseList',
	async (arg, { getState }) => {
		const caseListState = getState().caseList;
		const { filterCriteria, pagination } = caseListState;
		const response = await CaseListService.getCaseList({
			...filterCriteria,
			page: pagination.page,
		});
		if (response && response.cases) {
			response.cases = response.cases.map((item) => ({
				...item,
				startdate: convertTZ(item.startdate),
				uploadeddate: convertTZ(item.uploadeddate),
				completeddate: convertTZ(item.completeddate),
			}));
		}
		return response;
	}
);

export const fetchFilterOptions = createAsyncThunk(
	'caseList/fetchFilterOptions',
	async () => {
		const response = await CaseListService.getFilterOptions();
		return response;
	}
);

export const caseListSlice = createSlice({
	name: 'caseList',
	initialState,
	reducers: {
		setPage: (state, action) => {
			state.pagination.page = action.payload;
		},
		setFilterCriteria: (state, action) => {
			state.filterCriteria.caseName = action.payload.caseName;
			state.filterCriteria.caseStatus = action.payload.caseStatus;
			state.filterCriteria.customers = action.payload.customers;
			state.filterCriteria.modifiedBy = action.payload.modifiedBy;
		},
		setFullName: (state, action) => {
			state.filterCriteria.fullName = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCaseList.pending, (state) => {
			state.isFetchingData = true;
		});

		builder.addCase(fetchCaseList.fulfilled, (state, action) => {
			state.isFetchingData = false;
			state.cases = action.payload.cases;
			state.pagination.totalPages = action.payload.pagination.totalpages;
		});

		builder.addCase(fetchFilterOptions.fulfilled, (state, action) => {
			state.customerOptions = action.payload.customers.map((x) => ({
				label: x.customer,
				value: x.customer,
			}));
			state.modifiedByOptions = action.payload.modifiedby.map((x) => ({
				label: x.modifiedby,
				value: x.modifiedby,
			}));
			state.caseStatusOptions = action.payload.casestatus.map((x) => ({
				label: x.casestatusname,
				value: x.casestatus,
			}));
		});
	},
});

export const { setPage, setFilterCriteria, setFullName } =
	caseListSlice.actions;

export default caseListSlice.reducer;
