import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ImageMarker from '../../../../components/ImageMarker';
import './ImageSlider.scss';
import {
	LIMIT_SLIDE,
	PREVENT_SCROLL_STATUS,
} from '../../../../utils/constants';

function ImageSlider(props) {
	const parentRef = useRef();
	const isMouseLeave = useRef(true);
	const [loading, setLoading] = useState(false);
	const { imageBarService, similars } = useSelector(
		(state) => state.caseDetail
	);
	const {
		images,
		onHandleNextButton,
		onHandlePrevButton,
		step,
		enabled,
		isSimilar,
		onHandleConfirmButton,
		onHandleDeclineButton,
		isLoading,
		markedFrame,
		showHeatmap,
		onHandleOpenMarkModal,
	} = props;
	const imagesLength = images.length;
	const disableLeft = (step < 0 && Math.abs(step) >= LIMIT_SLIDE) || !enabled;
	const disableRight =
		(step >= 0 && Math.abs(step) >= LIMIT_SLIDE) || !enabled;
	const disableValidateControl = enabled && isSimilar && step !== 0;
	const { index, frameIds } = similars;

	const onHandlePrev = () => {
		if (disableLeft) {
			return;
		}
		onHandlePrevButton();
	};

	const onHandleNext = () => {
		if (disableRight) {
			return;
		}
		onHandleNextButton();
	};

	const onHandleDecline = () => {
		if (disableValidateControl) {
			return;
		}
		const callback = () => {
			setLoading(false);
		};
		setLoading(true);
		onHandleDeclineButton(callback);
	};

	const onHandleConfirm = () => {
		if (disableValidateControl) {
			return;
		}
		const callback = () => {
			setLoading(false);
		};
		setLoading(true);
		onHandleConfirmButton(callback);
	};

	const preventScroll = useCallback((e) => {
		e.preventDefault();
		return false;
	});

	const toggleScroll = (preventScrollStatus, mouseLeave = false) => {
		switch (preventScrollStatus) {
			case PREVENT_SCROLL_STATUS.ON:
				parentRef.current.addEventListener('wheel', preventScroll);
				break;
			case PREVENT_SCROLL_STATUS.OFF:
				parentRef.current.removeEventListener('wheel', preventScroll);
				break;

			default:
				break;
		}
		isMouseLeave.current = mouseLeave;
	};

	const handleScrollTimeline = (event) => {
		if (event.deltaY < 0 && !disableRight && !isLoading) {
			onHandleNext();
		} else if (event.deltaY > 0 && !disableLeft && !isLoading) {
			onHandlePrev();
		}
		if (!isMouseLeave.current) {
			toggleScroll(PREVENT_SCROLL_STATUS.ON);
		}
	};

	useEffect(() => {
		parentRef.current = document.querySelector('.app-content');
		if (!isMouseLeave.current) {
			toggleScroll(PREVENT_SCROLL_STATUS.ON);
		}
		return () => {
			toggleScroll(PREVENT_SCROLL_STATUS.OFF, isMouseLeave.current);
		};
	});

	return (
		<div className="card mb-1 image-slider">
			<div className="card-body shadow">
				{isSimilar && (
					<div className="row m-0">
						<h6 className="fw-bold">
							Similar Image {`${index + 1}/${frameIds.length}`}
						</h6>
					</div>
				)}
				<div
					className="row"
					style={{ margin: 0 }}
					onWheel={(e) => handleScrollTimeline(e)}
					onMouseEnter={() => toggleScroll(PREVENT_SCROLL_STATUS.ON)}
					onMouseLeave={() =>
						toggleScroll(PREVENT_SCROLL_STATUS.OFF, true)
					}
				>
					{imageBarService &&
						images.map((image, i) => {
							let borderColorStyle =
								imageBarService.getColorFromProb(
									imageBarService.getScoreFromProb(
										image.frameID,
										image.cam
									)
								);
							let score = imageBarService.getScore(
								image.frameID,
								image.cam
							);
							let timestamp = imageBarService.getTimestamp(
								image.frameID,
								image.cam
							);

							if (Math.floor(imagesLength / 2) === i) {
								if (!enabled && markedFrame) {
									borderColorStyle =
										imageBarService.getColorFromProb(
											imageBarService.getScoreFromProb(
												markedFrame.frame,
												markedFrame.cam
											)
										);
									score = imageBarService.getScore(
										markedFrame.frame,
										markedFrame.cam
									);
									timestamp = imageBarService.getTimestamp(
										markedFrame.frame,
										markedFrame.cam
									);
								}
								return (
									<div
										className="image-central__col position-relative"
										style={{
											zIndex:
												!enabled && markedFrame
													? '9999'
													: 'initial',
										}}
										key={image.frameID + Math.random()}
									>
										{!enabled && markedFrame ? (
											<ImageMarker
												markers={markedFrame.positions.map(
													(x) => ({
														...x,
														id: Math.random(),
													})
												)}
												image={
													showHeatmap
														? markedFrame.heatmapBase64
														: markedFrame.normalBase64
												}
											/>
										) : (
											// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
											<img
												className="img-fluid image-slider__image"
												style={{
													borderColor:
														borderColorStyle,
													cursor: 'pointer',
												}}
												src={image.src}
												alt={image.frameID}
												onClick={() =>
													onHandleOpenMarkModal()
												}
											/>
										)}
										<div className="d-flex justify-content-center align-items-center mt-3 image-slider__percent">
											<span
												className="image-slider__info px-3"
												style={{
													borderColor:
														borderColorStyle,
												}}
											>
												{`${score} ${timestamp}`}
											</span>
										</div>
										<div className="image-slider__slider-control position-absolute w-100">
											<i
												className={`bi bi-caret-left-fill image-slider__prev-button position-absolute ${
													disableLeft || isLoading
														? 'disabled'
														: 'cursor-pointer'
												}`}
												onClick={() => onHandlePrev()}
											></i>
											<i
												className={`bi bi-caret-right-fill image-slider__next-button position-absolute ${
													disableRight || isLoading
														? 'disabled'
														: 'cursor-pointer'
												}`}
												onClick={() => onHandleNext()}
											></i>
										</div>
										{enabled && isSimilar && (
											<div className="image-slider__validate-control position-absolute w-100">
												<span
													className={` image-slider__decline-button position-absolute ${
														loading ||
														disableValidateControl
															? 'disabled'
															: 'cursor-pointer'
													}`}
													onClick={() =>
														onHandleDecline()
													}
												>
													<i className="bi bi-x-square"></i>
													<div
														style={{
															fontSize: '0.8rem',
														}}
													>
														Disagree
													</div>
												</span>
												<span
													className={`image-slider__confirm-button position-absolute ${
														loading ||
														disableValidateControl
															? 'disabled'
															: 'cursor-pointer'
													}`}
													onClick={() =>
														onHandleConfirm()
													}
												>
													<i className="bi bi-check2-square"></i>
													<div
														style={{
															fontSize: '0.8rem',
														}}
													>
														Agree
													</div>
												</span>
											</div>
										)}
									</div>
								);
							}
							return (
								<div
									className="image__col"
									key={image.frameID + Math.random()}
								>
									<img
										className="img-fluid image-slider__image"
										src={image.src}
										style={{
											borderColor: borderColorStyle,
										}}
										alt={image.frameID}
									/>
									<div className="d-flex justify-content-center align-items-center mt-3 image-slider__percent">
										<span
											className="image-slider__info  px-3"
											style={{
												borderColor: borderColorStyle,
											}}
										>
											{score}
										</span>
									</div>
								</div>
							);
						})}
				</div>
			</div>
			{!enabled && (
				<div className="position-absolute card-disabled"></div>
			)}
		</div>
	);
}

ImageSlider.propTypes = {
	images: PropTypes.array.isRequired,
	onHandleNextButton: PropTypes.func,
	onHandlePrevButton: PropTypes.func,
	step: PropTypes.number,
	enabled: PropTypes.bool,
	isSimilar: PropTypes.bool,
	onHandleDeclineButton: PropTypes.func,
	onHandleConfirmButton: PropTypes.func,
	isLoading: PropTypes.bool,
	markedFrame: PropTypes.object,
	showHeatmap: PropTypes.bool,
	onHandleOpenMarkModal: PropTypes.func,
};

ImageSlider.defaultProps = {
	onHandleNextButton: null,
	onHandlePrevButton: null,
	step: 0,
	enabled: true,
	isSimilar: false,
	onHandleDeclineButton: null,
	onHandleConfirmButton: null,
	isLoading: false,
	markedFrame: null,
	showHeatmap: false,
	onHandleOpenMarkModal: null,
};

export default ImageSlider;
