import React from 'react';

export default function DeleteMark(props) {
	return (
		<span data-dismiss="alert" aria-label="Close" {...props}>
			<span
				style={{ fontSize: 28, lineHeight: 0 }}
				className="text-danger"
				aria-hidden="true"
			>
				&times;
			</span>
		</span>
	);
}
