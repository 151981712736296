import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import UserControlPage from '../UserControlPage';
import VideoAdministrationPage from '../VideoAdministrationPage';
import './AdminPanel.scss';

function AdminPanelPage() {
	const { path, url } = useRouteMatch();
	return (
		<div className="container-fluid AdminPanelPage">
			<div className="card mt-3">
				<div className="card-body shadow py-0">
					<div className="row admin-panel-wrapper">
						<div className="sidebar py-3 px-0 mx-0">
							<SideBar />
						</div>
						<div className="sidebar-content py-3">
							<Switch>
								<Route exact path={path}>
									<Redirect
										to={`${url}/video-administration`}
									/>
								</Route>
								<Route
									exact
									path={`${path}/user-control`}
									component={UserControlPage}
								/>
								<Route
									exact
									path={`${path}/video-administration`}
									component={VideoAdministrationPage}
								/>
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminPanelPage;
