import http from '../utils/http';

export default class CasDetailService {
	static async getCaseDetail(caseId) {
		try {
			const { data } = await http.get(`/api/case`, {
				params: { caseid: btoa(caseId) },
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getCaseHistory(caseId) {
		try {
			const { data } = await http.get(`/api/case/history`, {
				params: { caseid: btoa(caseId) },
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getListOfCaseImages(caseId) {
		try {
			const { data } = await http.get(`/api/casepreparing`, {
				params: { caseid: btoa(caseId) },
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async updateFrameStartOfCaseDetail(data) {
		try {
			const { response } = await http.put(
				`/api/casepreparing/mark`,
				{
					casestatus: data.casestatus,
					modifiedby: data.modifiedby,
					startframeid: data.startframeid,
					elapsedtime: data.elapsedtime,
					iscaseoverview: data.iscaseoverview,
				},
				{
					params: { caseid: btoa(data.caseId) },
				}
			);
			return response;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getImagesByFrameIds(caseId, frames) {
		try {
			const { data } = await http.post(
				`/api/caseoverview/images`,
				frames,
				{ params: { caseid: btoa(caseId) } }
			);
			return data;
		} catch (error) {
			return [];
		}
	}

	static async getSimlarImagesByIds(caseId, body) {
		try {
			const { data } = await http.post(
				`/api/caseoverview/similarimages`,
				body,
				{ params: { caseid: btoa(caseId) } }
			);
			return data;
		} catch (error) {
			return [];
		}
	}

	static async getCaseDetailHistory(caseId) {
		try {
			const { data } = await http.get(`/api/caseoverview/history`, {
				params: { caseid: btoa(caseId) },
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async updateCaseDetailHistory(caseId, contentValue) {
		try {
			const { data } = await http.post(
				`/api/caseoverview/history`,
				{
					contentvalue: contentValue,
				},
				{ params: { caseid: btoa(caseId) } }
			);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
