import React, { useState, useRef, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './CreateUserModal.scss';
import MultiSelect from 'react-multi-select-component';
import { useSelector } from 'react-redux';
import { USER_ROLE_NAME } from '../../utils/constants';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'visible',
	},
	overlay: {
		zIndex: 10000,
	},
};

const CreateUserModal = (props) => {
	const {
		isShowModal,
		handleHideModal,
		handleAddNewUser,
		userRoleOptions,
		onHandleCheckUserAvailable,
	} = props;
	const { isCheckingUser } = useSelector((state) => state.userControlList);
	const USER_CHECK_MESSAGE = {
		EXIST: 'User available.',
		NOT_EXIST: 'User not available.',
	};

	const formRef = useRef();
	const [userRoles, setUserRoles] = useState([]);
	const [userRoleOptionsFiltered, setUserRoleOptionsFiltered] = useState([]);
	const [userMail, setUserMail] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isUserExist, setIsUserExist] = useState(false);
	const [isUserChecked, setIsUserChecked] = useState(false);
	const [userId, setUserId] = useState('');

	const filterOptions = (options, filter) => {
		if (!filter) {
			return options;
		}
		return options.filter((x) =>
			x.label.toLowerCase().includes(filter.toLowerCase())
		);
	};

	const handleSelect = (value) => {
		if (value.length > 1) {
			value.shift();
		}
		setUserRoles(value);
	};

	const resetForm = () => {
		setUserRoles([]);
		setUserMail('');
		setIsSubmitted(false);
		setIsUserExist(false);
		setUserId('');
		setIsUserChecked(false);
	};

	const onHandleHideModal = () => {
		resetForm();
		handleHideModal();
	};

	const onHandleAddNewUser = (e) => {
		setIsSubmitted(true);
		if (formRef.current.checkValidity()) {
			e.preventDefault();
			if (userRoles.length > 0) {
				const newUser = {
					userId,
					roleId: userRoles[0].value,
				};
				handleAddNewUser(newUser);
				resetForm();
			}
		} else {
			formRef.current.reportValidity();
		}
	};

	const handleCheckUserAvailable = async () => {
		const user = await onHandleCheckUserAvailable(userMail);
		if (!isEmpty(user)) {
			setIsUserExist(true);
			setUserId(user.userid);
		}
		setIsUserChecked(true);
	};

	const handleInputEmail = (e) => {
		setIsUserExist(false);
		setIsUserChecked(false);
		setUserRoles([]);
		setUserMail(e.target.value);
	};

	useEffect(() => {
		setUserRoleOptionsFiltered(
			userRoleOptions.filter(
				(role) => role.label !== USER_ROLE_NAME.GLOBAL_ADMIN
			)
		);
		setIsSubmitted(false);
	}, [userRoleOptions]);

	return (
		<Modal isOpen={isShowModal} ariaHideApp={false} style={customStyles}>
			<div className="container">
				<div className="row d-flex justify-content-between flex-nowrap">
					<h4 className="col-auto">New User</h4>
					<div className="col-auto">
						<button
							type="button"
							className="btn btn-sm btn-light"
							onClick={() => onHandleHideModal()}
						>
							<i className="status-icon d-block bi bi-x-lg"></i>
						</button>
					</div>
				</div>
				<form ref={formRef}>
					<div className="row mt-2">
						<div className="col-md-8">
							<input
								className="form-control form-control-sm input-control"
								type="email"
								placeholder="Email"
								required
								value={userMail}
								onChange={(e) => handleInputEmail(e)}
							/>
						</div>
						<div className="col-auto">
							<button
								type="button"
								className="btn btn-sm btn-primary"
								onClick={() => handleCheckUserAvailable()}
							>
								{isCheckingUser ? (
									<div
										className="spinner-border spinner-border-sm text-light"
										role="status"
									>
										<span className="visually-hidden">
											Loading...
										</span>
									</div>
								) : (
									'Check Available '
								)}
							</button>
						</div>
					</div>
					{isUserChecked && (
						<div className="row mt-2">
							<div className="col-md-8">
								<span
									className={
										isUserExist
											? 'check-message--success'
											: 'check-message--error'
									}
								>
									{isUserExist
										? USER_CHECK_MESSAGE.EXIST
										: USER_CHECK_MESSAGE.NOT_EXIST}
								</span>
							</div>
						</div>
					)}
					<div className="row mt-1 mb-2">
						<div className="col-md-8">
							{userRoleOptionsFiltered.length !== 0 && (
								<MultiSelect
									options={userRoleOptionsFiltered}
									value={userRoles}
									onChange={handleSelect}
									isLoading={
										userRoleOptionsFiltered.length === 0
									}
									labelledBy="Select"
									filterOptions={filterOptions}
									hasSelectAll={false}
									disabled={!isUserExist}
								/>
							)}
						</div>
					</div>
					{isSubmitted && userRoles.length === 0 && (
						<div className="row mt-2">
							<div className="col-md-8">
								<span className="role-error">
									* Please select a role for this account!
								</span>
							</div>
						</div>
					)}

					<div className="row d-flex justify-content-start">
						<div className="col-auto">
							<button
								type="submit"
								className="btn btn-sm btn-success"
								disabled={!isUserExist}
								onClick={(e) => onHandleAddNewUser(e)}
							>
								Add New User
							</button>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};

CreateUserModal.propTypes = {
	isShowModal: PropTypes.bool,
	handleHideModal: PropTypes.func.isRequired,
	handleAddNewUser: PropTypes.func.isRequired,
	onHandleCheckUserAvailable: PropTypes.func.isRequired,
	userRoleOptions: PropTypes.array,
};

CreateUserModal.defaultProps = {
	isShowModal: false,
	userRoleOptions: [],
};

export default CreateUserModal;
