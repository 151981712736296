import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchFilterOptions,
	searchUsers,
	setFilterCriteria,
	setPage,
} from '../../../../store/slices/UserControlSlice';
import './UserControlFilter.scss';

function UserControlFilter() {
	const dispatch = useDispatch();
	const { userRoleOptions, isFetchingData } = useSelector(
		(state) => state.userControlList
	);

	const [userRoles, setUserRoles] = useState([]);

	const [userName, setUserName] = useState('');

	const handleApplyFilter = async () => {
		const criteria = {
			userName,
			userRoles,
		};
		dispatch(setPage(1));
		dispatch(setFilterCriteria(criteria));
		await dispatch(searchUsers());
	};

	const handleOnKeyDown = async (e) => {
		if (e.key === 'Enter' && e.code === 'Enter') {
			await handleApplyFilter();
		}
	};

	useEffect(() => {
		dispatch(fetchFilterOptions());
	}, []);

	useEffect(() => {
		if (userRoleOptions && userRoleOptions.length > 0) {
			setUserRoles(userRoleOptions);
		}
	}, [userRoleOptions]);

	const filterOptions = (options, filter) => {
		if (!filter) {
			return options;
		}
		return options.filter((x) =>
			x.label.toLowerCase().includes(filter.toLowerCase())
		);
	};

	return (
		<div className="row d-flex align-items-center UserControlPage-filter">
			<div className="col-lg-12">
				<div className="row d-flex align-items-center">
					<div className="col-lg-2 my-1 position-relative">
						<input
							className="form-control form-control-sm input-control"
							type="text"
							name="userName"
							id="userName"
							placeholder="Name"
							value={userName}
							onChange={(e) => {
								setUserName(e.target.value);
							}}
							onKeyDown={handleOnKeyDown}
						/>

						<i
							className={`close-icon bi bi-x ${
								userName ? '' : 'd-none'
							}`}
							onClick={() => {
								setUserName('');
							}}
						></i>
					</div>
					<div className="col-lg-2 my-1">
						{userRoleOptions.length !== 0 && (
							<MultiSelect
								options={userRoleOptions}
								value={userRoles}
								onChange={setUserRoles}
								isLoading={userRoleOptions.length === 0}
								labelledBy="Select"
								filterOptions={filterOptions}
								overrideStrings={{
									selectSomeItems: 'Roles',
									selectAll: 'All',
								}}
							/>
						)}
					</div>
					<div className="col-auto my-1">
						<button
							type="button"
							className="btn btn-sm btn-primary w-100 btn-search"
							onClick={() => {
								handleApplyFilter();
							}}
						>
							{isFetchingData ? (
								<div
									className="spinner-border spinner-border-sm text-light"
									role="status"
								>
									<span className="visually-hidden">
										Loading...
									</span>
								</div>
							) : (
								'Apply'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserControlFilter;
