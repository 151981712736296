import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useMsal } from '@azure/msal-react';
import { Link, Route } from 'react-router-dom';
import SignOut from '../SignOut';
import { loginRequest, ROLE } from '../../utils/authConfig';
import imageLogo from '../../assets/images/corporatehealth.png';
import './Navbar.scss';

function Navbar(props) {
	const { instance, accounts } = useMsal();
	const [photo, setPhoto] = useState('');
	const [accountRoles, setAccountRoles] = useState([]);
	const { isAuthenticated, nameAccount } = props;
	useEffect(() => {
		instance
			.acquireTokenSilent({
				account: accounts[0],
				scopes: loginRequest.scopes,
			})
			.then((tokenResponse) => {
				const headers = new Headers();
				const bearer = `Bearer ${tokenResponse.accessToken}`;
				headers.append('Authorization', bearer);
				const options = {
					method: 'GET',
					responseType: 'blob',
					headers,
				};
				fetch(
					`https://graph.microsoft.com/v1.0/me/photo/$value`,
					options
				)
					.then((response) => response.blob())
					.then((data) => {
						const urlCreator = window.URL || window.webkitURL;
						const imgUrl = urlCreator.createObjectURL(data);
						setPhoto(imgUrl);
					})
					.catch(() => {
						setPhoto('');
					});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (
			accounts &&
			accounts.length > 0 &&
			accounts[0]?.idTokenClaims?.roles
		) {
			setAccountRoles([...accounts[0]?.idTokenClaims?.roles]);
		}
	}, [accounts]);

	const activeForRole = (roles) => {
		const intersection = roles.filter((role) =>
			accountRoles.includes(role)
		);
		if (intersection && intersection.length > 0) {
			return true;
		}
		return false;
	};

	return (
		<Route>
			<div className="app-nav">
				<nav
					className="navbar navbar-expand-lg navbar-light"
					id="navbar"
				>
					<div className="container-fluid">
						<img
							src={imageLogo}
							alt="AiSPEED"
							className="navbar-logo"
						/>
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon" />
						</button>
						<div
							className="collapse navbar-collapse"
							id="navbarSupportedContent"
						>
							<ul className="navbar-nav me-auto ml-5 mb-2 mb-lg-0 ">
								<li className="nav-item">
									<Link className="nav-link" to="/">
										Home
									</Link>
								</li>
								{activeForRole([
									ROLE.ADMIN,
									ROLE.GLOBAL_ADMIN,
								]) && (
									<li className="nav-item">
										<Link
											className="nav-link"
											to="/admin-panel"
										>
											Admin Panel
										</Link>
									</li>
								)}
							</ul>
							<div className="d-flex">
								<ul className="navbar-nav me-auto mb-2 mb-lg-0 text-center">
									<li className="nav-item dropdown ">
										<div className="custom-toggle">
											<p
												className="nav-link dropdown-toggle"
												id="navbarDropdown"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<img
													src={
														!photo
															? imageLogo
															: photo
													}
													alt="avatar"
													className="avatar"
												/>
												<span className="fullname">
													{!nameAccount
														? 'Full Name'
														: nameAccount}
												</span>
												<svg
													width="16"
													height="16"
													fill="currentColor"
													className="bi bi-chevron-down"
													viewBox="0 0 16 16"
												>
													<path
														fillRule="evenodd"
														d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
													/>
												</svg>
											</p>
											<ul
												className="dropdown-menu"
												aria-labelledby="navbarDropdown"
											>
												<li>
													<div className="dropdown-item text-center">
														{isAuthenticated && (
															<SignOut />
														)}
													</div>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</div>
		</Route>
	);
}

Navbar.propTypes = {
	isAuthenticated: PropTypes.bool,
	nameAccount: PropTypes.string,
};
Navbar.defaultProps = {
	isAuthenticated: false,
	nameAccount: '',
};

export default Navbar;
