import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserControlListService from '../../services/UserControlListService';
import { USERS_ITEM_PER_PAGE, USER_ROLE_NAME } from '../../utils/constants';
import { convertTZ } from '../../utils/time';

const initialState = {
	users: [],
	userMasterList: [],
	userSearchList: [],
	userRoleOptions: [],
	pagination: {
		totalPages: 5,
		page: 1,
	},
	totalPageMaster: 5,
	filterCriteria: {
		userName: '',
		userRoles: [],
		fullName: '',
	},
	isFetchingData: false,
	isCheckingUser: false,
};

export const fetchUserControlList = createAsyncThunk(
	'userControlList/fetchUserControlList',
	async (arg, { getState }) => {
		const userControlListState = getState().userControlList;
		const { filterCriteria } = userControlListState;
		const response = await UserControlListService.getUserControlList({
			...filterCriteria,
		});
		if (response && response.users) {
			response.users = response.users.map((item) => ({
				...item,
				createddatetime: convertTZ(item.createddatetime),
			}));
		}
		return response;
	}
);

export const assignUserRole = createAsyncThunk(
	'userControlList/assignUserRole',
	async (userId, { getState }) => {
		const userControlListState = getState().userControlList;
		const { userMasterList, userRoleOptions } = userControlListState;
		const roleId = userRoleOptions.find(
			(role) => role.label === USER_ROLE_NAME.ADMIN
		);
		const users = userMasterList.filter((user) => user.id === userId);
		await Promise.all(
			users.map(async (user) => {
				await UserControlListService.unAssignRole(user.assignedid);
			})
		);
		await UserControlListService.assignRole({
			userId,
			roleId: roleId.value,
		});
	}
);

export const assignNewUserRole = createAsyncThunk(
	'userControlList/assignNewUserRole',
	async ({ userId, roleId }, { getState }) => {
		const userControlListState = getState().userControlList;
		const { userMasterList } = userControlListState;
		const users = userMasterList.filter((user) => user.id === userId);
		if (users && users.length > 0) {
			await Promise.all(
				users.map(async (user) => {
					await UserControlListService.unAssignRole(user.assignedid);
				})
			);
		}
		await UserControlListService.assignRole({
			userId,
			roleId,
		});
	}
);

export const removeUser = createAsyncThunk(
	'userControlList/removeUser',
	async (userId, { getState }) => {
		const userControlListState = getState().userControlList;
		const { userMasterList } = userControlListState;
		const users = userMasterList.filter((user) => user.id === userId);
		await Promise.all(
			users.map(async (user) => {
				await UserControlListService.unAssignRole(user.assignedid);
			})
		);
	}
);

export const unAssignUserRole = createAsyncThunk(
	'userControlList/unAssignUserRole',
	async (userId, { getState }) => {
		const userControlListState = getState().userControlList;
		const { userMasterList, userRoleOptions } = userControlListState;
		const roleId = userRoleOptions.find(
			(role) => role.label === USER_ROLE_NAME.USER
		);
		const users = userMasterList.filter((user) => user.id === userId);
		await Promise.all(
			users.map(async (user) => {
				await UserControlListService.unAssignRole(user.assignedid);
			})
		);
		await UserControlListService.assignRole({
			userId,
			roleId: roleId.value,
		});
	}
);

export const fetchFilterOptions = createAsyncThunk(
	'userControlList/fetchFilterOptions',
	async () => {
		const response = await UserControlListService.getFilterOptions();
		return response;
	}
);

export const checkUserExist = createAsyncThunk(
	'userControlList/checkUserExist',
	async (userMail) => {
		const response = await UserControlListService.checkUserExist(userMail);
		return response;
	}
);

export const createNewUser = createAsyncThunk(
	'userControlList/createNewUser',
	async (newUser) => {
		const response = await UserControlListService.createNewUser(newUser);
		return response;
	}
);

export const userControlSlice = createSlice({
	name: 'userControlList',
	initialState,
	reducers: {
		setPage: (state, action) => {
			state.pagination.page = action.payload;
		},
		setIsFetchingData: (state, action) => {
			state.isFetchingData = action.payload;
		},
		setIsCheckingUser: (state, action) => {
			state.isCheckingUser = action.payload;
		},
		setFilterCriteria: (state, action) => {
			state.filterCriteria.userName = action.payload.userName;
			state.filterCriteria.userRoles = action.payload.userRoles;
		},
		setFullName: (state, action) => {
			state.filterCriteria.fullName = action.payload;
		},
		getUsersByPage: (state) => {
			const { page } = state.pagination;
			const startIndex =
				page === 1 ? 0 : (page - 1) * USERS_ITEM_PER_PAGE;
			const endIndex = page * USERS_ITEM_PER_PAGE;
			if (state.userSearchList.length > 0) {
				state.users = state.userSearchList.slice(startIndex, endIndex);
			} else {
				state.users = state.userMasterList.slice(startIndex, endIndex);
			}
		},
		searchUsers: (state) => {
			const { userName, userRoles } = state.filterCriteria;

			const filteredUserList = state.userMasterList.filter((user) => {
				const rolesValidate =
					userRoles.length === 0
						? true
						: userRoles.some((role) => role.value === user.roleid);
				return (
					user.displayname
						.toLowerCase()
						.includes(userName.toLowerCase()) && rolesValidate
				);
			});
			const page = 1;
			let totalpages = 0;
			if (filteredUserList && filteredUserList.length > 0) {
				totalpages = Math.ceil(
					filteredUserList.length / USERS_ITEM_PER_PAGE
				);
			}
			const startIndex =
				page === 1 ? 0 : (page - 1) * USERS_ITEM_PER_PAGE;
			const endIndex = page * USERS_ITEM_PER_PAGE;
			state.userSearchList = filteredUserList;
			state.pagination = { page: 1, totalPages: totalpages };
			state.users = state.userSearchList.slice(startIndex, endIndex);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserControlList.pending, (state) => {
			state.isFetchingData = true;
		});

		builder.addCase(fetchUserControlList.fulfilled, (state, action) => {
			state.isFetchingData = false;
			state.users = action.payload.users;
			state.userMasterList = action.payload.users;
			state.userSearchList = [];
			state.pagination.totalPages = action.payload.pagination.totalpages;
			state.totalPageMaster = action.payload.pagination.totalpages;
		});

		builder.addCase(fetchFilterOptions.fulfilled, (state, action) => {
			state.userRoleOptions = action.payload.map((x) => ({
				label: x.displayname,
				value: x.id,
			}));
		});
		builder.addCase(createNewUser.pending, (state) => {
			state.isFetchingData = true;
		});
		builder.addCase(createNewUser.fulfilled, (state) => {
			state.isFetchingData = false;
		});
		builder.addCase(assignUserRole.pending, (state) => {
			state.isFetchingData = true;
		});
		builder.addCase(assignUserRole.fulfilled, (state) => {
			state.isFetchingData = false;
		});
		builder.addCase(unAssignUserRole.pending, (state) => {
			state.isFetchingData = true;
		});
		builder.addCase(unAssignUserRole.fulfilled, (state) => {
			state.isFetchingData = false;
		});
		builder.addCase(removeUser.pending, (state) => {
			state.isFetchingData = true;
		});
		builder.addCase(removeUser.fulfilled, (state) => {
			state.isFetchingData = false;
		});
		builder.addCase(checkUserExist.pending, (state) => {
			state.isCheckingUser = true;
		});
		builder.addCase(checkUserExist.fulfilled, (state) => {
			state.isCheckingUser = false;
		});
		builder.addCase(assignNewUserRole.pending, (state) => {
			state.isFetchingData = true;
		});
		builder.addCase(assignNewUserRole.fulfilled, (state) => {
			state.isFetchingData = false;
		});
	},
});

export const {
	setPage,
	setFilterCriteria,
	setFullName,
	getUsersByPage,
	searchUsers,
	setIsFetchingData,
} = userControlSlice.actions;

export default userControlSlice.reducer;
