import React, { useEffect, useState } from 'react';
import MultiSelect from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../../../components/DatePicker';
import {
	fetchFilterOptions,
	fetchVideoAdminList,
	setFilterCriteria,
	setPage,
} from '../../../../store/slices/VideoAdminListSlice';
import { CASE_STATUS_VALUES } from '../../../../utils/constants';
import { convertDateStringByFormat } from '../../../../utils/time';
import './VideoAdministrationFilter.scss';

function VideoAdministrationFilter() {
	const dispatch = useDispatch();
	const {
		isFetchingData,
		filterCriteria,
		customerOptions,
		caseStatusOptions,
	} = useSelector((state) => state.videoAdminList);
	const [caseStatus, setCaseStatus] = useState(
		filterCriteria.caseStatus.map((x) => ({
			value: CASE_STATUS_VALUES[x].value,
			label: CASE_STATUS_VALUES[x].label,
		}))
	);
	const [customers, setCustomers] = useState([]);
	const [caseName, setCaseName] = useState('');
	const [uploadedDate, setUploadedDate] = useState('');
	const [startedDate, setStartedDate] = useState('');
	const [completedDate, setCompletedDate] = useState('');
	const [isRemove, setIsRemove] = useState(false);

	const handleApplyFilter = async () => {
		const criteria = {
			caseName,
			caseStatus: caseStatus.map((x) => x.value),
			customers: customers.map((x) => x.value),
			uploadedDate,
			startedDate,
			completedDate,
			isRemove,
		};
		dispatch(setPage(1));
		dispatch(setFilterCriteria(criteria));
		await dispatch(fetchVideoAdminList());
	};

	const handleOnKeyDown = async (e) => {
		if (e.key === 'Enter' && e.code === 'Enter') {
			await handleApplyFilter();
		}
	};

	useEffect(() => {
		dispatch(fetchFilterOptions());
	}, []);

	const overrideStrings = {
		selectSomeItems: 'Select the options',
		selectAll: 'All',
	};

	const filterOptions = (options, filter) => {
		if (!filter) {
			return options;
		}
		return options.filter((x) =>
			x.label.toLowerCase().includes(filter.toLowerCase())
		);
	};

	const convertDateString = (date) => {
		return convertDateStringByFormat(date, 'yyMMDD');
	};

	return (
		<div className="row d-flex align-items-center VideoAdministrationPage-filter">
			<div className="col-lg-12">
				<div className="row d-flex align-items-center">
					<div className="col-lg-3 col-xxl-2 my-1">
						{customerOptions.length !== 0 && (
							<MultiSelect
								options={customerOptions}
								value={customers}
								onChange={setCustomers}
								isLoading={customerOptions.length === 0}
								labelledBy="Select"
								filterOptions={filterOptions}
								overrideStrings={{
									...overrideStrings,
									selectSomeItems: 'Customers',
								}}
							/>
						)}
					</div>
					<div className="col-lg-3 col-xxl-auto my-1 position-relative">
						<input
							className="form-control form-control-sm input-control"
							type="text"
							name="case"
							id="case"
							autoComplete="off"
							placeholder="case"
							value={caseName}
							onChange={(e) => {
								setCaseName(e.target.value);
							}}
							onKeyDown={handleOnKeyDown}
						/>

						<i
							className={`close-icon bi bi-x ${
								caseName ? '' : 'd-none'
							}`}
							onClick={() => {
								setCaseName('');
							}}
						></i>
					</div>
					<div className="col-lg-3 col-xxl-2 my-1">
						{caseStatusOptions.length !== 0 && (
							<MultiSelect
								options={caseStatusOptions}
								value={caseStatus}
								onChange={setCaseStatus}
								isLoading={caseStatusOptions.length === 0}
								labelledBy="Select"
								filterOptions={filterOptions}
								overrideStrings={{
									selectSomeItems: 'Status',
									selectAll: 'All',
								}}
							/>
						)}
					</div>
					<div className="col-lg-3 col-xxl-auto my-1">
						<DatePicker
							name="Uploaded"
							id="uploadedDate"
							handleDateSelect={(date) =>
								setUploadedDate(convertDateString(date))
							}
						/>
					</div>
					<div className="col-lg-3 col-xxl-auto my-1">
						<DatePicker
							name="Started"
							id="startedDate"
							handleDateSelect={(date) =>
								setStartedDate(convertDateString(date))
							}
						/>
					</div>
					<div className="col-lg-3 col-xxl-auto my-1">
						<DatePicker
							name="Completed"
							id="completedDate"
							handleDateSelect={(date) =>
								setCompletedDate(convertDateString(date))
							}
						/>
					</div>
					<div className="col-lg-1 col-xxl-auto my-1">
						<label
							htmlFor="remove"
							className="d-flex align-items-center"
						>
							<input
								className="remove-checkbox"
								type="checkbox"
								name="remove"
								id="remove"
								checked={isRemove}
								onChange={() =>
									setIsRemove((prevState) => !prevState)
								}
							/>
							Remove
						</label>
					</div>
					<div className="col-lg-2 col-xxl-auto col-xs-2 my-1">
						<button
							type="button"
							className="btn btn-sm btn-primary w-100 btn-search"
							onClick={() => {
								handleApplyFilter();
							}}
						>
							{isFetchingData ? (
								<div
									className="spinner-border spinner-border-sm text-light"
									role="status"
								>
									<span className="visually-hidden">
										Loading...
									</span>
								</div>
							) : (
								'Apply'
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default VideoAdministrationFilter;
