import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function RouteGuard({ ...props }) {
	const { instance, accounts } = useMsal();
	const [isAuthorized, setIsAuthorized] = useState(false);
	const isAuthenticated = useIsAuthenticated();
	const history = useHistory();

	const onLoad = async () => {
		let intersection = [];
		if (isAuthenticated && accounts && accounts.length > 0) {
			intersection = props.roles.filter((role) =>
				accounts[0]?.idTokenClaims?.roles?.includes(role)
			);
		}
		if (intersection && intersection.length > 0) {
			setIsAuthorized(true);
		} else {
			history.push('/');
		}
	};

	useEffect(() => {
		onLoad();
	}, [instance]);

	return <>{isAuthorized && <div>{props.children}</div>}</>;
}

RouteGuard.propTypes = {
	roles: PropTypes.array,
	children: PropTypes.node.isRequired,
};

RouteGuard.defaultProps = {
	roles: [],
};

export default RouteGuard;
