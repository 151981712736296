import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CaseDetailService from '../../services/CaseDetailService';
import CaseSummaryService from '../../services/CaseSummaryService';

const initialState = {
	detections: null,
	similarImages: null,
	casename: null,
	customer: null,
	history: null,
	loading: false,
};

const getSimilarImages = (trackNegative, detections, imageBarService) => {
	const imageRequestBody = [];

	if (!trackNegative) return imageRequestBody;

	detections.forEach((detection) => {
		const frameId = detection.frame;
		const cam = detection.camera;

		trackNegative.forEach((trackNegativeItem) => {
			trackNegativeItem.forEach((frame) => {
				if (
					!frame.similars ||
					!frame.similars.length ||
					frame.mark !== 'Mark' ||
					frame.cam !== cam ||
					frame.frame !== frameId
				)
					return;

				frame.similars.forEach((similarImage) => {
					imageRequestBody.push({
						frameid: similarImage.frame,
						cameraid: similarImage.cam,
						isexplain: false,
						camlength: imageBarService.getMaxFrameCameraOne(),
					});
				});
			});
		});
	});

	return imageRequestBody;
};

const getTopTenSimilar = (similar) => {
	const copySimilar = [...similar];
	const sorted = copySimilar.sort((a, b) => b.prob - a.prob);
	const topTen = sorted.slice(0, 10);
	return topTen;
};

const getSimilarImagesByDetection = (
	trackNegative,
	allSimilarImages,
	detection,
	imageBarService
) => {
	const foundSimilarImages = getSimilarImages(
		trackNegative,
		[detection],
		imageBarService
	);

	if (!foundSimilarImages || !foundSimilarImages.length) return [];

	const result = [];

	foundSimilarImages.forEach((sframe) => {
		const similarFrameId = sframe.frameid;
		const cameraId = sframe.cameraid;

		allSimilarImages.forEach((image) => {
			if (image.frameid !== similarFrameId || image.cameraid !== cameraId)
				return;

			const prob = imageBarService.getScoreFromProb(
				similarFrameId,
				cameraId
			);

			result.push({
				id: `${similarFrameId}-${cameraId}`,
				imageUrl: image.img_base64,
				prob,
			});
		});
	});

	const topTenResult = getTopTenSimilar(result);
	return topTenResult;
};

const parseMarkerFromApi = (singleSummary) => {
	return singleSummary.positions.map((pos, markId) => {
		return {
			id: markId + 1,
			description: `${pos.type}. ${pos.description}`,
			x: pos.x,
			y: pos.y,
			width: pos.width,
			height: pos.height,
		};
	});
};

const parseCaseSummaryToDetections = ({ caseSummary, images, similar }) => {
	return caseSummary.map((singleSummary, index) => ({
		id: singleSummary.markid,
		frame: singleSummary.frame,
		camera: singleSummary.cam,
		imageUrl: images[index].img_base64,
		markers: parseMarkerFromApi(singleSummary),
		similar,
		time: singleSummary.timestamp,
	}));
};

export const fetchSimilarImages = async ({
	caseId,
	detections,
	history,
	imageBarService,
}) => {
	const { trackNegative } = history;
	const imageRequestBody = getSimilarImages(
		trackNegative,
		detections,
		imageBarService
	);

	const [similarImages] = await CaseSummaryService.getCaseImage(
		caseId,
		imageRequestBody
	);

	return similarImages;
};

export const fetchCaseSummary = async ({ caseId, imageBarService }) => {
	const [result] = await CaseSummaryService.getCaseSummary(caseId);
	const { content: caseSummary, casename, customer } = result;

	const imageRequestBody = caseSummary.map((singleSummary) => ({
		frameid: singleSummary.frame,
		cameraid: singleSummary.cam,
		isexplain: false,
		camlength: imageBarService.getMaxFrameCameraOne(),
	}));

	const [images] = await CaseSummaryService.getCaseImage(
		caseId,
		imageRequestBody
	);

	return {
		casename,
		customer,
		caseSummary,
		images,
	};
};

export const fetchCaseHistory = async (caseId) => {
	const result = await CaseDetailService.getCaseDetailHistory(caseId);
	return result.contentvalue;
};

/**
 * Ensure the order of dispatch
 * Get all case summary data.
 */
export const fetchAllPageData = createAsyncThunk(
	'caseSummary/fetchAllPageData',
	async ({ caseId, imageBarService }) => {
		if (!imageBarService) return false;

		try {
			const history = await fetchCaseHistory(caseId);
			const caseSummaryData = await fetchCaseSummary({
				caseId,
				imageBarService,
			});
			const detections = parseCaseSummaryToDetections(caseSummaryData);
			const similarImages = await fetchSimilarImages({
				caseId,
				detections,
				history,
				imageBarService,
			});

			return {
				history,
				caseSummaryData,
				detections,
				similarImages,
				imageBarService,
			};
		} catch (e) {
			console.error(e);
			return false;
		}
	}
);

export const caseSummarySlice = createSlice({
	name: 'caseSummary',
	initialState,
	reducers: {
		setDetections: (state, action) => {
			state.detections = action.payload;
		},
		clearDetection: (state, action) => {
			const index = action.payload;
			state.detections.splice(index, 1);
		},
		clearSimilar: (state, action) => {
			const { rowIndex, similarIndex } = action.payload;
			state.detections[rowIndex].similar.splice(similarIndex, 1);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAllPageData.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchAllPageData.fulfilled, (state, action) => {
			if (!action.payload) return;

			const {
				similarImages,
				detections,
				history,
				caseSummaryData,
				imageBarService,
			} = action.payload;
			const { trackNegative } = history;

			// Add similar image to detections
			detections.forEach((detection) => {
				detection.similar = getSimilarImagesByDetection(
					trackNegative,
					similarImages,
					detection,
					imageBarService
				);
			});

			detections.sort((prev, next) => {
				if (prev.time < next.time) {
					return -1;
				}
				if (prev.time > next.time) {
					return 1;
				}
				return 0;
			});

			state.similarImages = similarImages;
			state.detections = detections;
			state.history = history;
			state.casename = caseSummaryData.casename;
			state.customer = caseSummaryData.customer;
			state.loading = false;
		});
		builder.addCase(fetchAllPageData.rejected, (state) => {
			state.loading = false;
		});
	},
});

export const { setDetections, clearDetection, clearSimilar } =
	caseSummarySlice.actions;

export default caseSummarySlice.reducer;
