import React from 'react';
import PropTypes from 'prop-types';
import MarkForm from './MarkForm';
import { SLIDE_LENGTH } from '../../../utils/constants';

const RightPanel = (props) => {
	const {
		isAddView,
		marks,
		isLoading,
		image,
		imageIndex,
		imageBarService,
		onToggleIsMarking,
		onComplete,
		onCancelMark,
		onSaveMark,
		onRemoveMark,
	} = props;
	const { frameID, cam } = image || {};
	const score = imageBarService.getScore(frameID, cam);
	const timestamp = imageBarService.getTimestamp(frameID, cam);

	const handleClickMarkFinding = () => {
		onToggleIsMarking();
	};

	const handleCancelMark = () => {
		onCancelMark();
	};

	const handleSaveMark = (infor) => {
		onSaveMark(infor);
	};

	const getFrameText = () => {
		if (imageIndex - SLIDE_LENGTH === 0) return 'Central';
		if (imageIndex - SLIDE_LENGTH > 0)
			return `+${imageIndex - SLIDE_LENGTH}`;
		return imageIndex - SLIDE_LENGTH;
	};

	return (
		<div className="col p-4 border right-panel position-relative">
			<div className="text-center color-505050">
				<div className="fs-2 fw-bold">Frame Revision</div>
				<div className="fs-4 fw-bolder">Frame: {getFrameText()}</div>
			</div>
			<br />
			{isAddView ? (
				<MarkForm onSave={handleSaveMark} onCancel={handleCancelMark} />
			) : (
				<div>
					<div className="d-flex justify-content-evenly">
						<button
							type="button"
							className="btn btn-primary width-172"
							onClick={handleClickMarkFinding}
						>
							<i className="bi bi-vector-pen me-2"></i>
							Mark finding
						</button>
						<button
							type="button"
							className="btn btn-danger width-172"
							onClick={onComplete}
							disabled={isLoading || !marks.length}
						>
							<i className="bi bi-check-all me-2"></i>
							Complete
						</button>
					</div>
					<br />
					<div className="mark-box">
						<div className="mark-box__content">
							{marks.map((mark, index) => (
								<div
									key={mark.id}
									className="row mark-modal-content__mark-item"
								>
									Finding {index + 1}: {mark.type}
									<button
										type="button"
										className="close btn-lg"
										style={{ marginRight: -20 }}
										data-dismiss="alert"
										aria-label="Close"
										onClick={() => onRemoveMark(mark.id)}
									>
										<span
											style={{ fontSize: 28 }}
											aria-hidden="true"
										>
											&times;
										</span>
									</button>
								</div>
							))}
						</div>
					</div>
					<br />
				</div>
			)}
			<div className="position-absolute bottom-0 end-0 bg-white mark-modal-content__footer">
				<div>Score: {score}</div>
				<div>Time: {timestamp}</div>
				{/* <div>Camera: {camera}</div> */}
				<div>Camera: {cam}</div>
			</div>
		</div>
	);
};

RightPanel.propTypes = {
	image: PropTypes.any,
	isLoading: PropTypes.bool,
	imageBarService: PropTypes.any,
	imageIndex: PropTypes.number.isRequired,
	marks: PropTypes.array.isRequired,
	isAddView: PropTypes.bool.isRequired,
	onRemoveMark: PropTypes.func.isRequired,
	onSaveMark: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	onToggleIsMarking: PropTypes.func.isRequired,
	onCancelMark: PropTypes.func.isRequired,
};

RightPanel.defaultProps = {
	image: {},
	isLoading: false,
	imageBarService: {},
};

export default RightPanel;
