export const hexToRgb = (hex) => {
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				red: parseInt(result[1], 16),
				green: parseInt(result[2], 16),
				blue: parseInt(result[3], 16),
		  }
		: null;
};

export const rbgToHex = (r, b, g) => {
	return `#${[r, g, b]
		.map((x) =>
			x.toString(16).length === 1 ? `0${x.toString(16)}` : x.toString(16)
		)
		.join('')}`;
};

export const getMarkFrames = (trackNegative) => {
	const marks = [];
	trackNegative.forEach((tracks) => {
		const mark = tracks.find((item) => item.mark === 'Mark');
		if (mark) {
			marks.push(mark);
		}
	});
	return marks;
};

export const safelyStripUnit = (sizeInString, unit = 'px') => {
	if (typeof sizeInString === 'number') return sizeInString;
	return Number(sizeInString.replace(unit, ''));
};
