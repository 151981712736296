import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './NetworkModal.scss';

const customStyles = {
	content: {
		width: 312,
		height: 212,
		left: '50%',
		padding: 12,
		transform: 'translateX(-50%)',
	},
	overlay: {
		zIndex: 10000,
	},
};

const NetworkModal = (props) => {
	const { isShowModal, onHideModal } = props;

	return (
		<Modal isOpen={isShowModal} ariaHideApp={false} style={customStyles}>
			<div className="container warning-modal">
				<div>
					Something is temporarily wrong with your network connection.
					Please make sure you are connected to the internet and then
					reload your browser.
				</div>
				<div className="warning-modal__footer">
					<button
						type="button"
						className="btn warning-modal__close-btn"
						onClick={onHideModal}
					>
						Close
					</button>
					<button
						type="button"
						className="btn btn-warning warning-modal__reload-btn"
						onClick={() => window.location.reload()}
					>
						Reload
					</button>
				</div>
			</div>
		</Modal>
	);
};

NetworkModal.propTypes = {
	isShowModal: PropTypes.bool,
	onHideModal: PropTypes.func.isRequired,
};

NetworkModal.defaultProps = {
	isShowModal: false,
};

export default NetworkModal;
