import PropTypes from 'prop-types';
import { IMAGE_SIZE } from '../../../utils/constants';
import { safelyStripUnit } from '../../../utils/commons';
import './Mark.scss';

function Mark(props) {
	const { index, mark, removeUnit } = props;

	/**
	 * @type {{x: number | string, y: number | string, width: number | string, height: number | string}}>}
	 */
	const drawingMark = { ...mark };

	if (removeUnit) {
		drawingMark.x = safelyStripUnit(mark.x);
		drawingMark.y = safelyStripUnit(mark.y);
		drawingMark.width = safelyStripUnit(mark.width);
		drawingMark.height = safelyStripUnit(mark.height);
	}

	return (
		<div
			className="image-mark"
			style={{
				left: `${(drawingMark.x / IMAGE_SIZE) * 100}%`,
				top: `${(drawingMark.y / IMAGE_SIZE) * 100}%`,
				width: `${(drawingMark.width / IMAGE_SIZE) * 100}%`,
				height: `${(drawingMark.height / IMAGE_SIZE) * 100}%`,
			}}
		>
			{index > 0 ? <div className="image-mark__index">{index}</div> : ''}
		</div>
	);
}

Mark.propTypes = {
	mark: PropTypes.object.isRequired,
	removeUnit: PropTypes.bool,
	index: PropTypes.number,
};

// Specifies the default values for props:
Mark.defaultProps = {
	removeUnit: true,
	index: null,
};

export default Mark;
