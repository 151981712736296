import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import {
	assignUserRole,
	fetchUserControlList,
	getUsersByPage,
	removeUser,
	setFilterCriteria,
	setFullName,
	setPage,
	unAssignUserRole,
} from '../../../../store/slices/UserControlSlice';
import { USER_ROLE_NAME } from '../../../../utils/constants';
import UserControlItem from '../UserControlItem';
import './UserControlList.scss';

function UserControlList() {
	const dispatch = useDispatch();
	const { users, isFetchingData, userRoleOptions } = useSelector(
		(state) => state.userControlList
	);
	const { fullName } = useSelector(
		(state) => state.userControlList.filterCriteria
	);
	const { accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [displayUsers, setDisplayUsers] = useState([]);

	useEffect(() => {
		if (isAuthenticated && !fullName) {
			dispatch(setFullName(accounts[0].name));
		}
	}, []);

	const refreshUserList = async () => {
		await dispatch(fetchUserControlList());
		dispatch(setPage(1));
		dispatch(
			setFilterCriteria({
				userName: '',
				userRoles: [],
			})
		);
		dispatch(getUsersByPage());
	};

	useEffect(async () => {
		await refreshUserList();
	}, []);

	useEffect(async () => {
		if (users && users.length > 0) {
			setDisplayUsers(users);
			const getRoleNameById = (roleId) => {
				return userRoleOptions.find((role) => role.value === roleId);
			};
			let tempUsers = [...users];
			tempUsers = tempUsers.map((user) => {
				return {
					...user,
					roleName: getRoleNameById(user.roleid).label,
				};
			});
			tempUsers.sort((a, b) => {
				if (
					a.roleName.includes(USER_ROLE_NAME.GLOBAL_ADMIN) &&
					b.roleName.includes(USER_ROLE_NAME.ADMIN)
				) {
					return -1;
				}
				if (
					a.roleName.includes(USER_ROLE_NAME.ADMIN) &&
					b.roleName.includes(USER_ROLE_NAME.USER)
				) {
					return -1;
				}
				return 0;
			});
			setDisplayUsers(tempUsers);
		} else {
			setDisplayUsers(users);
		}
	}, [users]);

	const handleSetAdminRole = async (userId) => {
		try {
			await dispatch(assignUserRole(userId));
			await refreshUserList();
		} catch (error) {
			console.error('Assign user', error);
		}
	};

	const handleRemoveAdminRole = async (userId) => {
		try {
			await dispatch(unAssignUserRole(userId));
			await refreshUserList();
		} catch (error) {
			console.error('UnAssign user', error);
		}
	};

	const handleDeleteUser = async (userId) => {
		try {
			await dispatch(removeUser(userId));
			await refreshUserList();
		} catch (error) {
			console.error('Remove user', error);
		}
	};

	return isFetchingData ? (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100px',
				width: '100%',
			}}
		>
			<ReactLoading
				type="spin"
				color="#5f5f5f"
				width="48px"
				height="48px"
			/>
		</div>
	) : (
		<div className="table-responsive mt-4 table-fixed UserControlPage-list">
			<table className="table table-hover table-sm align-middle">
				<thead className="bg-white">
					<tr>
						<th scope="col">Full Name</th>
						<th scope="col">User Name</th>
						<th scope="col">Added Date</th>
						<th scope="col">Role</th>
						<th scope="col" colSpan="2">
							{' '}
						</th>
					</tr>
				</thead>
				<tbody>
					{displayUsers && displayUsers.length !== 0 ? (
						displayUsers.map((user) => (
							<UserControlItem
								key={user.id + Math.random()}
								row={user}
								handleSetAdminRole={handleSetAdminRole}
								handleRemoveAdminRole={handleRemoveAdminRole}
								handleDeleteUser={handleDeleteUser}
							/>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No data found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
export default UserControlList;
