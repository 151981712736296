import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './ConfirmModal.scss';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		overflow: 'visible',
	},
	overlay: {
		zIndex: 10000,
	},
};

const ConfirmModal = (props) => {
	const {
		isShowModal,
		handleHideModal,
		handleConfirm,
		buttonName,
		buttonColor,
		message,
		isShowCloseButton,
	} = props;

	return (
		<Modal isOpen={isShowModal} ariaHideApp={false} style={customStyles}>
			<div className="container">
				<div className="row d-flex justify-content-between flex-nowrap">
					<h4 className="col-auto">Confirm</h4>
					<div className="col-auto">
						<button
							type="button"
							className="btn btn-sm btn-light"
							onClick={handleHideModal}
						>
							<i className="status-icon d-block bi bi-x-lg"></i>
						</button>
					</div>
				</div>
				<div className="row mt-2 mb-4">
					<div className="col-auto">{message}</div>
				</div>
				<div className="row d-flex justify-content-start">
					<div className="col-auto">
						<button
							type="button"
							className={`btn btn-sm btn-${buttonColor}`}
							onClick={handleConfirm}
						>
							{buttonName}
						</button>
					</div>
					{isShowCloseButton && (
						<div className="col-auto">
							<button
								type="button"
								className="btn btn-sm btn-secondary"
								onClick={handleHideModal}
							>
								Close
							</button>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

ConfirmModal.propTypes = {
	isShowModal: PropTypes.bool,
	isShowCloseButton: PropTypes.bool,
	buttonColor: PropTypes.string,
	buttonName: PropTypes.string,
	message: PropTypes.string.isRequired,
	handleHideModal: PropTypes.func.isRequired,
	handleConfirm: PropTypes.func.isRequired,
};

ConfirmModal.defaultProps = {
	isShowModal: false,
	buttonColor: 'danger',
	buttonName: 'Confirm',
	isShowCloseButton: true,
};

export default ConfirmModal;
