import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import CaseListItem from '../CaseListItem';
import {
	fetchCaseList,
	setFullName,
	setPage,
	setFilterCriteria,
} from '../../../../store/slices/CaseListSlice';
import { fetchCaseHistory } from '../../../../store/slices/CaseDetailSlice';
import { CaseStatus } from '../../../../utils/constants';
import './CaseList.scss';

function CaseList() {
	const rowPerPage = 50;
	const cases = useSelector((state) => state.caseList.cases);
	const history = useSelector((state) => state.caseDetail.history);
	const page = useSelector((state) => state.caseList.pagination.page);
	const { fullName } = useSelector((state) => state.caseList.filterCriteria);
	const dispatch = useDispatch();
	const { accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	useEffect(() => {
		if (isAuthenticated && !fullName) {
			dispatch(setFullName(accounts[0].name));
		}
	}, []);

	useEffect(() => {
		dispatch(setPage(1));
		dispatch(
			setFilterCriteria({
				caseName: '',
				customers: [],
				modifiedBy: [],
				caseStatus: [CaseStatus.NOT_STARTED, CaseStatus.IN_PROGRESS],
			})
		);
		dispatch(fetchCaseList());
	}, []);

	const onRowClicked = async (row) => {
		await dispatch(fetchCaseHistory(row.caseid));
	};

	const onIconClicked = async (caseId) => {
		await dispatch(fetchCaseHistory(caseId));
	};

	return (
		<div className="table-responsive mt-4 table-fixed CaseListPage">
			<table className="table table-hover table-sm align-middle">
				<thead className="bg-white">
					<tr>
						<th scope="col">#</th>
						<th scope="col">Case</th>
						<th scope="col">Customer</th>
						<th scope="col">Modified By</th>
						<th scope="col">Capsule Type</th>
						<th scope="col">Uploaded</th>
						<th scope="col">Status</th>
						<th scope="col">Started</th>
						<th scope="col">Completed</th>
					</tr>
				</thead>
				<tbody>
					{cases && cases.length !== 0 ? (
						cases.map((caseItem, index) => (
							<CaseListItem
								key={caseItem.caseid}
								row={caseItem}
								noNumber={(page - 1) * rowPerPage + index + 1}
								history={history}
								onRowClicked={onRowClicked}
								onIconClicked={onIconClicked}
							/>
						))
					) : (
						<tr>
							<td colSpan="9" className="text-center">
								No data found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default CaseList;
