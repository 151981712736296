import PropTypes from 'prop-types';
import Mark from './Mark';

function ImageMarker(props) {
	const { markers, image, showIndex } = props;

	return (
		<div className="position-relative">
			<img src={image} alt="" className="img-fluid" />

			{markers.map((mark, index) => (
				<Mark
					index={showIndex ? index + 1 : 0}
					key={mark.id}
					mark={mark}
				/>
			))}
		</div>
	);
}

ImageMarker.propTypes = {
	image: PropTypes.string.isRequired,
	markers: PropTypes.array.isRequired,
	showIndex: PropTypes.bool,
};

ImageMarker.defaultProps = {
	showIndex: false,
};

export default ImageMarker;
