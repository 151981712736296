/* eslint-disable */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ElapsedTime from '../../../../components/ElapsedTime';
import {
	fetchListOfCaseImages,
	setImageBarService,
	fetchCaseHistory,
	fetchCaseDetail,
	resetImageBarState,
	// setFrameStart,
} from '../../../../store/slices/CaseDetailSlice';
import CaseDetailService from '../../../../services/CaseDetailService';
import {
	FPL,
	CAMERA,
	TIMELINE_ITEM_GAP,
	PREVENT_SCROLL_STATUS,
} from '../../../../utils/constants';
import usePrevious from '../../../../hooks/usePrevious';
import './ImageBar.scss';
import {
	convertSecondsToHMS,
	convertHMSStringToTime,
} from '../../../../utils/time';

function PreparingBar(props) {
	const parentRef = useRef();
	const isMouseLeave = useRef(true);
	const CAM_NAME = {
		ONE: 2,
		TWO: 5,
	};
	const { allowDragdrop, getStartFrame, getImages } = props;
	const {
		isFetchingData,
		caseDetail,
		history,
		imageBarService,
		csvData,
		inspected,
		detection,
		trackNegative,
		centralFrame,
		frameStart,
		// indexTimestamp,
	} = useSelector((state) => state.caseDetail);

	const prevTrackNegative = usePrevious(trackNegative);
	const prevCentralFrame = usePrevious(centralFrame);
	const refs = React.useRef([
		React.createRef(),
		React.createRef(),
		React.createRef(),
		React.createRef(),
		React.createRef(),
		React.createRef(),
	]);
	const timelineRef = React.createRef();
	const dispatch = useDispatch();
	const movingTimeoutRef = useRef(null);
	const reSizeTimeoutRef = useRef(null);
	const params = useParams();
	const [frameNumCam, setFrameNumCam] = useState({
		one: 0,
		two: 0,
		camChanged: '',
	});

	const drawImageBar = (
		data,
		context,
		haveIniProb,
		imgBarService,
		startFrameID
	) => {
		const dataLength = data.length;
		for (let index = 0; index < dataLength; index++) {
			let prob;
			if (haveIniProb) {
				prob = data[index].ini_prob;
			} else {
				prob = data[index].prob;
			}

			if (allowDragdrop && index < startFrameID) {
				context.fillStyle = imgBarService.getColorFromProb(prob, true);
			} else {
				context.fillStyle = imgBarService.getColorFromProb(prob);
			}

			// Always subtract frameStart because color bar should be start at 0
			context.fillRect(
				index - Math.floor(FPL / 2) - frameStart,
				2,
				FPL,
				10
			);
		}
	};

	const drawCanvas = (imgBarService) => {
		const camOne = imgBarService.getCamOne();
		const camTwo = imgBarService.getCamTwo();
		const haveIniProb = imgBarService.checkHaveIsIniProb();
		/**
		 * Two canvas width must be the same
		 * regardless of the frames count of each camera.
		 *
		 * Then, fill the bar normally.
		 */
		const maxWidth = Math.max(camOne.length, camTwo.length);

		const canvasOneElement = refs.current[0].current;
		canvasOneElement.height = 14;
		canvasOneElement.width = maxWidth;

		drawImageBar(
			camOne,
			canvasOneElement.getContext('2d'),
			haveIniProb,
			imgBarService,
			frameNumCam.one
		);

		const canvasTwoElement = refs.current[1].current;
		canvasTwoElement.height = 14;
		canvasTwoElement.width = maxWidth;

		drawImageBar(
			camTwo,
			canvasTwoElement.getContext('2d'),
			haveIniProb,
			imgBarService,
			frameNumCam.two
		);
	};

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const paintMarker = (canvasOne, canvasTwo, centralFrame) => {
		let size;
		let context;
		if (centralFrame.cam === CAMERA.TWO) {
			const camTwoLength = imageBarService.getCamTwo().length;
			size = Math.floor(0.003 * camTwoLength);
			context = canvasTwo.getContext('2d');
		} else {
			const camOneLength = imageBarService.getCamOne().length;
			size = Math.floor(0.003 * camOneLength);
			context = canvasOne.getContext('2d');
		}

		context.fillStyle = '#7578ff';
		context.fillRect(
			centralFrame.frameID - size * Math.floor(FPL) - frameStart,
			0,
			size * 2 * FPL,
			2
		);
		context.fillStyle = '#0002ff';
		context.fillRect(
			centralFrame.frameID - Math.floor(FPL / 2) - frameStart,
			0,
			FPL,
			2
		);
	};

	const paintSingleMarker = (canvasOne, canvasTwo, frames) => {
		let context;
		// TODO: Please check this bug "Cannot read property 'cam' of undefined"
		if (!frames[0]) {
			return;
		}
		const isCamTwo = frames[0].cam === CAMERA.TWO;
		for (let index = 0; index < frames.length; index++) {
			if (isCamTwo) {
				context = canvasTwo.getContext('2d');
			} else {
				context = canvasOne.getContext('2d');
			}

			context.fillStyle = '#000000';
			context.fillRect(
				frames[index].frame - frameStart,
				12,
				1,
				frames[index]?.mark === 'Mark' ? 2 : 1
			);
		}
	};

	const clearMarker = (canvasOne, canvasTwo, centralFrame) => {
		let size;
		let context;
		if (centralFrame.cam === CAMERA.TWO) {
			const camTwoLength = imageBarService.getCamTwo().length;
			size = Math.floor(0.003 * camTwoLength);
			context = canvasTwo.getContext('2d');
		} else {
			const camOneLength = imageBarService.getCamOne().length;
			size = Math.floor(0.003 * camOneLength);
			context = canvasOne.getContext('2d');
		}

		context.clearRect(
			centralFrame.frameID - size * Math.floor(FPL) - frameStart,
			0,
			size * 2 * FPL,
			2
		);
		context.clearRect(
			centralFrame.frameID - Math.floor(FPL / 2) - frameStart,
			0,
			FPL,
			2
		);
	};

	const clearSingleMarker = (canvasOne, canvasTwo, frames) => {
		let context;
		// TODO: Please check this bug "Cannot read property 'cam' of undefined"
		if (!frames[0]) {
			return;
		}
		const isCamTwo = frames[0].cam === CAMERA.TWO;
		for (let index = 0; index < frames.length; index++) {
			if (isCamTwo) {
				context = canvasTwo.getContext('2d');
			} else {
				context = canvasOne.getContext('2d');
			}

			context.clearRect(frames[index].frame - frameStart, 12, 1, 2);
		}
	};

	const removeElementsByClass = (className) => {
		const elements = document.getElementsByClassName(className);
		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	};

	const drawTimeLineBar = () => {
		const timeLine = timelineRef.current;
		removeElementsByClass('itemTimeLine');

		window.imageBarService = imageBarService;

		const totalTimeVideo = imageBarService.getTotalVideoTime();
		const timeVideoHMS = convertSecondsToHMS(totalTimeVideo);
		/**
		 * Due to returned timestamps are incorrect from server,
		 * we'll have to recalculate the timeline byself
		 */
		const totalTimeStartVideo = Math.min(
			convertHMSStringToTime(imageBarService.camOne[0].timestamp),
			convertHMSStringToTime(imageBarService.camTwo[0].timestamp)
		);
		const croppedVideoTime = totalTimeVideo - totalTimeStartVideo;

		const timelineWidth = timeLine.getBoundingClientRect().width;
		const totalItemTimeLine = Math.floor(timelineWidth / TIMELINE_ITEM_GAP);
		const videoStepLength = Math.floor(
			croppedVideoTime / (totalItemTimeLine - 1)
		);

		for (let index = 0; index < totalItemTimeLine; index++) {
			if (index === 0) {
				timeLine.innerHTML += `<span class="itemTimeLine">${convertSecondsToHMS(
					totalTimeStartVideo
				)}</span>`;
			} else {
				const timeLineItem =
					videoStepLength * index + totalTimeStartVideo;
				if (timeLineItem > totalTimeVideo) {
					timeLine.innerHTML += `<span class="itemTimeLine">${
						timeVideoHMS[0].toString().length > 1
							? `${timeVideoHMS[0]}`
							: `0${timeVideoHMS[0]}`
					}:${
						timeVideoHMS[1].toString().length > 1
							? `${timeVideoHMS[1]}`
							: `0${timeVideoHMS[1]}`
					}:00</span>`;
				} else {
					const minutes = Math.floor(timeLineItem / 60) % 60;
					const hours = Math.floor(timeLineItem / 3600);

					timeLine.innerHTML += `<span class="itemTimeLine">${
						hours.toString().length > 1 ? `${hours}` : `0${hours}`
					}:${
						minutes.toString().length > 1
							? `${minutes}`
							: `0${minutes}`
					}:00</span>`;
				}
			}
		}
	};
	const move = (dragDrop, dragDropLeft, dragDropTop) => {
		dragDrop.style.left = `${dragDropLeft}px`;
		dragDrop.style.top = `${dragDropTop}px`;
	};
	const deBounceMoving = (frameNumCamOne, frameNumCamTwo) => {
		if (movingTimeoutRef.current) {
			clearTimeout(movingTimeoutRef.current);
		}

		movingTimeoutRef.current = setTimeout(async () => {
			const camOnelength = imageBarService.getMaxFrameCameraOne();
			const camTwolength = imageBarService.getMaxFrameCameraTwo();
			const camOneRealFrameID =
				imageBarService.camOne[frameNumCamOne].frameID;
			const camTwoRealFrameID =
				imageBarService.camTwo[frameNumCamTwo].frameID;
			let body = [
				{
					frameid: camOneRealFrameID,
					cameraid: CAMERA.ONE,
					camOnelength,
					isexplain: false,
				},
				{
					frameid: camTwoRealFrameID,
					cameraid: CAMERA.TWO,
					camTwolength,
					isexplain: false,
				},
			];
			const imagesCam = await CaseDetailService.getImagesByFrameIds(
				+params.id,
				body
			);

			if (imagesCam.length !== 0) {
				getImages(imagesCam);
			}

			if (
				frameNumCam.camChanged !== '' &&
				frameNumCam.camChanged === 'two'
			) {
				getStartFrame(camTwoRealFrameID);
			} else {
				getStartFrame(camOneRealFrameID);
			}
		}, 300);
	};
	const deBounceResize = () => {
		if (reSizeTimeoutRef.current) {
			clearTimeout(reSizeTimeoutRef.current);
		}
		reSizeTimeoutRef.current = setTimeout(() => {
			if (allowDragdrop) {
				const dragDrop = refs.current[2].current;
				const canvasOneElement = refs.current[0].current;
				const canvasOneElementWidth = canvasOneElement.clientWidth;
				const percent = canvasOneElement.width / canvasOneElementWidth;
				const realX = Math.floor(frameNumCam.one / percent);
				move(dragDrop, realX, 0);
			}

			setWindowWidth(window.innerWidth);
		}, 300);
	};

	// cam 1 is 2
	// cam 2 is 5
	const startMoving = (evt, cam) => {
		evt = evt || window.event;
		const dragDrop = refs.current[cam].current;
		const canvasOneElement = refs.current[0].current;
		const canvasTwoElement = refs.current[1].current;
		const posX = evt.clientX;
		const posY = evt.clientY;
		const dragDropWidth = parseInt(dragDrop.clientWidth, 10);

		let posXMax = 0;

		switch (cam) {
			case 2:
				posXMax = parseInt(canvasOneElement.clientWidth, 10) + 38;
				break;
			case 5:
				posXMax = parseInt(canvasTwoElement.clientWidth, 10) + 38;
				break;
		}

		let dragDropTop = dragDrop.style.top;
		let dragDropLeft = dragDrop.style.left;
		dragDropTop = dragDropTop.replace('px', '');
		dragDropLeft = dragDropLeft.replace('px', '');
		const diffX = posX - dragDropLeft;
		const diffY = posY - dragDropTop;
		document.onmousemove = async function (e) {
			e = e || window.event;
			const positionX = e.clientX;
			const positionY = e.clientY;
			let realX = positionX - diffX;
			let realY = positionY - diffY;
			if (realX > posXMax) {
				realX = posXMax;
			}
			if (realX < 0) realX = 0;
			if (realX + dragDropWidth > posXMax)
				realX = posXMax - dragDropWidth - 2;
			realY = 0;
			move(dragDrop, realX, realY);
			const percent =
				canvasOneElement.width / canvasOneElement.clientWidth;
			getCamImages(percent, realX, cam);
		};
	};

	const getCamImages = (percent, realX, cam) => {
		switch (cam) {
			case 2:
				const frameNumCamOne = Math.min(
					imageBarService.getCamOne().length - 1,
					Math.floor(realX * percent)
				);
				setFrameNumCam({
					...frameNumCam,
					one: frameNumCamOne,
					camChanged: 'one',
				});
				break;
			case 5:
				const frameNumCamTwo = Math.min(
					imageBarService.getCamTwo().length - 1,
					Math.floor(realX * percent)
				);
				setFrameNumCam({
					...frameNumCam,
					two: frameNumCamTwo,
					camChanged: 'two',
				});
				break;
		}
	};

	const preventScroll = useCallback((e) => {
		e.preventDefault();
		return false;
	});

	const toggleScroll = (preventScrollStatus, mouseLeave = false) => {
		switch (preventScrollStatus) {
			case PREVENT_SCROLL_STATUS.ON:
				parentRef.current.addEventListener('wheel', preventScroll);
				break;
			case PREVENT_SCROLL_STATUS.OFF:
				parentRef.current.removeEventListener('wheel', preventScroll);
				break;

			default:
				break;
		}
		isMouseLeave.current = mouseLeave;
	};

	const handleCamScroll = (camName, event) => {
		const canvasOneElement = refs.current[0].current;
		const canvasTwoElement = refs.current[1].current;
		const dragDrop = refs.current[camName].current;
		let dragDropLeft = dragDrop.style.left;
		dragDropLeft = +dragDropLeft.replace('px', '');
		const dragDropWidth = parseInt(dragDrop.clientWidth, 10);
		let posXMax = 0;

		switch (camName) {
			case 2:
				posXMax = parseInt(canvasOneElement.clientWidth, 10) + 38;
				break;
			case 5:
				posXMax = parseInt(canvasTwoElement.clientWidth, 10) + 38;
				break;
		}
		if (dragDropLeft > posXMax) {
			dragDropLeft = posXMax;
		}
		const maxCamlength = posXMax - dragDropWidth - 2;

		if (dragDropLeft < 0) dragDropLeft = 0;
		if (dragDropLeft + dragDropWidth > posXMax) dragDropLeft = maxCamlength;

		let realX = 0;

		if (event.deltaY < 0) {
			realX =
				dragDropLeft < maxCamlength ? dragDropLeft + 1 : dragDropLeft;
		} else if (event.deltaY > 0) {
			realX = dragDropLeft > 0 ? dragDropLeft - 1 : dragDropLeft;
		} else {
			return;
		}

		move(dragDrop, realX, 0);
		const percent = canvasOneElement.width / canvasOneElement.clientWidth;
		getCamImages(percent, realX, camName);
		if (!isMouseLeave.current) {
			toggleScroll(PREVENT_SCROLL_STATUS.ON);
		}
	};

	useEffect(() => {
		parentRef.current = document.querySelector('.app-content');
		if (!isMouseLeave.current) {
			toggleScroll(PREVENT_SCROLL_STATUS.ON);
		}
		return () => {
			toggleScroll(PREVENT_SCROLL_STATUS.OFF, isMouseLeave.current);
		};
	});

	// cam 1 is 2
	// cam 2 is 5
	const stopMoving = (cam) => {
		const dragDrop = refs.current[cam].current;
		dragDrop.style.cursor = 'default';
		dragDrop.addEventListener(
			'mouseover',
			(event) => {
				event.target.style.cursor = 'pointer';
			},
			false
		);
		document.onmousemove = function () {};
	};
	window.addEventListener('resize', deBounceResize);

	const loadImageDeFault = async () => {
		if (!allowDragdrop) {
			return;
		}

		const camOneLength = imageBarService.getMaxFrameCameraOne();
		const camTwoLength = imageBarService.getMaxFrameCameraTwo();
		let body = [
			{
				frameid: imageBarService.camOne[0].frameID,
				cameraid: CAMERA.ONE,
				camOneLength,
				isexplain: false,
			},
			{
				frameid: imageBarService.camTwo[0].frameID,
				cameraid: CAMERA.TWO,
				camTwoLength,
				isexplain: false,
			},
		];

		const imagesCam = await CaseDetailService.getImagesByFrameIds(
			+params.id,
			body
		);
		if (imagesCam.length !== 0) {
			getImages(imagesCam);
		}
	};

	useEffect(() => {
		if (frameNumCam.one !== 0 || frameNumCam.two !== 0) {
			deBounceMoving(frameNumCam.one, frameNumCam.two);
		}
	}, [frameNumCam]);

	useEffect(() => {
		dispatch(fetchCaseHistory(+params.id));
		dispatch(fetchCaseDetail(+params.id));
		dispatch(fetchListOfCaseImages(+params.id));

		return () => {
			dispatch(resetImageBarState());
		};
	}, []);

	useEffect(() => {
		if (imageBarService) {
			drawCanvas(imageBarService);
			drawTimeLineBar(allowDragdrop);
			if (allowDragdrop) {
				loadImageDeFault();
			}
			for (let index = 0; index < trackNegative.length; index++) {
				paintSingleMarker(
					refs.current[0].current,
					refs.current[1].current,
					trackNegative[index]
				);
			}
		}
	}, [imageBarService, windowWidth]);

	useEffect(() => {
		if (allowDragdrop) {
			if (imageBarService) {
				drawCanvas(imageBarService);
				drawTimeLineBar(allowDragdrop);
			}
		}
	}, [frameNumCam]);

	useEffect(() => {
		if (csvData.length > 0) {
			dispatch(
				setImageBarService({
					csvData,
					frameStart,
					allowDragdrop,
				})
			);
		}
	}, [csvData, frameStart]);

	useEffect(() => {
		if (prevTrackNegative && trackNegative && imageBarService) {
			if (prevTrackNegative.length > trackNegative.length) {
				let lastClean;
				for (
					let index = 1;
					index <= prevTrackNegative.length;
					index++
				) {
					lastClean =
						prevTrackNegative[prevTrackNegative.length - index];
					if (lastClean.every((x) => x.mark === 'Clear')) {
						break;
					}
				}

				clearSingleMarker(
					refs.current[0].current,
					refs.current[1].current,
					// prevTrackNegative[prevTrackNegative.length - 1]
					lastClean
				);
			} else if (prevTrackNegative.length < trackNegative.length) {
				paintSingleMarker(
					refs.current[0].current,
					refs.current[1].current,
					trackNegative[trackNegative.length - 1]
				);
			}
		}
	}, [trackNegative]);

	useEffect(() => {
		if (prevCentralFrame === null && centralFrame) {
			paintMarker(
				refs.current[0].current,
				refs.current[1].current,
				// centralFrame.frameID
				centralFrame
			);
		}

		if (
			prevCentralFrame &&
			centralFrame &&
			prevCentralFrame.frameID !== centralFrame.frameID
		) {
			clearMarker(
				refs.current[0].current,
				refs.current[1].current,
				// prevCentralFrame.frameID
				prevCentralFrame
			);
			paintMarker(
				refs.current[0].current,
				refs.current[1].current,
				// centralFrame.frameID
				centralFrame
			);
		}
	}, [centralFrame]);

	return (
		<div className="container-fluid">
			<div className="card mb-1">
				<div className="card-body shadow">
					<div className="row">
						<div className="col-lg-12">
							<div
								className="image-bar"
								onMouseEnter={() =>
									toggleScroll(PREVENT_SCROLL_STATUS.ON)
								}
								onMouseLeave={() =>
									toggleScroll(
										PREVENT_SCROLL_STATUS.OFF,
										true
									)
								}
							>
								<div className="d-flex align-items-center justify-content-between position-relative">
									{caseDetail.casestatus === 3 ? (
										<div
											style={{
												marginLeft: allowDragdrop
													? 60
													: 40,
												fontSize: '0.825rem',
											}}
										>
											Elapsed Time :{' '}
											{caseDetail.elapsedtime}
										</div>
									) : (
										!isFetchingData && (
											<ElapsedTime
												allowDragdrop={allowDragdrop}
											/>
										)
									)}
									<div className="case-info position-absolute">
										{`Case: ${caseDetail?.casename} / Customer: ${history[0]?.customer}`}
									</div>
									<div className="case-inspected">
										{!allowDragdrop &&
											`${detection} Detection(s) ${inspected}/${imageBarService?.getTotalInspected()} Inspected (${(
												(inspected * 100) /
												imageBarService?.getTotalInspected()
											).toFixed(2)}%)`}
									</div>
								</div>
								<div className="position-relative">
									<div
										className="d-flex flex-nowrap position-relative"
										style={{ paddingTop: '4px' }}
									>
										<div
											className="flex-shrink-0"
											style={{ width: '40px' }}
										>
											<div className="camera-one">
												<i className="bi bi-camera-video" />
												<span>1</span>
											</div>
										</div>
										<div
											className="d-flex align-center flex-grow position-relative"
											style={{
												paddingLeft: allowDragdrop
													? '20px'
													: 0,
											}}
											onWheel={(e) =>
												handleCamScroll(CAM_NAME.ONE, e)
											}
										>
											<canvas
												style={{
													height: '40px',
													width: '100%',
												}}
												ref={refs.current[0]}
											/>
											{/* <div
												className="marker position-absolute d-none"
												ref={refs.current[3]}
											></div> */}
										</div>
										{allowDragdrop && (
											<div
												ref={refs.current[2]}
												className="position-absolute dragdrop"
												onMouseDown={(event) =>
													startMoving(event, 2)
												}
												onMouseUp={() => stopMoving(2)}
												onWheel={(e) =>
													handleCamScroll(
														CAM_NAME.ONE,
														e
													)
												}
											/>
										)}
									</div>
									<div
										className="d-flex flex-nowrap position-relative"
										style={{ paddingTop: '3px' }}
									>
										<div
											className="flex-shrink-0"
											style={{ width: '40px' }}
										>
											<div className="camera-two">
												<i className="bi bi-camera-video" />
												<span>2</span>
											</div>
										</div>
										<div
											className="d-flex align-center flex-grow position-relative"
											style={{
												paddingLeft: allowDragdrop
													? '20px'
													: 0,
											}}
											onWheel={(e) =>
												handleCamScroll(CAM_NAME.TWO, e)
											}
										>
											<canvas
												style={{
													height: '40px',
													width: '100%',
												}}
												ref={refs.current[1]}
											/>
											{/* <div
												className="marker position-absolute d-none"
												ref={refs.current[4]}
											></div> */}
										</div>
										{allowDragdrop && (
											<div
												ref={refs.current[5]}
												className="position-absolute dragdrop"
												onMouseDown={(event) =>
													startMoving(event, 5)
												}
												onMouseUp={() => stopMoving(5)}
												onWheel={(e) =>
													handleCamScroll(
														CAM_NAME.TWO,
														e
													)
												}
											/>
										)}
									</div>
									<div
										className="row "
										style={{ paddingTop: '2px' }}
									>
										<div style={{ width: '40px' }} />
										<div
											style={{
												width: 'calc(100% - 40px)',
											}}
										>
											<div
												style={{
													width: '100%',
													paddingLeft: allowDragdrop
														? '20px'
														: 0,
												}}
											>
												<div
													ref={timelineRef}
													id="timeLine"
													style={{
														width: '100%',
														border: '2px solid black',
														backgroundColor:
															'black',
														display: 'flex',
														justifyContent:
															'space-between',
														fontSize: '10px',
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

PreparingBar.propTypes = {
	allowDragdrop: PropTypes.bool,
	getStartFrame: PropTypes.func,
	getImages: PropTypes.func,
};

PreparingBar.defaultProps = {
	allowDragdrop: true,
	getStartFrame: null,
	getImages: null,
};

export default PreparingBar;
