import moment from 'moment';

/**
 * @param {string} hmsString
 * @returns {Array<number>}
 */
export const convertHMSStringToArray = (hmsString) => {
	return hmsString.split(':').map((x) => Number(x));
};

/**
 * @param {[number, number, number]} time
 * @returns {number}
 */
export const convertHMSArrayToNumber = (time) => {
	let timeToNumber = 0;
	for (let index = 0; index < time.length; index++) {
		if (index === 0) {
			timeToNumber += Number(time[index]) * 60 * 60;
		} else if (index === 1) {
			timeToNumber += Number(time[index]) * 60;
		} else {
			timeToNumber += Number(time[index]);
		}
	}
	return timeToNumber;
};

/**
 * @param {number} seconds
 * @returns {string} Time in HH:MM:SS
 */
export const convertSecondsToHMS = (seconds, showSeconds = false) => {
	const hms = new Date(seconds * 1000).toISOString().substr(11, 8);
	if (showSeconds) {
		return hms;
	}
	return `${hms.slice(0, hms.length - 2)}00`;
};

export const convertHMSStringToTime = (timeStr) => {
	const arr = convertHMSStringToArray(timeStr);
	const time = convertHMSArrayToNumber(arr);
	return time;
};

/**
 * @param {date} string
 * @returns {string} Time in DD-MMM-YYYY HH:mm
 */
export const convertTZ = (date) => {
	if (!date) {
		return '';
	}
	const format = 'DD-MMM-YYYY HH:mm';
	return moment.utc(date).local().format(format);
};

export const convertDateStringByFormat = (date, format) => {
	if (!date) {
		return '';
	}
	return moment.utc(date).local().format(format);
};
