import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function Pagination(props) {
	const { align, totalPages, page, onPageChange } = props;
	const [pages, setPages] = useState([]);
	const initArray = (total) => {
		const totalArray = [];
		for (let index = 1; index <= total; index++) {
			totalArray.push(index);
		}
		return totalArray;
	};
	const [totalPagesArray, setTotalPagesArray] = useState(
		initArray(totalPages)
	);

	const calculatePages = (newPage) => {
		if (totalPages < 10) {
			const slicedPages = totalPagesArray.slice(0, totalPages);
			setPages(slicedPages);
			return;
		}

		let start = newPage - 6 < 0 ? 0 : newPage - 6;
		let end = 0;
		if (start + 10 < totalPages) {
			end = start + 10;
		} else {
			end = totalPages;
			start = totalPages - 10;
		}
		const slicedPages = totalPagesArray.slice(start, end);
		setPages(slicedPages);
	};

	useEffect(() => {
		setTotalPagesArray(initArray(totalPages));
		calculatePages(page);
	}, [totalPages, page]);

	const onHandlePageChange = (newPage) => {
		if (onPageChange) {
			onPageChange(newPage);
			calculatePages(newPage);
		}
	};

	function getAlignClass(alignValue) {
		switch (alignValue) {
			case 'right':
				return 'justify-content-end';
			case 'left':
				return 'justify-content-start';
			case 'center':
				return 'justify-content-center';
			default:
				return 'justify-content-end';
		}
	}
	const alignClass = getAlignClass(align);
	return (
		<nav aria-label="Pagination" className="mt-3">
			<ul className={`pagination pagination-sm ${alignClass}`}>
				<li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
					<button
						type="button"
						className="page-link"
						onClick={() => onHandlePageChange(1)}
					>
						First
					</button>
				</li>
				<li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
					<button
						type="button"
						className="page-link"
						onClick={() => onHandlePageChange(page - 1)}
					>
						<i className="bi bi-chevron-left"></i>
					</button>
				</li>
				{pages.map((p) => {
					if (page === p) {
						return (
							<li
								key={p}
								className="page-item active"
								aria-current="page"
							>
								<span className="page-link">{p}</span>
							</li>
						);
					}

					return (
						<li key={p} className="page-item">
							<button
								type="button"
								className="page-link"
								onClick={() => onHandlePageChange(p)}
							>
								{p}
							</button>
						</li>
					);
				})}
				<li
					className={`page-item ${
						page === totalPages ? 'disabled' : ''
					}`}
				>
					<button
						type="button"
						className="page-link"
						onClick={() => onHandlePageChange(page + 1)}
					>
						<i className="bi bi-chevron-right"></i>
					</button>
				</li>
				<li
					className={`page-item ${
						page === totalPages ? 'disabled' : ''
					}`}
				>
					<button
						type="button"
						className="page-link"
						onClick={() => onHandlePageChange(totalPages)}
					>
						Last
					</button>
				</li>
			</ul>
		</nav>
	);
}
Pagination.propTypes = {
	totalPages: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	align: PropTypes.string,
	onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
	align: 'right',
	onPageChange: null,
};
export default Pagination;
