import axios from 'axios';
import qs from 'qs';
import { loginRequest } from './authConfig';
import getConfig from './configProvider';

const http = axios.create({
	baseURL: getConfig('REACT_APP_API_ENDPOINT'),
	paramsSerializer: (params) =>
		qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const registerInterceptor = (msalInstance, accounts, callback) => {
	const interceptorRequest = http.interceptors.request.use(
		async (config) => {
			const newConfig = { ...config };

			if (accounts[0]) {
				try {
					const tokenResponse = await msalInstance.acquireTokenSilent(
						{
							account: accounts[0],
							scopes: loginRequest.scopes,
						}
					);
					if (tokenResponse) {
						const { idToken } = tokenResponse;
						newConfig.headers = {
							...newConfig.headers,
							...{
								Authorization: `Bearer ${idToken}`,
							},
						};
					}
				} catch (error) {
					return msalInstance.acquireTokenRedirect(
						msalInstance.tokenRequest
					);
				}
			}

			return newConfig;
		},
		(error) => Promise.reject(error)
	);

	const interceptorResponse = http.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			callback(error);
			return Promise.reject(error);
		}
	);

	return {
		interceptorRequest,
		interceptorResponse,
	};
};

export const removeInterceptor = (interceptorInstance) => {
	http.interceptors.request.eject(interceptorInstance.interceptorRequest);
	http.interceptors.response.eject(interceptorInstance.interceptorResponse);
};

/**
 * @param {Promise<import('axios').AxiosResponse>} axiosRequest
 * @returns {Promise<[unknown | null, import('axios').AxiosError | null]>}
 */
export const standardizeResponse = async (axiosRequest) => {
	try {
		const { data } = await axiosRequest;
		return [data, null];
	} catch (error) {
		return [null, error];
	}
};

export default http;
