import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import FooterButton from './components/FooterButton';
import ImageVideo from './components/ImageVideo';
import {
	setAllowDragdrop,
	setFrameStart,
} from '../../store/slices/CaseDetailSlice';

function CasePreparingPage(props) {
	const { startFrame, images } = props;
	const { allowDragdrop } = useSelector((state) => state.caseDetail);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!allowDragdrop) {
			dispatch(setAllowDragdrop(true));
		}
		dispatch(setFrameStart(0));
	}, []);

	return (
		<div className="container-fluid mt-3">
			<Helmet>
				<title>Case Preparing | CHI AiSPEED</title>
			</Helmet>
			<ImageVideo images={images} />
			<FooterButton frameID={startFrame} />
		</div>
	);
}

CasePreparingPage.propTypes = {
	startFrame: PropTypes.number,
	images: PropTypes.array,
};

CasePreparingPage.defaultProps = {
	startFrame: 0,
	images: [],
};

export default CasePreparingPage;
