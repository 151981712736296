import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { findLastIndex } from 'lodash';
import CaseDetailService from '../../services/CaseDetailService';
import ImageBarService from '../../services/ImageBarService';

const initialState = {
	allowDragdrop: true,
	caseDetail: {},
	history: [],
	elapsedTime: '',
	csvData: [],
	frameStart: 0,
	isFetchingData: false,
	imageBarService: null,
	showHeatmap: false,
	showFinding: false,
	showSimilar: false,
	currentImages: [],
	centralFrame: null,
	inspected: 0,
	detection: 0,
	indexTimestamp: 0,
	trackNegative: [],
	markDetections: [],
	undoTime: 0,
	seen: [],
	similars: {
		index: 0,
		frameIds: [],
		images: [],
	},
	ignoredIndexTimestamp: [],
};

export const updateFrameStartOfCaseDetail = createAsyncThunk(
	'caseDetail/updateFrameStartOfCaseDetail',
	async (data) => {
		const response = await CaseDetailService.updateFrameStartOfCaseDetail(
			data
		);
		return response;
	}
);

export const fetchCaseDetail = createAsyncThunk(
	'caseDetail/fetchCaseDetail',
	async (caseId) => {
		const response = await CaseDetailService.getCaseDetail(caseId);
		return response;
	}
);

export const fetchCaseHistory = createAsyncThunk(
	'caseDetail/fetchCaseHistory',
	async (caseId) => {
		const response = await CaseDetailService.getCaseHistory(caseId);
		return response;
	}
);

export const fetchListOfCaseImages = createAsyncThunk(
	'caseDetail/fetchListOfCaseImages',
	async (caseId) => {
		const response = await CaseDetailService.getListOfCaseImages(caseId);
		return response;
	}
);

export const fetchCaseDetailHistory = createAsyncThunk(
	'caseDetail/fetchCaseDetailHistory',
	async (caseId) => {
		const response = await CaseDetailService.getCaseDetailHistory(caseId);
		return response;
	}
);

export const updateCaseDetailHistory = createAsyncThunk(
	'caseDetail/updateCaseDetailHistory',
	async (caseId, { getState }) => {
		const {
			seen,
			trackNegative,
			indexTimestamp,
			inspected,
			detection,
			ignoredIndexTimestamp,
		} = getState().caseDetail;

		const body = {
			seen,
			trackNegative,
			indexTimestamp,
			inspected,
			detection,
			ignoredIndexTimestamp,
		};
		const response = await CaseDetailService.updateCaseDetailHistory(
			caseId,
			body
		);
		return response;
	}
);

export const caseDetailSlice = createSlice({
	name: 'caseDetail',
	initialState,
	reducers: {
		setAllowDragdrop: (state, action) => {
			state.allowDragdrop = action.payload;
		},
		setFrameStart: (state, action) => {
			state.frameStart = action.payload;
		},
		setElapsedTime: (state, action) => {
			state.elapsedTime = action.payload;
		},
		updateCaseDetail: (state, action) => {
			state.caseDetail = {
				...state.caseDetail,
				casestatus: action.payload.caseStatus,
				startframeid: action.payload.startFrameID,
				elapsedtime: action.payload.elapsedTime,
			};

			if (action.payload.modifiedBy) {
				state.caseDetail.modifiedby = action.payload.modifiedBy;
			}
		},
		setImageBarService: (state, action) => {
			state.imageBarService = new ImageBarService(
				action.payload.csvData,
				action.payload.frameStart,
				undefined,
				action.payload.allowDragdrop
			);
		},
		toggleHeatmap: (state) => {
			state.showHeatmap = !state.showHeatmap;
		},
		toggleFinding: (state) => {
			state.showFinding = !state.showFinding;
		},
		toggleSimilar: (state) => {
			state.showSimilar = !state.showSimilar;
		},
		setCurrentImages: (state, action) => {
			state.currentImages = action.payload;
		},
		updateCurrentImages: (state, action) => {
			if (state.currentImages[0].frameID > action.payload.frameID) {
				state.currentImages.pop();
				state.currentImages.unshift(action.payload);
			} else {
				state.currentImages.shift();
				state.currentImages.push(action.payload);
			}
		},
		setCentralFrame: (state, action) => {
			state.centralFrame = action.payload;
		},
		setIndexTimestamp: (state, action) => {
			state.indexTimestamp = action.payload;
		},
		setInspected: (state, action) => {
			state.inspected = action.payload;
		},
		setDetection: (state, action) => {
			state.detection = action.payload;
		},
		setMarkDetections: (state, action) => {
			const markDetections = action.payload;
			state.markDetections = markDetections;
			state.detection = markDetections.length;
		},
		updateSimilarForMarkDetections: (state, action) => {
			const index = findLastIndex(state.markDetections);
			if (index !== -1) {
				state.markDetections[index].similars = action.payload;
			}
		},
		addTrackNegative: (state, action) => {
			state.trackNegative.push(action.payload);
		},
		removeTrackNegative: (state, action) => {
			if (action.payload >= 0) {
				state.trackNegative.splice(action.payload, 1);
			}
		},
		setUndoTime: (state, action) => {
			state.undoTime = state.undoTime + action.payload;
		},
		addSeen: (state, action) => {
			state.seen.push(...action.payload);
		},
		removeSeen: (state, action) => {
			const { length } = action.payload;
			for (let i = 0; i < length; i++) {
				const index = state.seen.findIndex(
					(x) =>
						x.cam === action.payload[i].cam &&
						x.frame === action.payload[i].frame
				);
				if (index !== -1) {
					state.seen.splice(index, 1);
				}
			}
		},
		setSimilarIndex: (state, action) => {
			state.similars.index = action.payload;
		},
		setSimilarImages: (state, action) => {
			state.similars.images = action.payload;
		},
		setSimilarFrameIds: (state, action) => {
			state.similars.frameIds = action.payload;
		},
		updateSimilarImages: (state, action) => {
			if (state.similars.images[0].frameID > action.payload.frameID) {
				state.similars.images.pop();
				state.similars.images.unshift(action.payload);
			} else {
				state.similars.images.shift();
				state.similars.images.push(action.payload);
			}
		},
		updateValidatedSimilarImages: (state, action) => {
			const index = findLastIndex(state.trackNegative, (x) =>
				x.some((y) => y.mark === 'Mark')
			);
			if (index !== -1) {
				const markIndex = state.trackNegative[index].findIndex(
					(x) => x.mark === 'Mark'
				);
				if (markIndex !== -1) {
					state.trackNegative[index][markIndex].similars =
						action.payload;
				}
			}
		},
		resetImageBarState: (state) => {
			state.csvData = [];
			state.frameStart = 0;
			state.showFinding = false;
			state.showHeatmap = false;
			state.showSimilar = false;
			state.currentImages = [];
			state.centralFrame = null;
			state.inspected = 0;
			state.detection = 0;
			state.indexTimestamp = 0;
			state.trackNegative = [];
			state.undoTime = 0;
			state.seen = [];
			state.elapsedTime = '';
			state.imageBarService = null;
			state.similars = {
				frameIds: [],
				index: 0,
				images: [],
			};
			state.ignoredIndexTimestamp = [];
		},
		setIgnoredIndexTimestamp: (state, action) => {
			state.ignoredIndexTimestamp = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCaseDetail.fulfilled, (state, action) => {
			state.caseDetail = action.payload;
		});

		builder.addCase(fetchCaseHistory.fulfilled, (state, action) => {
			state.history = action.payload;
		});

		builder.addCase(fetchListOfCaseImages.pending, (state) => {
			state.isFetchingData = true;
		});

		builder.addCase(fetchListOfCaseImages.rejected, (state) => {
			state.isFetchingData = false;
		});

		builder.addCase(fetchListOfCaseImages.fulfilled, (state, action) => {
			state.isFetchingData = false;
			state.csvData = action.payload.caseimages;
			state.frameStart = action.payload.startframeid;
		});

		builder.addCase(fetchCaseDetailHistory.fulfilled, (state, action) => {
			state.seen = action.payload.contentvalue.seen || [];
			state.trackNegative =
				action.payload.contentvalue.trackNegative || [];
			state.indexTimestamp =
				action.payload.contentvalue.indexTimestamp || 0;
			state.inspected = action.payload.contentvalue.inspected || 0;
			state.detection = action.payload.contentvalue.detection || 0;
			state.ignoredIndexTimestamp =
				action.payload.contentvalue.ignoredIndexTimestamp || [];
		});
	},
});

export const {
	setAllowDragdrop,
	setImageBarService,
	setCurrentImages,
	toggleHeatmap,
	toggleFinding,
	setFrameStart,
	setElapsedTime,
	setCentralFrame,
	updateCurrentImages,
	updateCaseDetail,
	setIndexTimestamp,
	setInspected,
	setDetection,
	setMarkDetections,
	addTrackNegative,
	removeTrackNegative,
	setUndoTime,
	addSeen,
	removeSeen,
	resetImageBarState,
	toggleSimilar,
	setSimilarImages,
	updateSimilarImages,
	setSimilarFrameIds,
	setSimilarIndex,
	updateValidatedSimilarImages,
	updateSimilarForMarkDetections,
	setSimilarForMarkDetections,
	setIgnoredIndexTimestamp,
} = caseDetailSlice.actions;

export default caseDetailSlice.reducer;
