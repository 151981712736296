import React from 'react';
import PropTypes from 'prop-types';
import { convertTZ } from '../../../../utils/time';
import './CaseOverviewDetail.scss';

function CaseOverviewDetail(props) {
	const { history, caseDetail } = props;

	return (
		<div className="card CaseOverviewPage-detail">
			<div className="card-body shadow">
				<span className="card-title CaseOverviewPage-detail__title">
					Details
				</span>
				<div className="row">
					<div className="col-lg-8">
						<ul className="detail-list">
							<li className="detail-list__item">
								Order Details: {history[0]?.orderdetails}
							</li>
							<li className="detail-list__item">
								Medical History: {history[0]?.medicalhistory}
							</li>
							<li className="detail-list__item">
								Indication: {history[0]?.indication}
							</li>
						</ul>
					</div>
					<div className="col-lg-4">
						<ul className="detail-list">
							<li className="detail-list__item">
								Uploaded Date:{' '}
								{convertTZ(caseDetail?.uploadeddate)}
							</li>
							<li className="detail-list__item">
								Patient ID: {history[0]?.patientid}
							</li>
						</ul>
						<div></div>
					</div>
					<div className="col-lg-7"></div>
				</div>
			</div>
		</div>
	);
}

CaseOverviewDetail.propTypes = {
	history: PropTypes.array,
	caseDetail: PropTypes.object,
};

CaseOverviewDetail.defaultProps = {
	history: [],
	caseDetail: {},
};
export default CaseOverviewDetail;
