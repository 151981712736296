import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CaseListFilter from './components/CaseListFilter';
import CaseList from './components/CaseList';
import Pagination from '../../components/Pagination';
import { setPage, fetchCaseList } from '../../store/slices/CaseListSlice';
import './CaseListPage.scss';

function CaseListPage() {
	const { totalPages, page } = useSelector(
		(state) => state.caseList.pagination
	);
	const dispatch = useDispatch();

	const onPageChange = async (newPage) => {
		dispatch(setPage(newPage));
		await dispatch(fetchCaseList());
	};

	return (
		<div className="container-fluid">
			<div className="card mt-3">
				<div className="card-body shadow">
					<div className="row d-flex align-items-center">
						<div className="col-lg-2">
							<span className="card-title text-uppercase CaseListPage__title">
								Cases
							</span>
						</div>
						<div className="col-lg-10">
							<CaseListFilter />
						</div>
					</div>
					<CaseList />
					{totalPages > 1 && (
						<Pagination
							totalPages={totalPages}
							page={page}
							onPageChange={onPageChange}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default CaseListPage;
