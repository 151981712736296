import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Footer.scss';
import footerImage1 from '../../assets/images/footer/1.png';
import footerImage2 from '../../assets/images/footer/2.png';
import footerImage3 from '../../assets/images/footer/3.png';
import footerImage4 from '../../assets/images/footer/4.png';
import { fetchVersion } from '../../store/slices/FooterSlice';

function Footer() {
	const dispatch = useDispatch();
	const footer = useSelector((state) => state.footer);

	useEffect(() => {
		dispatch(fetchVersion());
	}, []);

	return (
		<div className="row Footer justify-content-between mx-0 w-100 py-3">
			<div className="info d-flex">
				<span>CorporateHealth International UK Ltd</span>
				<span>SC553316</span>
			</div>
			<div className="info info--left d-flex">
				<span>https://portal.aispeed.corphealth.co/</span>
				<span>Version: {footer.version}</span>
				<span>Version Date: {footer.date}</span>
			</div>
			<div className="d-flex Footer__content">
				<div className="d-flex flex-column">
					<div className="Footer__image">
						<img src={footerImage1} alt="Legal Manufacturer" />
					</div>
					<span>Legal Manufacturer:</span>
					<span>CorporateHealth International UK Ltd</span>
					<span>8 Inverness Campus, Inverness</span>
					<span>Scotland</span>
					<span>IV2 5NA</span>
					<a href="https://corphealth.co/">
						<span>https://corphealth.co/</span>
					</a>
				</div>
				<div className="d-flex flex-column ">
					<div className="Footer__image">
						<img
							src={footerImage2}
							alt="CorporateHealth International ApS"
						/>
					</div>
					<span>CorporateHealth International ApS</span>
					<span>Forskerparken 10A, Odense</span>
					<span>5230</span>
					<span>Denmark</span>
				</div>
				<div className="d-flex flex-column">
					<div className="Footer__image">
						<img
							src={footerImage3}
							alt="CorporateHealth International ApS"
						/>
						<img
							src={footerImage4}
							alt="CorporateHealth International ApS"
						/>
					</div>
					<span>
						AiSPEED™ is a Medical Device according to Regulation
						(EU) 2017/745 and MHRA (UK)
					</span>
					<span>UDI-DI:</span>
					<span>111905527483</span>
					<span>PZN:</span>
					<span>19055274</span>
				</div>
			</div>
		</div>
	);
}

export default Footer;
