import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FooterService from '../../services/FooterService';

const initialState = {
	version: '',
	date: '',
	loading: false,
};

export const fetchVersion = createAsyncThunk(
	'footer/fetchVersion',
	async () => {
		const response = await FooterService.getVersion();
		return response;
	}
);

export const footerSlice = createSlice({
	name: 'footer',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchVersion.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchVersion.fulfilled, (state, action) => {
			const { buildversion, builddate } = action.payload;
			state.version = buildversion;
			state.date = builddate;
			state.loading = false;
		});
		builder.addCase(fetchVersion.rejected, (state) => {
			state.loading = false;
		});
	},
});

export default footerSlice.reducer;
