import http, { standardizeResponse } from '../utils/http';

class CaseSummaryService {
	async getCaseSummary(caseId) {
		return standardizeResponse(
			http.get(`/api/caseoverview/summary`, {
				params: { caseid: btoa(caseId) },
			})
		);
	}

	async getCaseImage(caseId, frames) {
		return standardizeResponse(
			http.post(`/api/caseoverview/images`, frames, {
				params: { caseid: btoa(caseId) },
			})
		);
	}

	async endCase({ caseId, modifiedby }) {
		const requestBody = {
			casestatus: 3,
			modifiedby,
		};

		return standardizeResponse(
			http.put(`/api/caseoverview/summary/complete`, requestBody, {
				params: { caseid: btoa(caseId) },
			})
		);
	}
}

export default new CaseSummaryService();
