export const CaseStatus = {
	NOT_STARTED: 0,
	IN_PROGRESS: 2,
	COMPLETED: 3,
};

export const CASE_STATUS_VALUES = {
	[CaseStatus.NOT_STARTED]: {
		label: 'Not Started',
		value: CaseStatus.NOT_STARTED,
	},
	[CaseStatus.IN_PROGRESS]: {
		label: 'In Progress',
		value: CaseStatus.IN_PROGRESS,
	},
	[CaseStatus.COMPLETED]: {
		label: 'Completed',
		value: CaseStatus.COMPLETED,
	},
};

export const USER_ROLE_NAME = {
	USER: 'User',
	ADMIN: 'Admin',
	GLOBAL_ADMIN: 'Global Admin',
};

export const USERS_ITEM_PER_PAGE = 50;

export const STATUS_CLASS = {
	[CaseStatus.NOT_STARTED]: 'bg-warning text-dark status-text',
	[CaseStatus.IN_PROGRESS]: 'bg-primary text-light status-text',
	[CaseStatus.COMPLETED]: 'bg-success text-light status-text',
};

export const FPL = 7;
export const SLIDE_LENGTH = 50;
export const LIMIT_SLIDE = SLIDE_LENGTH - Math.floor(FPL / 2);
export const CAMERA = {
	ONE: 1,
	TWO: 2,
};
export const TIMELINE_ITEM_GAP = 70; // the width of the timeline item: ex: 00:00:00
export const IMAGE_SIZE = 512;
export const PREVENT_SCROLL_STATUS = {
	ON: 'on',
	OFF: 'off',
};
