import http from '../utils/http';

export default class VideoAdminListService {
	static async getVideoAdminList(queryParams) {
		try {
			const { data } = await http.get(`/api/admin/cases`, {
				params: {
					startdate: queryParams?.startedDate || '',
					isdeleted: queryParams?.isRemove || false,
					completeddate: queryParams?.completedDate || '',
					uploadeddate: queryParams?.uploadedDate || '',
					casestatus: queryParams?.caseStatus || [],
					customer: queryParams?.customers || [],
					casename: queryParams?.caseName || '',
					fullname: queryParams?.fullName || '',
					page: queryParams?.page || 1,
				},
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async getFilterOptions() {
		try {
			const { data } = await http.get(`/api/filters/cases`);
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async softRemoveCase(caseId) {
		try {
			const { response } = await http.put(
				`/api/case/softremoval`,
				{
					isremoval: true,
				},
				{ params: { caseid: btoa(caseId) } }
			);
			return response;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async restoreCase(caseId) {
		try {
			const { response } = await http.put(
				`/api/case/softremoval`,
				{
					isremoval: false,
				},
				{ params: { caseid: btoa(caseId) } }
			);
			return response;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async hardRemoveCase(caseId) {
		try {
			const { response } = await http.delete(`/api/case/hardremoval`, {
				params: { caseid: btoa(caseId) },
			});
			return response;
		} catch (error) {
			console.error(error);
			return error;
		}
	}
}
