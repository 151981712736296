import { useMsal } from '@azure/msal-react';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmModal from '../../../../components/ConfirmModal';
import { USER_ROLE_NAME } from '../../../../utils/constants';
import './UserControlItem.scss';

function UserControlItem(props) {
	const { accounts } = useMsal();
	const deleteUserConfirmMessage = 'Are you sure to delete this user?';
	const [isShowModal, setIsShowModal] = useState(false);
	const { userRoleOptions } = useSelector((state) => state.userControlList);
	const { row, handleSetAdminRole, handleRemoveAdminRole, handleDeleteUser } =
		props;

	const handleConfirm = () => {
		setIsShowModal(false);
		handleDeleteUser(row.id);
	};

	const getRoleLabelById = (roleId) => {
		const result = userRoleOptions.find((role) => role.value === roleId);
		return result ? result.label : '';
	};
	return (
		<>
			<ConfirmModal
				isShowModal={isShowModal}
				handleHideModal={() => setIsShowModal(false)}
				handleConfirm={handleConfirm}
				message={deleteUserConfirmMessage}
				buttonColor="danger"
				buttonName="Delete"
			/>
			<tr className="UserControlPage-item">
				<td>
					<div className="d-block text-truncate max-width-200px">
						{row.displayname}
					</div>
				</td>
				<td>
					<div className="d-block text-truncate max-width-200px">
						{row.username}
					</div>
				</td>
				<td>
					<div className="d-block text-truncate max-width-200px">
						{row.createddatetime}
					</div>
				</td>
				<td>
					<div className="d-block max-width-120px">
						{getRoleLabelById(row.roleid)}
					</div>
				</td>
				<td className="max-width-50px">
					<div className="d-flex justify-content-center align-items-center">
						{getRoleLabelById(row.roleid) !==
						USER_ROLE_NAME.GLOBAL_ADMIN ? (
							<i
								className="status-icon d-block bi bi-trash btn btn-outline-light btn-sm"
								onClick={() => setIsShowModal(true)}
							></i>
						) : (
							<div> </div>
						)}
					</div>
				</td>
				<td className="max-width-50px">
					<div className="d-flex justify-content-center align-items-center">
						{getRoleLabelById(row.roleid) === USER_ROLE_NAME.USER &&
							accounts[0]?.idTokenClaims?.roles.includes(
								'GlobalAdmin'
							) && (
								<i
									className="status-icon d-block bi bi-person-plus-fill btn btn-outline-light btn-sm"
									onClick={() => handleSetAdminRole(row.id)}
								></i>
							)}
						{getRoleLabelById(row.roleid) ===
							USER_ROLE_NAME.ADMIN &&
							accounts[0]?.idTokenClaims?.roles.includes(
								'GlobalAdmin'
							) && (
								<i
									className="status-icon d-block bi bi-person-x-fill btn btn-outline-light btn-sm"
									onClick={() =>
										handleRemoveAdminRole(row.id)
									}
								></i>
							)}
					</div>
				</td>
			</tr>
		</>
	);
}

UserControlItem.propTypes = {
	row: PropTypes.object.isRequired,
	handleRemoveAdminRole: PropTypes.func,
	handleSetAdminRole: PropTypes.func,
	handleDeleteUser: PropTypes.func,
};

UserControlItem.defaultProps = {
	handleRemoveAdminRole: null,
	handleSetAdminRole: null,
	handleDeleteUser: null,
};

export default UserControlItem;
