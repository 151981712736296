import CaseDetailService from './CaseDetailService';
import http, { standardizeResponse } from '../utils/http';

export default class CaseOverviewService {
	static async getCaseImages(caseID) {
		try {
			const { data } = await http.get(`/api/casepreparing`, {
				params: { caseid: btoa(caseID) },
			});
			return data;
		} catch (error) {
			console.error(error);
			return error;
		}
	}

	static async completeCase({ caseId, markDetections, modifiedby }) {
		const requestContentArray = markDetections.map((detection) => {
			const { base64, image, ...content } = detection;
			return content;
		});

		const requestBody = {
			content: requestContentArray,
			modifiedby,
		};

		return standardizeResponse(
			http.post(`/api/caseoverview/complete`, requestBody, {
				params: { caseid: btoa(caseId) },
			})
		);
	}
}

export const getImageByFrameId = async (
	caseId,
	frameid,
	cameraid,
	camlength,
	isexplain = false
) => {
	try {
		const image = await CaseDetailService.getImagesByFrameIds(caseId, [
			{
				frameid,
				cameraid,
				camlength,
				isexplain: isexplain || false,
			},
		]);
		return {
			src: image[0].img_base64,
			frameID: image[0].frameid,
			cam: cameraid,
		};
	} catch (error) {
		console.log('Error api: getImageByFrameId', error);
		return null;
	}
};
