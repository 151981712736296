import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import './SideBar.scss';

function SideBar() {
	const { url } = useRouteMatch();
	return (
		<nav className="nav SideBar">
			<NavLink
				activeClassName="btn-primary text-light"
				className="nav-link btn btn-outline-primary d-flex"
				to={`${url}/video-administration`}
			>
				<i className="bi bi-camera-video me-2"></i>
				Cases
			</NavLink>
			<NavLink
				activeClassName="btn-primary text-light"
				className="nav-link btn btn-outline-primary d-flex"
				to={`${url}/user-control`}
			>
				<i className="bi bi-people me-2"></i>
				Users
			</NavLink>
		</nav>
	);
}

export default SideBar;
