import React from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import isEmpty from 'lodash/isEmpty';

const rndStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: 'solid 3px #ff0000',
	borderRadius: '50%',
	background: 'transparent',
};

const LeftPanel = (props) => {
	const {
		style,
		isMarking,
		image = {},
		marks,
		imageBarService,
		onAddMark,
		onDragStop,
		onResizeStop,
	} = props;

	if (isEmpty(image)) return null;
	const borderColor =
		imageBarService &&
		imageBarService.getColorFromProb(
			// imageBarService.getScoreFromProb(image.frameID)
			imageBarService.getScoreFromProb(image.frameID, image.cam)
		);
	const newStyle = {
		position: 'relative',
		border: `solid 3px ${borderColor}`,
		backgroundImage: `url(${image?.src})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		height: 512,
		width: 512,
		...style,
	};

	return (
		<div style={newStyle}>
			{isMarking && (
				<div
					className="w-100 h-100 mark-modal-content__overlap-box"
					onClick={onAddMark}
				/>
			)}
			{marks.map((mark, index) => (
				<Rnd
					key={mark.id || index.toString()}
					style={rndStyle}
					bounds="parent"
					default={mark}
					onDragStop={(e, d) => onDragStop(mark, e, d)}
					onResizeStop={(e, direction, dom, delta, position) =>
						onResizeStop(mark, dom, position)
					}
				>
					<div
						style={{
							fontWeight: 900,
							color: '#ff0000',
							position: 'absolute',
							top: -18,
							right: 0,
							fontSize: 16,
						}}
					>
						{index + 1}
					</div>
				</Rnd>
			))}
		</div>
	);
};

LeftPanel.propTypes = {
	style: PropTypes.object,
	image: PropTypes.any,
	imageBarService: PropTypes.any,
	isMarking: PropTypes.bool,
	marks: PropTypes.array,
	onAddMark: PropTypes.func,
	onDragStop: PropTypes.func,
	onResizeStop: PropTypes.func,
};

LeftPanel.defaultProps = {
	style: {},
	image: {},
	marks: [],
	isMarking: false,
	imageBarService: {},
	onAddMark: () => {},
	onDragStop: () => {},
	onResizeStop: () => {},
};

export default LeftPanel;
