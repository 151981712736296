/* eslint-disable */
import { hexToRgb } from '../utils/commons';
import { CAMERA } from '../utils/constants';

import {
	convertHMSArrayToNumber,
	convertHMSStringToArray,
	convertHMSStringToTime,
	convertSecondsToHMS,
} from '../utils/time';

export default class ImageBarService {
	constructor(
		csvData,
		frameStart = 0,
		superiorColors = {
			initColor: hexToRgb('#004D40'),
			middleOne: hexToRgb('#FFC107'),
			middleTwo: hexToRgb('#FFC107'),
			finalColor: hexToRgb('#D81B60'),
		},
		allowDragDrop = false
	) {
		this.superiorColors = superiorColors;
		this.frameStart = frameStart;
		this.probData = [];
		this.haveIniProb = false;
		this.allData = [];
		this.camOne = [];
		this.camTwo = [];
		this.maxFrameCameraOne = 0;
		this.maxFrameCameraTwo = 0;

		this.maxTimestampCamOne = 0;
		this.maxTimestampCamTwo = 0;

		this.initialize(csvData);

		// Only for preparing page
		if (allowDragDrop) {
			this.initCasePreparingData();
		}
	}

	splitSimilarAndSetDefaultCam(csvData) {
		csvData = csvData.map((y) => {
			let x = {};
			x.similar = y.simmilar;
			if (!('similar' in x)) {
				x.similar = [];
			} else {
				if (!x.similar) {
					x.similar = [];
				}
				if (x.similar.includes(';')) {
					x.similar = x.similar.split(';');
				} else {
					if (x.similar.length > 0) {
						x.similar = [x.similar];
					} else {
						x.similar = [];
					}
				}
			}

			x.cam = y.cameraid;
			x.thumbnail = `${y.thumbnail}`;
			x.timestamp = y.timestampvideo;
			x.y_true = +y.y_true;
			x.frameID = y.frameid;
			x.ini_prob = +y.init_prob;
			x.prob = +y.probability;

			if (!x?.cam) {
				x.cam = 1;
			}

			return x;
		});

		return csvData;
	}

	getColorFromProb(prob, grayscale) {
		let percentFade = prob;

		let startColor = this.superiorColors.initColor;
		let endColor = this.superiorColors.middleOne;

		if (percentFade < 0.25) {
			percentFade = percentFade / 0.25;
		} else {
			if (percentFade < 0.5) {
				startColor = this.superiorColors.initColor;
				endColor = this.superiorColors.middleOne;
				percentFade = (percentFade - 0.25) / 0.25;
			} else {
				if (percentFade < 0.75) {
					startColor = this.superiorColors.middleOne;
					endColor = this.superiorColors.middleTwo;
					percentFade = (percentFade - 0.5) / 0.25;
				} else {
					startColor = this.superiorColors.middleTwo;
					endColor = this.superiorColors.finalColor;
					percentFade = (percentFade - 0.75) / 0.25;
				}
			}
		}

		let diffRed = endColor.red - startColor.red;
		let diffGreen = endColor.green - startColor.green;
		let diffBlue = endColor.blue - startColor.blue;

		diffRed = diffRed * percentFade + startColor.red;
		diffGreen = diffGreen * percentFade + startColor.green;
		diffBlue = diffBlue * percentFade + startColor.blue;
		if (grayscale) {
			const weighted = Math.floor(
				diffRed * 0.299 + diffGreen * 0.587 + diffBlue * 0.114
			);
			return `rgb(${weighted},${weighted},${weighted})`;
		}
		return `rgb(${Math.floor(diffRed)},${Math.floor(
			diffGreen
		)},${Math.floor(diffBlue)})`;
	}

	checkHaveIsIniProb() {
		return this.haveIniProb;
	}

	getIndex(frameId, cam) {
		return this.allData.findIndex(
			(x) => x.frameID === frameId && x.cam === cam
		);
	}

	/*
	 *	The current frameID we are visualizing
	 */
	currentTms(indexTimestamp) {
		return this.probData[indexTimestamp]?.frameID;
	}

	currentCam(indexTimestamp) {
		return this.probData[indexTimestamp]?.cam;
	}

	getCurrentSimilar(indexTimestamp, seen) {
		const similars = this.probData[indexTimestamp].similar;
		const arr = similars.filter((item) => {
			return seen.indexOf(item.frameID) === -1;
		});
		return arr;
	}

	/*
	 *	Given a frame, it returns its timestamp
	 */
	getTimestamp(frameId, cam) {
		const index = this.getIndex(frameId, cam);
		if (index === -1) {
			return '';
		}
		return this.allData[index].timestamp;
	}

	getMaxFramesBothCam() {
		return Math.max(
			this.getCamOne().length + this.frameStart,
			this.getCamTwo().length + this.frameStart
		);
	}

	/**
	 * Get total time of the video in HMS array
	 */
	getTotalVideoTimeHMS() {
		const camOneTimestampStr = this.maxTimestampCamOne;
		const camTwoTimestampStr = this.maxTimestampCamTwo;

		const camOneTimestampArr = convertHMSStringToArray(camOneTimestampStr);
		const camTwoTimestampArr = convertHMSStringToArray(camTwoTimestampStr);

		const camOneTotalTime = convertHMSArrayToNumber(camOneTimestampArr);
		const camTwoTotalTime = convertHMSArrayToNumber(camTwoTimestampArr);

		if (camTwoTotalTime > camOneTotalTime) return camTwoTimestampArr;
		return camOneTimestampArr;
	}

	getTotalVideoTime() {
		const timeVideo = this.getTotalVideoTimeHMS();
		const totalVideoTime = convertHMSArrayToNumber(timeVideo);
		return totalVideoTime;
	}

	getTimelapsePerFrame() {
		const totalVideoTime = this.getTotalVideoTime();
		const timelapsePerFrame = totalVideoTime / this.getMaxFramesBothCam();

		return timelapsePerFrame;
	}

	/*
	 *	Given a frame, it returns its thumbnail
	 */
	getThumbnail(frameId, cam) {
		const index = this.getIndex(frameId, cam);
		if (index === -1) {
			return '';
		}
		return this.allData[index].thumbnail;
	}

	/*
	 *	Given a frame, it returns if it is a polyp
	 */
	getRealY(frameId, cam) {
		const index = this.getIndex(frameId, cam);
		if (index === -1) {
			return '';
		}
		return this.allData[index].y_true;
	}

	/*
	 *	Given a frame, it returns its score in a 0-1 range
	 */
	getScoreFromProb(frameId, cam) {
		const index = this.getIndex(frameId, cam);
		if (index === -1) {
			return 0;
		}
		if (this.haveIniProb) {
			return parseFloat(this.allData[index].ini_prob);
		}
		return parseFloat(this.allData[index].prob);
	}

	/*
	 *	Given a frame, it returns its score formatted as a 0% - 100% string
	 */
	getScore(frameId, cam) {
		return `${(this.getScoreFromProb(frameId, cam) * 100).toFixed(2)}%`;
	}

	/*
	 *	Given a similar Id, it returns an array [frameId, camera]
	 */
	getDataFromSimilarId(similarId) {
		const camOneLength = this.maxFrameCameraOne + 1;
		if (similarId - camOneLength < 0) {
			return [similarId, 1];
		} else {
			return [similarId - camOneLength, 2];
		}
	}

	getCamOne() {
		return this.camOne;
	}

	getCamTwo() {
		return this.camTwo;
	}

	getTotalInspected() {
		return this.camOne.length + this.camTwo.length;
	}

	getFrameStart() {
		return this.frameStart;
	}

	getSimilar(frameId, cam, seen) {
		const index = this.getIndex(frameId, cam);
		if (index === -1) {
			return [];
		}
		let similars = this.allData[index].similar;
		similars = similars.map((x) => {
			const [frame, cam] = this.getDataFromSimilarId(+x);
			return { frame, cam };
		});
		// const arr = similars.filter((item) => {
		// 	return (
		// 		seen.findIndex(
		// 			(x) => x.frame === item.frame && x.cam === item.cam
		// 		) === -1
		// 	);
		// });

		return similars;
	}

	getMaxFrameCameraOne() {
		return this.maxFrameCameraOne;
	}

	getMaxFrameCameraTwo() {
		return this.maxFrameCameraTwo;
	}

	filterDataByCam(data, cam) {
		return data
			.filter((x) => x.cam === cam)
			.sort((a, b) => (a.frameID > b.frameID ? 1 : -1));
	}

	initialize(csvData) {
		const processData = this.splitSimilarAndSetDefaultCam(csvData);
		this.allData = [...processData];

		this.camOne = this.filterDataByCam(processData, CAMERA.ONE);
		this.maxTimestampCamOne = this.camOne[this.camOne.length - 1].timestamp;
		this.maxFrameCameraOne = Math.max.apply(
			Math,
			this.camOne.map((x) => x.frameID)
		);
		this.camOne.splice(0, this.frameStart);

		this.camTwo = this.filterDataByCam(processData, CAMERA.TWO);
		this.maxTimestampCamTwo = this.camTwo[this.camTwo.length - 1].timestamp;
		this.maxFrameCameraTwo = Math.max.apply(
			Math,
			this.camTwo.map((x) => x.frameID)
		);
		this.camTwo.splice(0, this.frameStart);

		this.haveIniProb =
			Object.keys(processData[0]).findIndex((x) => x === 'ini_prob') !==
			-1;

		this.probData = [...this.camOne, ...this.camTwo].sort((a, b) =>
			a.prob > b.prob ? -1 : 1
		);
	}

	initCasePreparingData() {
		if (!this.camOne.length || !this.camTwo.length) return;

		const { alignedCamOne, alignedCamTwo, newFrameStart } =
			this.reAlignCameraFrames(this.camOne, this.camTwo);

		this.camOne = alignedCamOne;
		this.camTwo = alignedCamTwo;
		this.frameStart = newFrameStart;
	}

	/**
	 * Detect the frame having faulty timestamp
	 * Then cut off faulty part of the video and recalculate all the indexes.
	 *
	 * @param {Array} camOneFrames
	 * @param {Array} camTwoFrames
	 */
	reAlignCameraFrames(camOneFrames, camTwoFrames) {
		let faultyIndexCamOne = 0,
			faultyIndexCamTwo = 0;

		// This is confirmed the timestamp which cameras stop working
		// It's 00:02:59 by the time of this code is written
		const faultyTimestamp = convertHMSStringToTime('00:02:59');

		for (
			let i = 0;
			i < Math.min(camTwoFrames.length - 1, camOneFrames.length - 1);
			i++
		) {
			const camOneTimestamp = convertHMSStringToTime(
				camOneFrames[i].timestamp
			);
			const camTwoTimestamp = convertHMSStringToTime(
				camTwoFrames[i].timestamp
			);

			if (!faultyIndexCamOne && camOneTimestamp > faultyTimestamp)
				faultyIndexCamOne = i;
			if (!faultyIndexCamTwo && camTwoTimestamp > faultyTimestamp)
				faultyIndexCamTwo = i;

			if (faultyIndexCamOne && faultyIndexCamTwo) break;
		}

		const alignedCamOne = camOneFrames.slice(
			faultyIndexCamOne,
			camOneFrames.length - 1
		);

		const alignedCamTwo = camTwoFrames.slice(
			faultyIndexCamTwo,
			camTwoFrames.length - 1
		);

		return {
			alignedCamOne,
			alignedCamTwo,
			newFrameStart: camOneFrames[faultyIndexCamOne].frameID,
		};
	}
}
