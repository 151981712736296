import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../components/ConfirmModal';
import CreateUserModal from '../../components/CreateUserModal';
import Pagination from '../../components/Pagination';
import {
	assignNewUserRole,
	checkUserExist,
	fetchFilterOptions,
	fetchUserControlList,
	getUsersByPage,
	setFilterCriteria,
	setPage,
} from '../../store/slices/UserControlSlice';
import UserControlFilter from './components/UserControlFilter';
import UserControlList from './components/UserControlList';
import './UserControlPage.scss';

function UserControlPage() {
	const CONFIRM_MODAL_MESSAGE = {
		createUserConfirm: 'This account already exists.',
		createUserError: 'Can not create this account.',
	};
	const [isShowModal, setIsShowModal] = useState(false);
	const [confirmModalMessage, setConfirmModalMessage] = useState(
		CONFIRM_MODAL_MESSAGE.createUserConfirm
	);
	const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
	const { totalPages, page } = useSelector(
		(state) => state.userControlList.pagination
	);
	const { userRoleOptions } = useSelector((state) => state.userControlList);
	const dispatch = useDispatch();

	const onPageChange = async (newPage) => {
		dispatch(setPage(newPage));
		await dispatch(getUsersByPage());
	};

	useEffect(async () => {
		await dispatch(fetchFilterOptions());
	}, []);

	const handleAddNewUser = async (newUser) => {
		setIsShowModal(false);
		try {
			await dispatch(assignNewUserRole(newUser));
			await dispatch(fetchUserControlList());
			dispatch(setPage(1));
			dispatch(
				setFilterCriteria({
					userName: '',
					userRoles: [],
				})
			);
			dispatch(getUsersByPage());
		} catch (error) {
			setConfirmModalMessage(CONFIRM_MODAL_MESSAGE.createUserError);
			setIsShowConfirmModal(true);
			console.error('Create User Error', error);
		}
	};

	const onHandleCheckUserAvailable = async (userMail) => {
		const response = await dispatch(checkUserExist(userMail));
		return response.payload;
	};

	const handleConfirmCfModal = () => {
		setIsShowConfirmModal(false);
	};
	return (
		<>
			<ConfirmModal
				isShowModal={isShowConfirmModal}
				handleHideModal={() => setIsShowConfirmModal(false)}
				handleConfirm={handleConfirmCfModal}
				message={confirmModalMessage}
				buttonColor="warning"
				buttonName="OK"
				isShowCloseButton={false}
			/>
			<CreateUserModal
				isShowModal={isShowModal}
				handleHideModal={() => setIsShowModal(false)}
				handleAddNewUser={handleAddNewUser}
				onHandleCheckUserAvailable={onHandleCheckUserAvailable}
				userRoleOptions={userRoleOptions}
			/>
			<div className="row">
				<div className="col-auto">
					<h4>Users</h4>
				</div>
				<div className="col-auto">
					<button
						type="button"
						className="btn btn-sm btn-success"
						onClick={() => setIsShowModal(true)}
					>
						Add New User
					</button>
				</div>
			</div>
			<UserControlFilter />
			<UserControlList />
			<div className="user-control-pagination">
				{totalPages > 1 && (
					<Pagination
						totalPages={totalPages}
						page={page}
						onPageChange={onPageChange}
					/>
				)}
			</div>
		</>
	);
}

export default UserControlPage;
