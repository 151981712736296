import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import VideoAdminListService from '../../services/VideoAdminListService';
import { CaseStatus } from '../../utils/constants';
import { convertTZ } from '../../utils/time';

const initialState = {
	cases: [],
	customerOptions: [],
	caseStatusOptions: [],
	pagination: {
		totalPages: 1,
		page: 1,
	},
	filterCriteria: {
		fullName: '',
		caseName: '',
		customers: [],
		caseStatus: [CaseStatus.NOT_STARTED, CaseStatus.IN_PROGRESS],
		uploadedDate: '',
		completedDate: '',
		startedDate: '',
		isRemove: false,
	},
	isFetchingData: false,
};

export const fetchVideoAdminList = createAsyncThunk(
	'videoAdminList/fetchVideoAdminList',
	async (arg, { getState }) => {
		const videoAdminListState = getState().videoAdminList;
		const { filterCriteria, pagination } = videoAdminListState;
		const response = await VideoAdminListService.getVideoAdminList({
			...filterCriteria,
			page: pagination.page,
		});
		if (response && response.cases) {
			response.cases = response.cases.map((item) => ({
				...item,
				startedDate: convertTZ(item.startdate),
				uploadedDate: convertTZ(item.uploadeddate),
				completedDate: convertTZ(item.completeddate),
			}));
		}
		return response;
	}
);

export const fetchFilterOptions = createAsyncThunk(
	'videoAdminList/fetchFilterOptions',
	async () => {
		const response = await VideoAdminListService.getFilterOptions();
		return response;
	}
);

export const softRemoveCase = createAsyncThunk(
	'videoAdminList/softRemoveCase',
	async (caseId) => {
		const response = await VideoAdminListService.softRemoveCase(caseId);
		return response;
	}
);

export const hardRemoveCase = createAsyncThunk(
	'videoAdminList/hardRemoveCase',
	async (caseId) => {
		const response = await VideoAdminListService.hardRemoveCase(caseId);
		return response;
	}
);

export const restoreCase = createAsyncThunk(
	'videoAdminList/restoreCase',
	async (caseId) => {
		const response = await VideoAdminListService.restoreCase(caseId);
		return response;
	}
);

export const videoAdminListSlice = createSlice({
	name: 'videoAdminList',
	initialState,
	reducers: {
		setPage: (state, action) => {
			state.pagination.page = action.payload;
		},
		setFilterCriteria: (state, action) => {
			state.filterCriteria.caseName = action.payload.caseName;
			state.filterCriteria.caseStatus = action.payload.caseStatus;
			state.filterCriteria.customers = action.payload.customers;
			state.filterCriteria.uploadedDate = action.payload.uploadedDate;
			state.filterCriteria.completedDate = action.payload.completedDate;
			state.filterCriteria.startedDate = action.payload.startedDate;
			state.filterCriteria.isRemove = action.payload.isRemove;
		},
		setFullName: (state, action) => {
			state.filterCriteria.fullName = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchVideoAdminList.pending, (state) => {
			state.isFetchingData = true;
		});

		builder.addCase(fetchVideoAdminList.fulfilled, (state, action) => {
			state.isFetchingData = false;
			state.cases = action.payload.cases;
			state.pagination.totalPages = action.payload.pagination.totalpages;
		});

		builder.addCase(fetchFilterOptions.fulfilled, (state, action) => {
			state.caseStatusOptions = action.payload.casestatus.map((x) => ({
				label: x.casestatusname,
				value: x.casestatus,
			}));
			state.customerOptions = action.payload.customers.map((x) => ({
				label: x.customer,
				value: x.customer,
			}));
		});
	},
});

export const { setPage, setFullName, setFilterCriteria } =
	videoAdminListSlice.actions;

export default videoAdminListSlice.reducer;
