import React from 'react';
import { useMsal } from '@azure/msal-react';

function handleLogout(instance) {
	instance.logoutRedirect().catch((e) => {
		console.error(e);
	});
}

function SignOut() {
	const { instance } = useMsal();

	return (
		<p
			className="signout"
			variant="secondary"
			onClick={() => handleLogout(instance)}
			aria-hidden="true"
			style={{ marginTop: '5px', marginBottom: '5px' }}
		>
			Sign out
		</p>
	);
}

export default SignOut;
