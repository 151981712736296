import React, { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import html2pdf from 'html2pdf.js';
import { useMsal } from '@azure/msal-react';

import ElapsedTime from '../../components/ElapsedTime';
import './CaseSummaryStyles.scss';
import { fetchAllPageData } from '../../store/slices/CaseSummarySlice';
import Detection from './components/Detection';
import Loading from '../../components/Loading';
import CaseSummaryService from '../../services/CaseSummaryService';
import { CaseStatus } from '../../utils/constants';
import {
	updateCaseDetail,
	updateFrameStartOfCaseDetail,
} from '../../store/slices/CaseDetailSlice';

function CaseSummaryIndex() {
	const params = useParams();
	const dispatch = useDispatch();
	const printableRef = createRef();
	const printPreviewRef = createRef();
	const {
		detections,
		customer,
		casename,
		imageBarService,
		caseDetail,
		frameStart,
		loading,
	} = useSelector((state) => ({
		detections: state.caseSummary.detections,
		casename: state.caseDetail.caseDetail.casename,
		customer: state.caseDetail.history[0]?.customer,
		imageBarService: state.caseDetail.imageBarService,
		caseDetail: state.caseDetail.caseDetail,
		frameStart: state.caseDetail.frameStart,
		loading: state.caseSummary.loading,
	}));

	const [isExporting, setIsExporting] = useState(false);
	const caseId = params.id;
	const { accounts } = useMsal();
	const userFullName = accounts[0] && accounts[0].name;
	const isTimerFrozen = caseDetail.casestatus === CaseStatus.COMPLETED;

	const getCamColor = (camId) => {
		let camIdNumber = 0;
		if (!camId || camId === '') {
			return '';
		}

		try {
			camIdNumber = parseInt(camId, 10);
		} catch {
			return '';
		}

		switch (camIdNumber) {
			case 1:
				return 'green';
			case 2:
				return 'yellow';
			default:
				return '';
		}
	};

	useEffect(() => {
		if (!imageBarService) return;
		dispatch(
			fetchAllPageData({
				caseId,
				imageBarService,
			})
		);
	}, [imageBarService]);

	const updateElapsedTime = () => {
		const timer = localStorage.getItem('elapsedTime');

		const obj = {
			caseId: caseDetail.caseid,
			casestatus: CaseStatus.COMPLETED,
			modifiedby: userFullName,
			startframeid: frameStart,
			elapsedtime: timer,
			iscaseoverview: true,
		};
		dispatch(updateFrameStartOfCaseDetail(obj));

		dispatch(
			updateCaseDetail({
				caseStatus: CaseStatus.COMPLETED,
				modifiedBy: userFullName,
				startFrameID: caseDetail.startframeid,
				elapsedTime: timer,
			})
		);
	};

	const exportPDF = async () => {
		setIsExporting(true);

		const filename = `
			case-${casename}${customer ? `-${customer}` : ''}.pdf
		`;
		const exportOption = {
			margin: 1,
			filename: filename.trim(),
			image: { type: 'jpeg', quality: 0.9 },
			jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
			html2canvas: {
				scale: 2,
				allowTaint: true,
				imageTimeout: 0,
			},
		};
		const exportDOMs = printableRef.current.cloneNode(true);
		const printContainer = exportDOMs.getRootNode();
		printContainer.classList.add('is-printing');
		// For debugging purposes...
		printPreviewRef.current.innerHTML = '';
		printPreviewRef.current.appendChild(exportDOMs);
		html2pdf().set(exportOption).from(exportDOMs).save();
		updateElapsedTime();

		setTimeout(() => {
			setIsExporting(false);
		}, 1000);
	};

	const onExportButtonClick = async () => {
		await exportPDF();

		CaseSummaryService.endCase({
			caseId,
			modifiedby: userFullName,
		});
	};

	return (
		<div className="p-4">
			<Helmet>
				<title>Case Summary | CHI AiSPEED</title>
			</Helmet>
			<div className="card">
				<div className="card-body">
					<div
						id="preview-before-printing"
						ref={printPreviewRef}
						className="d-none"
					></div>

					<div ref={printableRef}>
						{/* Case Summary Header */}
						<div className="CaseSummaryPage-header">
							<div className="hide-on-export">
								<ElapsedTime
									overrideTimerString={
										isTimerFrozen
											? caseDetail.elapsedtime
											: ''
									}
									freeze={isTimerFrozen}
									style={{ width: 'auto', margin: 0 }}
								/>
							</div>

							<div className="case-info">
								Case: {casename} / Customer: {customer}
							</div>

							<div>
								<button
									type="button"
									disabled={
										isExporting ||
										loading ||
										!(
											Array.isArray(detections) &&
											detections.length > 0
										)
											? 'disabled'
											: false
									}
									onClick={onExportButtonClick}
									className="btn-primary btn-sm btn hide-on-export"
								>
									{isExporting || loading ? (
										<div
											className="spinner-border spinner-border-sm text-light"
											role="status"
										>
											<span className="visually-hidden">
												Loading...
											</span>
										</div>
									) : (
										'Export'
									)}
								</button>
							</div>
						</div>

						<div className="summary-table-container">
							<table className="summary-table mx-auto">
								<tr>
									<th>Detection #</th>
									<th>Comment</th>
									<th>Camera</th>
									<th>Timestamp</th>
								</tr>
								{detections &&
									Array.isArray(detections) &&
									detections.map((detection, index) => {
										const hasDetections =
											detection &&
											Array.isArray(detection.markers);
										return (
											<tr key={detection.caseid}>
												<td>{index}</td>
												<td>
													{hasDetections &&
														detection.markers.map(
															(finding) => (
																<p
																	className="mb-0"
																	key={
																		finding.id
																	}
																>
																	{
																		finding.description
																	}
																</p>
															)
														)}
												</td>
												<td
													style={{
														backgroundColor:
															getCamColor(
																detection.camera
															),
													}}
												>
													{`${
														detection.camera
													} (${getCamColor(
														detection.camera
													)})`}
												</td>
												<td>{detection.time}</td>
											</tr>
										);
									})}
							</table>
						</div>

						<div className="detection-list">
							{/* Case Summary Body */}
							{detections &&
								Array.isArray(detections) &&
								detections.map((detection, index) => (
									<>
										<Detection
											key={detection.id}
											rowIndex={index}
											detection={detection}
										/>
									</>
								))}
							{!detections && (
								<Loading show>
									<p className="m-auto loading-message">
										Loading the images, please wait ...
									</p>
								</Loading>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CaseSummaryIndex;
