import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CaseListPage from '../CaseListPage';

function HomePage() {
	const { accounts } = useMsal();
	const isHaveRole =
		accounts[0] &&
		accounts[0].idTokenClaims &&
		accounts[0].idTokenClaims.roles;
	useEffect(() => {
		if (!isHaveRole) {
			// eslint-disable-next-line no-alert
			alert(
				`Your account doesn't have permission to access this website. Please contact your manager to grant permission.`
			);
		}
	}, []);
	return (
		<div className="mt-3 pb-3">
			<Helmet>
				<title>Case Listing | CHI AiSPEED</title>
			</Helmet>
			<CaseListPage />
		</div>
	);
}

export default HomePage;
